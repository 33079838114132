/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const BenefitDefinitionAccessRulesRequestRequirements = {
  Always: "always",
  Never: "never",
  IfNeeded: "if needed",
} as const;
export type BenefitDefinitionAccessRulesRequestRequirements =
  (typeof BenefitDefinitionAccessRulesRequestRequirements)[keyof typeof BenefitDefinitionAccessRulesRequestRequirements];

export function instanceOfBenefitDefinitionAccessRulesRequestRequirements(
  value: any,
): boolean {
  for (const key in BenefitDefinitionAccessRulesRequestRequirements) {
    if (
      Object.prototype.hasOwnProperty.call(
        BenefitDefinitionAccessRulesRequestRequirements,
        key,
      )
    ) {
      if (
        BenefitDefinitionAccessRulesRequestRequirements[
          key as keyof typeof BenefitDefinitionAccessRulesRequestRequirements
        ] === value
      ) {
        return true;
      }
    }
  }
  return false;
}

export function BenefitDefinitionAccessRulesRequestRequirementsFromJSON(
  json: any,
): BenefitDefinitionAccessRulesRequestRequirements {
  return BenefitDefinitionAccessRulesRequestRequirementsFromJSONTyped(
    json,
    false,
  );
}

export function BenefitDefinitionAccessRulesRequestRequirementsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BenefitDefinitionAccessRulesRequestRequirements {
  return json as BenefitDefinitionAccessRulesRequestRequirements;
}

export function BenefitDefinitionAccessRulesRequestRequirementsToJSON(
  value?: BenefitDefinitionAccessRulesRequestRequirements | null,
): any {
  return value as any;
}

export function BenefitDefinitionAccessRulesRequestRequirementsToJSONTyped(
  value: any,
  ignoreDiscriminator: boolean,
): BenefitDefinitionAccessRulesRequestRequirements {
  return value as BenefitDefinitionAccessRulesRequestRequirements;
}
