/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";

/**
 *
 * @export
 * @interface BenefitCalculation
 */
export interface BenefitCalculation {
  /**
   *
   * @type {number}
   * @memberof BenefitCalculation
   */
  leasingPeriodMonths: number;
  /**
   *
   * @type {number}
   * @memberof BenefitCalculation
   */
  taxablePackagePrice: number;
  /**
   *
   * @type {number}
   * @memberof BenefitCalculation
   */
  totalTaxableValue: number;
  /**
   *
   * @type {number}
   * @memberof BenefitCalculation
   */
  paymentsTotal: number;
  /**
   *
   * @type {number}
   * @memberof BenefitCalculation
   */
  financialInterest: number;
  /**
   *
   * @type {number}
   * @memberof BenefitCalculation
   */
  redemptionPercentage: number;
  /**
   *
   * @type {number}
   * @memberof BenefitCalculation
   */
  residualValue: number;
  /**
   *
   * @type {number}
   * @memberof BenefitCalculation
   */
  monthlyLeasingFee: number;
  /**
   *
   * @type {number}
   * @memberof BenefitCalculation
   */
  monthlyTaxableValue: number;
  /**
   *
   * @type {number}
   * @memberof BenefitCalculation
   */
  monthlyInsuranceFee: number;
  /**
   *
   * @type {number}
   * @memberof BenefitCalculation
   */
  downPaymentAmount: number;
  /**
   *
   * @type {number}
   * @memberof BenefitCalculation
   */
  yearlyDownPaymentInsuranceFee: number;
  /**
   *
   * @type {number}
   * @memberof BenefitCalculation
   */
  totalFinancingCost: number;
  /**
   *
   * @type {number}
   * @memberof BenefitCalculation
   */
  totalServiceCost: number;
}

/**
 * Check if a given object implements the BenefitCalculation interface.
 */
export function instanceOfBenefitCalculation(
  value: object,
): value is BenefitCalculation {
  if (
    !("leasingPeriodMonths" in value) ||
    value["leasingPeriodMonths"] === undefined
  )
    return false;
  if (
    !("taxablePackagePrice" in value) ||
    value["taxablePackagePrice"] === undefined
  )
    return false;
  if (
    !("totalTaxableValue" in value) ||
    value["totalTaxableValue"] === undefined
  )
    return false;
  if (!("paymentsTotal" in value) || value["paymentsTotal"] === undefined)
    return false;
  if (
    !("financialInterest" in value) ||
    value["financialInterest"] === undefined
  )
    return false;
  if (
    !("redemptionPercentage" in value) ||
    value["redemptionPercentage"] === undefined
  )
    return false;
  if (!("residualValue" in value) || value["residualValue"] === undefined)
    return false;
  if (
    !("monthlyLeasingFee" in value) ||
    value["monthlyLeasingFee"] === undefined
  )
    return false;
  if (
    !("monthlyTaxableValue" in value) ||
    value["monthlyTaxableValue"] === undefined
  )
    return false;
  if (
    !("monthlyInsuranceFee" in value) ||
    value["monthlyInsuranceFee"] === undefined
  )
    return false;
  if (
    !("downPaymentAmount" in value) ||
    value["downPaymentAmount"] === undefined
  )
    return false;
  if (
    !("yearlyDownPaymentInsuranceFee" in value) ||
    value["yearlyDownPaymentInsuranceFee"] === undefined
  )
    return false;
  if (
    !("totalFinancingCost" in value) ||
    value["totalFinancingCost"] === undefined
  )
    return false;
  if (!("totalServiceCost" in value) || value["totalServiceCost"] === undefined)
    return false;
  return true;
}

export function BenefitCalculationFromJSON(json: any): BenefitCalculation {
  return BenefitCalculationFromJSONTyped(json, false);
}

export function BenefitCalculationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BenefitCalculation {
  if (json == null) {
    return json;
  }
  return {
    leasingPeriodMonths: json["leasing_period_months"],
    taxablePackagePrice: json["taxable_package_price"],
    totalTaxableValue: json["total_taxable_value"],
    paymentsTotal: json["payments_total"],
    financialInterest: json["financial_interest"],
    redemptionPercentage: json["redemption_percentage"],
    residualValue: json["residual_value"],
    monthlyLeasingFee: json["monthly_leasing_fee"],
    monthlyTaxableValue: json["monthly_taxable_value"],
    monthlyInsuranceFee: json["monthly_insurance_fee"],
    downPaymentAmount: json["down_payment_amount"],
    yearlyDownPaymentInsuranceFee: json["yearly_down_payment_insurance_fee"],
    totalFinancingCost: json["total_financing_cost"],
    totalServiceCost: json["total_service_cost"],
  };
}

export function BenefitCalculationToJSON(json: any): BenefitCalculation {
  return BenefitCalculationToJSONTyped(json, false);
}

export function BenefitCalculationToJSONTyped(
  value?: BenefitCalculation | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    leasing_period_months: value["leasingPeriodMonths"],
    taxable_package_price: value["taxablePackagePrice"],
    total_taxable_value: value["totalTaxableValue"],
    payments_total: value["paymentsTotal"],
    financial_interest: value["financialInterest"],
    redemption_percentage: value["redemptionPercentage"],
    residual_value: value["residualValue"],
    monthly_leasing_fee: value["monthlyLeasingFee"],
    monthly_taxable_value: value["monthlyTaxableValue"],
    monthly_insurance_fee: value["monthlyInsuranceFee"],
    down_payment_amount: value["downPaymentAmount"],
    yearly_down_payment_insurance_fee: value["yearlyDownPaymentInsuranceFee"],
    total_financing_cost: value["totalFinancingCost"],
    total_service_cost: value["totalServiceCost"],
  };
}
