/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { BenefitDefinitionSummaryRead } from "./BenefitDefinitionSummaryRead";
import {
  BenefitDefinitionSummaryReadFromJSON,
  BenefitDefinitionSummaryReadFromJSONTyped,
  BenefitDefinitionSummaryReadToJSON,
  BenefitDefinitionSummaryReadToJSONTyped,
} from "./BenefitDefinitionSummaryRead";
import type { MasterPermissions } from "./MasterPermissions";
import {
  MasterPermissionsFromJSON,
  MasterPermissionsFromJSONTyped,
  MasterPermissionsToJSON,
  MasterPermissionsToJSONTyped,
} from "./MasterPermissions";
import type { ShopRead } from "./ShopRead";
import {
  ShopReadFromJSON,
  ShopReadFromJSONTyped,
  ShopReadToJSON,
  ShopReadToJSONTyped,
} from "./ShopRead";
import type { SupportedCountriesEnum } from "./SupportedCountriesEnum";
import {
  SupportedCountriesEnumFromJSON,
  SupportedCountriesEnumFromJSONTyped,
  SupportedCountriesEnumToJSON,
  SupportedCountriesEnumToJSONTyped,
} from "./SupportedCountriesEnum";
import type { SupportedLanguagesEnum } from "./SupportedLanguagesEnum";
import {
  SupportedLanguagesEnumFromJSON,
  SupportedLanguagesEnumFromJSONTyped,
  SupportedLanguagesEnumToJSON,
  SupportedLanguagesEnumToJSONTyped,
} from "./SupportedLanguagesEnum";

/**
 *
 * @export
 * @interface CurrentUserRead
 */
export interface CurrentUserRead {
  /**
   *
   * @type {string}
   * @memberof CurrentUserRead
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof CurrentUserRead
   */
  lastName?: string;
  /**
   *
   * @type {SupportedCountriesEnum}
   * @memberof CurrentUserRead
   */
  country?: SupportedCountriesEnum;
  /**
   *
   * @type {string}
   * @memberof CurrentUserRead
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof CurrentUserRead
   */
  postCode?: string;
  /**
   *
   * @type {string}
   * @memberof CurrentUserRead
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof CurrentUserRead
   */
  phoneNumber?: string;
  /**
   *
   * @type {boolean}
   * @memberof CurrentUserRead
   */
  phoneNumberVerified?: boolean;
  /**
   *
   * @type {Array<MasterPermissions>}
   * @memberof CurrentUserRead
   */
  masterPermissions?: Array<MasterPermissions>;
  /**
   *
   * @type {string}
   * @memberof CurrentUserRead
   */
  ssn?: string;
  /**
   *
   * @type {string}
   * @memberof CurrentUserRead
   */
  company?: string;
  /**
   *
   * @type {string}
   * @memberof CurrentUserRead
   */
  costCenter?: string;
  /**
   *
   * @type {string}
   * @memberof CurrentUserRead
   */
  employeeNumber?: string;
  /**
   *
   * @type {SupportedLanguagesEnum}
   * @memberof CurrentUserRead
   */
  language?: SupportedLanguagesEnum;
  /**
   *
   * @type {boolean}
   * @memberof CurrentUserRead
   */
  isAnonymous?: boolean;
  /**
   *
   * @type {string}
   * @memberof CurrentUserRead
   */
  acceptedUserTermsId?: string;
  /**
   *
   * @type {string}
   * @memberof CurrentUserRead
   */
  acceptedShopTermsId?: string;
  /**
   *
   * @type {string}
   * @memberof CurrentUserRead
   */
  acceptedProviderTermsId?: string;
  /**
   * array of codes to access fleets.
   * @type {Array<string>}
   * @memberof CurrentUserRead
   */
  fleetAccessCodes?: Array<string>;
  /**
   * array of codes to allow access to benefit definitions.
   * @type {Array<string>}
   * @memberof CurrentUserRead
   */
  benefitDefinitionAccessCodes?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof CurrentUserRead
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof CurrentUserRead
   */
  email: string;
  /**
   *
   * @type {boolean}
   * @memberof CurrentUserRead
   */
  isDeleted: boolean;
  /**
   *
   * @type {string}
   * @memberof CurrentUserRead
   */
  fullName?: string;
  /**
   *
   * @type {boolean}
   * @memberof CurrentUserRead
   */
  isIdentityVerified: boolean;
  /**
   *
   * @type {string}
   * @memberof CurrentUserRead
   */
  currentUserTermsId?: string;
  /**
   *
   * @type {string}
   * @memberof CurrentUserRead
   */
  currentShopTermsId?: string;
  /**
   *
   * @type {string}
   * @memberof CurrentUserRead
   */
  currentProviderTermsId?: string;
  /**
   *
   * @type {Array<ShopRead>}
   * @memberof CurrentUserRead
   */
  managedShops: Array<ShopRead>;
  /**
   *
   * @type {Array<BenefitDefinitionSummaryRead>}
   * @memberof CurrentUserRead
   */
  managedBenefits: Array<BenefitDefinitionSummaryRead>;
}

/**
 * Check if a given object implements the CurrentUserRead interface.
 */
export function instanceOfCurrentUserRead(
  value: object,
): value is CurrentUserRead {
  if (!("id" in value) || value["id"] === undefined) return false;
  if (!("email" in value) || value["email"] === undefined) return false;
  if (!("isDeleted" in value) || value["isDeleted"] === undefined) return false;
  if (
    !("isIdentityVerified" in value) ||
    value["isIdentityVerified"] === undefined
  )
    return false;
  if (!("managedShops" in value) || value["managedShops"] === undefined)
    return false;
  if (!("managedBenefits" in value) || value["managedBenefits"] === undefined)
    return false;
  return true;
}

export function CurrentUserReadFromJSON(json: any): CurrentUserRead {
  return CurrentUserReadFromJSONTyped(json, false);
}

export function CurrentUserReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CurrentUserRead {
  if (json == null) {
    return json;
  }
  return {
    firstName: json["first_name"] == null ? undefined : json["first_name"],
    lastName: json["last_name"] == null ? undefined : json["last_name"],
    country:
      json["country"] == null
        ? undefined
        : SupportedCountriesEnumFromJSON(json["country"]),
    address: json["address"] == null ? undefined : json["address"],
    postCode: json["post_code"] == null ? undefined : json["post_code"],
    city: json["city"] == null ? undefined : json["city"],
    phoneNumber:
      json["phone_number"] == null ? undefined : json["phone_number"],
    phoneNumberVerified:
      json["phone_number_verified"] == null
        ? undefined
        : json["phone_number_verified"],
    masterPermissions:
      json["master_permissions"] == null
        ? undefined
        : (json["master_permissions"] as Array<any>).map(
            MasterPermissionsFromJSON,
          ),
    ssn: json["ssn"] == null ? undefined : json["ssn"],
    company: json["company"] == null ? undefined : json["company"],
    costCenter: json["cost_center"] == null ? undefined : json["cost_center"],
    employeeNumber:
      json["employee_number"] == null ? undefined : json["employee_number"],
    language:
      json["language"] == null
        ? undefined
        : SupportedLanguagesEnumFromJSON(json["language"]),
    isAnonymous:
      json["is_anonymous"] == null ? undefined : json["is_anonymous"],
    acceptedUserTermsId:
      json["accepted_user_terms_id"] == null
        ? undefined
        : json["accepted_user_terms_id"],
    acceptedShopTermsId:
      json["accepted_shop_terms_id"] == null
        ? undefined
        : json["accepted_shop_terms_id"],
    acceptedProviderTermsId:
      json["accepted_provider_terms_id"] == null
        ? undefined
        : json["accepted_provider_terms_id"],
    fleetAccessCodes:
      json["fleet_access_codes"] == null
        ? undefined
        : json["fleet_access_codes"],
    benefitDefinitionAccessCodes:
      json["benefit_definition_access_codes"] == null
        ? undefined
        : json["benefit_definition_access_codes"],
    id: json["id"],
    email: json["email"],
    isDeleted: json["is_deleted"],
    fullName: json["full_name"] == null ? undefined : json["full_name"],
    isIdentityVerified: json["is_identity_verified"],
    currentUserTermsId:
      json["current_user_terms_id"] == null
        ? undefined
        : json["current_user_terms_id"],
    currentShopTermsId:
      json["current_shop_terms_id"] == null
        ? undefined
        : json["current_shop_terms_id"],
    currentProviderTermsId:
      json["current_provider_terms_id"] == null
        ? undefined
        : json["current_provider_terms_id"],
    managedShops: (json["managed_shops"] as Array<any>).map(ShopReadFromJSON),
    managedBenefits: (json["managed_benefits"] as Array<any>).map(
      BenefitDefinitionSummaryReadFromJSON,
    ),
  };
}

export function CurrentUserReadToJSON(json: any): CurrentUserRead {
  return CurrentUserReadToJSONTyped(json, false);
}

export function CurrentUserReadToJSONTyped(
  value?: CurrentUserRead | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    first_name: value["firstName"],
    last_name: value["lastName"],
    country: SupportedCountriesEnumToJSON(value["country"]),
    address: value["address"],
    post_code: value["postCode"],
    city: value["city"],
    phone_number: value["phoneNumber"],
    phone_number_verified: value["phoneNumberVerified"],
    master_permissions:
      value["masterPermissions"] == null
        ? undefined
        : (value["masterPermissions"] as Array<any>).map(
            MasterPermissionsToJSON,
          ),
    ssn: value["ssn"],
    company: value["company"],
    cost_center: value["costCenter"],
    employee_number: value["employeeNumber"],
    language: SupportedLanguagesEnumToJSON(value["language"]),
    is_anonymous: value["isAnonymous"],
    accepted_user_terms_id: value["acceptedUserTermsId"],
    accepted_shop_terms_id: value["acceptedShopTermsId"],
    accepted_provider_terms_id: value["acceptedProviderTermsId"],
    fleet_access_codes: value["fleetAccessCodes"],
    benefit_definition_access_codes: value["benefitDefinitionAccessCodes"],
    id: value["id"],
    email: value["email"],
    is_deleted: value["isDeleted"],
    full_name: value["fullName"],
    is_identity_verified: value["isIdentityVerified"],
    current_user_terms_id: value["currentUserTermsId"],
    current_shop_terms_id: value["currentShopTermsId"],
    current_provider_terms_id: value["currentProviderTermsId"],
    managed_shops: (value["managedShops"] as Array<any>).map(ShopReadToJSON),
    managed_benefits: (value["managedBenefits"] as Array<any>).map(
      BenefitDefinitionSummaryReadToJSON,
    ),
  };
}
