/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { AnnouncementVariant } from "./AnnouncementVariant";
import {
  AnnouncementVariantFromJSON,
  AnnouncementVariantFromJSONTyped,
  AnnouncementVariantToJSON,
  AnnouncementVariantToJSONTyped,
} from "./AnnouncementVariant";
import type { AvailableScopes } from "./AvailableScopes";
import {
  AvailableScopesFromJSON,
  AvailableScopesFromJSONTyped,
  AvailableScopesToJSON,
  AvailableScopesToJSONTyped,
} from "./AvailableScopes";
import type { SupportedCountriesEnum } from "./SupportedCountriesEnum";
import {
  SupportedCountriesEnumFromJSON,
  SupportedCountriesEnumFromJSONTyped,
  SupportedCountriesEnumToJSON,
  SupportedCountriesEnumToJSONTyped,
} from "./SupportedCountriesEnum";

/**
 *
 * @export
 * @interface Announcement
 */
export interface Announcement {
  /**
   *
   * @type {string}
   * @memberof Announcement
   */
  internalName: string;
  /**
   *
   * @type {AnnouncementVariant}
   * @memberof Announcement
   */
  variant: AnnouncementVariant;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof Announcement
   */
  content: { [key: string]: string };
  /**
   *
   * @type {boolean}
   * @memberof Announcement
   */
  isEnabled?: boolean;
  /**
   *
   * @type {Date}
   * @memberof Announcement
   */
  enabledFrom?: Date;
  /**
   *
   * @type {Date}
   * @memberof Announcement
   */
  enabledUntil?: Date;
  /**
   *
   * @type {Array<AvailableScopes>}
   * @memberof Announcement
   */
  scopes?: Array<AvailableScopes>;
  /**
   *
   * @type {Array<SupportedCountriesEnum>}
   * @memberof Announcement
   */
  countries?: Array<SupportedCountriesEnum>;
  /**
   *
   * @type {boolean}
   * @memberof Announcement
   */
  isCloseable?: boolean;
  /**
   *
   * @type {string}
   * @memberof Announcement
   */
  id?: string;
  /**
   *
   * @type {Date}
   * @memberof Announcement
   */
  createdAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof Announcement
   */
  updatedAt?: Date;
}

/**
 * Check if a given object implements the Announcement interface.
 */
export function instanceOfAnnouncement(value: object): value is Announcement {
  if (!("internalName" in value) || value["internalName"] === undefined)
    return false;
  if (!("variant" in value) || value["variant"] === undefined) return false;
  if (!("content" in value) || value["content"] === undefined) return false;
  return true;
}

export function AnnouncementFromJSON(json: any): Announcement {
  return AnnouncementFromJSONTyped(json, false);
}

export function AnnouncementFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Announcement {
  if (json == null) {
    return json;
  }
  return {
    internalName: json["internal_name"],
    variant: AnnouncementVariantFromJSON(json["variant"]),
    content: json["content"],
    isEnabled: json["is_enabled"] == null ? undefined : json["is_enabled"],
    enabledFrom:
      json["enabled_from"] == null ? undefined : new Date(json["enabled_from"]),
    enabledUntil:
      json["enabled_until"] == null
        ? undefined
        : new Date(json["enabled_until"]),
    scopes:
      json["scopes"] == null
        ? undefined
        : (json["scopes"] as Array<any>).map(AvailableScopesFromJSON),
    countries:
      json["countries"] == null
        ? undefined
        : (json["countries"] as Array<any>).map(SupportedCountriesEnumFromJSON),
    isCloseable:
      json["is_closeable"] == null ? undefined : json["is_closeable"],
    id: json["id"] == null ? undefined : json["id"],
    createdAt:
      json["created_at"] == null ? undefined : new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] == null ? undefined : new Date(json["updated_at"]),
  };
}

export function AnnouncementToJSON(json: any): Announcement {
  return AnnouncementToJSONTyped(json, false);
}

export function AnnouncementToJSONTyped(
  value?: Announcement | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    internal_name: value["internalName"],
    variant: AnnouncementVariantToJSON(value["variant"]),
    content: value["content"],
    is_enabled: value["isEnabled"],
    enabled_from:
      value["enabledFrom"] == null
        ? undefined
        : value["enabledFrom"].toISOString().substring(0, 10),
    enabled_until:
      value["enabledUntil"] == null
        ? undefined
        : value["enabledUntil"].toISOString().substring(0, 10),
    scopes:
      value["scopes"] == null
        ? undefined
        : (value["scopes"] as Array<any>).map(AvailableScopesToJSON),
    countries:
      value["countries"] == null
        ? undefined
        : (value["countries"] as Array<any>).map(SupportedCountriesEnumToJSON),
    is_closeable: value["isCloseable"],
    id: value["id"],
    created_at:
      value["createdAt"] == null ? undefined : value["createdAt"].toISOString(),
    updated_at:
      value["updatedAt"] == null ? undefined : value["updatedAt"].toISOString(),
  };
}
