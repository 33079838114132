/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";

/**
 *
 * @export
 * @interface UserMepcoIntegrationSettingsCreateUpdate
 */
export interface UserMepcoIntegrationSettingsCreateUpdate {
  /**
   *
   * @type {string}
   * @memberof UserMepcoIntegrationSettingsCreateUpdate
   */
  payGroupId: string;
  /**
   *
   * @type {string}
   * @memberof UserMepcoIntegrationSettingsCreateUpdate
   */
  payComponent: string;
}

/**
 * Check if a given object implements the UserMepcoIntegrationSettingsCreateUpdate interface.
 */
export function instanceOfUserMepcoIntegrationSettingsCreateUpdate(
  value: object,
): value is UserMepcoIntegrationSettingsCreateUpdate {
  if (!("payGroupId" in value) || value["payGroupId"] === undefined)
    return false;
  if (!("payComponent" in value) || value["payComponent"] === undefined)
    return false;
  return true;
}

export function UserMepcoIntegrationSettingsCreateUpdateFromJSON(
  json: any,
): UserMepcoIntegrationSettingsCreateUpdate {
  return UserMepcoIntegrationSettingsCreateUpdateFromJSONTyped(json, false);
}

export function UserMepcoIntegrationSettingsCreateUpdateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserMepcoIntegrationSettingsCreateUpdate {
  if (json == null) {
    return json;
  }
  return {
    payGroupId: json["pay_group_id"],
    payComponent: json["pay_component"],
  };
}

export function UserMepcoIntegrationSettingsCreateUpdateToJSON(
  json: any,
): UserMepcoIntegrationSettingsCreateUpdate {
  return UserMepcoIntegrationSettingsCreateUpdateToJSONTyped(json, false);
}

export function UserMepcoIntegrationSettingsCreateUpdateToJSONTyped(
  value?: UserMepcoIntegrationSettingsCreateUpdate | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    pay_group_id: value["payGroupId"],
    pay_component: value["payComponent"],
  };
}
