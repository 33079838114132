/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { StorageObjectState } from "./StorageObjectState";
import {
  StorageObjectStateFromJSON,
  StorageObjectStateFromJSONTyped,
  StorageObjectStateToJSON,
  StorageObjectStateToJSONTyped,
} from "./StorageObjectState";

/**
 *
 * @export
 * @interface StorageObjectRead
 */
export interface StorageObjectRead {
  /**
   *
   * @type {StorageObjectState}
   * @memberof StorageObjectRead
   */
  state: StorageObjectState;
  /**
   *
   * @type {string}
   * @memberof StorageObjectRead
   */
  bucketName: string;
  /**
   *
   * @type {string}
   * @memberof StorageObjectRead
   */
  contentType: string;
  /**
   *
   * @type {string}
   * @memberof StorageObjectRead
   */
  originalFilename: string;
  /**
   *
   * @type {string}
   * @memberof StorageObjectRead
   */
  storedFilename: string;
  /**
   *
   * @type {number}
   * @memberof StorageObjectRead
   */
  size?: number;
  /**
   *
   * @type {string}
   * @memberof StorageObjectRead
   */
  addedByUserId?: string;
  /**
   *
   * @type {string}
   * @memberof StorageObjectRead
   */
  errorText?: string;
  /**
   *
   * @type {string}
   * @memberof StorageObjectRead
   */
  errorTraceback?: string;
  /**
   *
   * @type {string}
   * @memberof StorageObjectRead
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof StorageObjectRead
   */
  publicUrl?: string;
}

/**
 * Check if a given object implements the StorageObjectRead interface.
 */
export function instanceOfStorageObjectRead(
  value: object,
): value is StorageObjectRead {
  if (!("state" in value) || value["state"] === undefined) return false;
  if (!("bucketName" in value) || value["bucketName"] === undefined)
    return false;
  if (!("contentType" in value) || value["contentType"] === undefined)
    return false;
  if (!("originalFilename" in value) || value["originalFilename"] === undefined)
    return false;
  if (!("storedFilename" in value) || value["storedFilename"] === undefined)
    return false;
  if (!("id" in value) || value["id"] === undefined) return false;
  return true;
}

export function StorageObjectReadFromJSON(json: any): StorageObjectRead {
  return StorageObjectReadFromJSONTyped(json, false);
}

export function StorageObjectReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StorageObjectRead {
  if (json == null) {
    return json;
  }
  return {
    state: StorageObjectStateFromJSON(json["state"]),
    bucketName: json["bucket_name"],
    contentType: json["content_type"],
    originalFilename: json["original_filename"],
    storedFilename: json["stored_filename"],
    size: json["size"] == null ? undefined : json["size"],
    addedByUserId:
      json["added_by_user_id"] == null ? undefined : json["added_by_user_id"],
    errorText: json["error_text"] == null ? undefined : json["error_text"],
    errorTraceback:
      json["error_traceback"] == null ? undefined : json["error_traceback"],
    id: json["id"],
    publicUrl: json["public_url"] == null ? undefined : json["public_url"],
  };
}

export function StorageObjectReadToJSON(json: any): StorageObjectRead {
  return StorageObjectReadToJSONTyped(json, false);
}

export function StorageObjectReadToJSONTyped(
  value?: StorageObjectRead | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    state: StorageObjectStateToJSON(value["state"]),
    bucket_name: value["bucketName"],
    content_type: value["contentType"],
    original_filename: value["originalFilename"],
    stored_filename: value["storedFilename"],
    size: value["size"],
    added_by_user_id: value["addedByUserId"],
    error_text: value["errorText"],
    error_traceback: value["errorTraceback"],
    id: value["id"],
    public_url: value["publicUrl"],
  };
}
