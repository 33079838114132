/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  BenefitCalculation,
  BodyCalculationsGetFixedMonthlyPaymentCalculation,
  BodyCalculationsGetFixedMonthlyPaymentWithTaxCalculation,
  BodyCalculationsGetFixedPeriodCalculation,
  BodyCalculationsGetFixedPeriodSwedenPilotCalculation,
  BodyCalculationsGetPublicFixedMonthlyPaymentWithTaxCalculation,
  BodyCalculationsGetShortestPeriodCalculation,
  BookingCalculation,
  BookingUseType,
  ErrorResponse,
  ErrorWithCodeResponse,
  HTTPValidationError,
  SwedenSuggestedBenefitValuesCalculation,
  WithTaxDetailsBenefitCalculation,
} from "../models/index";
import {
  BenefitCalculationFromJSON,
  BenefitCalculationToJSON,
  BodyCalculationsGetFixedMonthlyPaymentCalculationFromJSON,
  BodyCalculationsGetFixedMonthlyPaymentCalculationToJSON,
  BodyCalculationsGetFixedMonthlyPaymentWithTaxCalculationFromJSON,
  BodyCalculationsGetFixedMonthlyPaymentWithTaxCalculationToJSON,
  BodyCalculationsGetFixedPeriodCalculationFromJSON,
  BodyCalculationsGetFixedPeriodCalculationToJSON,
  BodyCalculationsGetFixedPeriodSwedenPilotCalculationFromJSON,
  BodyCalculationsGetFixedPeriodSwedenPilotCalculationToJSON,
  BodyCalculationsGetPublicFixedMonthlyPaymentWithTaxCalculationFromJSON,
  BodyCalculationsGetPublicFixedMonthlyPaymentWithTaxCalculationToJSON,
  BodyCalculationsGetShortestPeriodCalculationFromJSON,
  BodyCalculationsGetShortestPeriodCalculationToJSON,
  BookingCalculationFromJSON,
  BookingCalculationToJSON,
  BookingUseTypeFromJSON,
  BookingUseTypeToJSON,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ErrorWithCodeResponseFromJSON,
  ErrorWithCodeResponseToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  SwedenSuggestedBenefitValuesCalculationFromJSON,
  SwedenSuggestedBenefitValuesCalculationToJSON,
  WithTaxDetailsBenefitCalculationFromJSON,
  WithTaxDetailsBenefitCalculationToJSON,
} from "../models/index";
import * as runtime from "../runtime";

export interface CalculationsGetBookingCalculationRequest {
  fleetId: string;
  useType: BookingUseType;
  startAt: Date;
  endAt: Date;
}

export interface CalculationsGetFixedMonthlyPaymentCalculationRequest {
  bodyCalculationsGetFixedMonthlyPaymentCalculation: BodyCalculationsGetFixedMonthlyPaymentCalculation;
}

export interface CalculationsGetFixedMonthlyPaymentWithTaxCalculationRequest {
  bodyCalculationsGetFixedMonthlyPaymentWithTaxCalculation: BodyCalculationsGetFixedMonthlyPaymentWithTaxCalculation;
}

export interface CalculationsGetFixedPeriodCalculationRequest {
  bodyCalculationsGetFixedPeriodCalculation: BodyCalculationsGetFixedPeriodCalculation;
}

export interface CalculationsGetFixedPeriodSwedenPilotCalculationRequest {
  bodyCalculationsGetFixedPeriodSwedenPilotCalculation: BodyCalculationsGetFixedPeriodSwedenPilotCalculation;
}

export interface CalculationsGetPublicFixedMonthlyPaymentWithTaxCalculationRequest {
  bodyCalculationsGetPublicFixedMonthlyPaymentWithTaxCalculation: BodyCalculationsGetPublicFixedMonthlyPaymentWithTaxCalculation;
}

export interface CalculationsGetShortestPeriodCalculationRequest {
  bodyCalculationsGetShortestPeriodCalculation: BodyCalculationsGetShortestPeriodCalculation;
}

export interface CalculationsGetSwedenSuggestedBenefitValuesCalculationForContractRequest {
  bikeBenefitContractId: string;
}

export interface CalculationsGetSwedenSuggestedBenefitValuesCalculationForOrderRequest {
  bikeBenefitOrderId: string;
}

/**
 *
 */
export class CalculationsApi extends runtime.BaseAPI {
  /**
   * Get Booking Calculation
   */
  async calculationsGetBookingCalculationRaw(
    requestParameters: CalculationsGetBookingCalculationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BookingCalculation>> {
    if (requestParameters["fleetId"] == null) {
      throw new runtime.RequiredError(
        "fleetId",
        'Required parameter "fleetId" was null or undefined when calling calculationsGetBookingCalculation().',
      );
    }

    if (requestParameters["useType"] == null) {
      throw new runtime.RequiredError(
        "useType",
        'Required parameter "useType" was null or undefined when calling calculationsGetBookingCalculation().',
      );
    }

    if (requestParameters["startAt"] == null) {
      throw new runtime.RequiredError(
        "startAt",
        'Required parameter "startAt" was null or undefined when calling calculationsGetBookingCalculation().',
      );
    }

    if (requestParameters["endAt"] == null) {
      throw new runtime.RequiredError(
        "endAt",
        'Required parameter "endAt" was null or undefined when calling calculationsGetBookingCalculation().',
      );
    }

    const queryParameters: any = {};

    if (requestParameters["fleetId"] != null) {
      queryParameters["fleet_id"] = requestParameters["fleetId"];
    }

    if (requestParameters["useType"] != null) {
      queryParameters["use_type"] = requestParameters["useType"];
    }

    if (requestParameters["startAt"] != null) {
      queryParameters["start_at"] = (
        requestParameters["startAt"] as any
      ).toISOString();
    }

    if (requestParameters["endAt"] != null) {
      queryParameters["end_at"] = (
        requestParameters["endAt"] as any
      ).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/calculations/booking`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BookingCalculationFromJSON(jsonValue),
    );
  }

  /**
   * Get Booking Calculation
   */
  async calculationsGetBookingCalculation(
    requestParameters: CalculationsGetBookingCalculationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BookingCalculation> {
    const response = await this.calculationsGetBookingCalculationRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Get Fixed Monthly Payment Calculation
   */
  async calculationsGetFixedMonthlyPaymentCalculationRaw(
    requestParameters: CalculationsGetFixedMonthlyPaymentCalculationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BenefitCalculation>> {
    if (
      requestParameters["bodyCalculationsGetFixedMonthlyPaymentCalculation"] ==
      null
    ) {
      throw new runtime.RequiredError(
        "bodyCalculationsGetFixedMonthlyPaymentCalculation",
        'Required parameter "bodyCalculationsGetFixedMonthlyPaymentCalculation" was null or undefined when calling calculationsGetFixedMonthlyPaymentCalculation().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/calculations/bike-benefit-fixed-monthly-payment`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: BodyCalculationsGetFixedMonthlyPaymentCalculationToJSON(
          requestParameters[
            "bodyCalculationsGetFixedMonthlyPaymentCalculation"
          ],
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BenefitCalculationFromJSON(jsonValue),
    );
  }

  /**
   * Get Fixed Monthly Payment Calculation
   */
  async calculationsGetFixedMonthlyPaymentCalculation(
    requestParameters: CalculationsGetFixedMonthlyPaymentCalculationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BenefitCalculation> {
    const response =
      await this.calculationsGetFixedMonthlyPaymentCalculationRaw(
        requestParameters,
        initOverrides,
      );
    return await response.value();
  }

  /**
   * Get Fixed Monthly Payment With Tax Calculation
   */
  async calculationsGetFixedMonthlyPaymentWithTaxCalculationRaw(
    requestParameters: CalculationsGetFixedMonthlyPaymentWithTaxCalculationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<WithTaxDetailsBenefitCalculation>> {
    if (
      requestParameters[
        "bodyCalculationsGetFixedMonthlyPaymentWithTaxCalculation"
      ] == null
    ) {
      throw new runtime.RequiredError(
        "bodyCalculationsGetFixedMonthlyPaymentWithTaxCalculation",
        'Required parameter "bodyCalculationsGetFixedMonthlyPaymentWithTaxCalculation" was null or undefined when calling calculationsGetFixedMonthlyPaymentWithTaxCalculation().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/calculations/bike-benefit-fixed-monthly-payment-with-tax`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: BodyCalculationsGetFixedMonthlyPaymentWithTaxCalculationToJSON(
          requestParameters[
            "bodyCalculationsGetFixedMonthlyPaymentWithTaxCalculation"
          ],
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      WithTaxDetailsBenefitCalculationFromJSON(jsonValue),
    );
  }

  /**
   * Get Fixed Monthly Payment With Tax Calculation
   */
  async calculationsGetFixedMonthlyPaymentWithTaxCalculation(
    requestParameters: CalculationsGetFixedMonthlyPaymentWithTaxCalculationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<WithTaxDetailsBenefitCalculation> {
    const response =
      await this.calculationsGetFixedMonthlyPaymentWithTaxCalculationRaw(
        requestParameters,
        initOverrides,
      );
    return await response.value();
  }

  /**
   * Get Fixed Period Calculation
   */
  async calculationsGetFixedPeriodCalculationRaw(
    requestParameters: CalculationsGetFixedPeriodCalculationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BenefitCalculation>> {
    if (
      requestParameters["bodyCalculationsGetFixedPeriodCalculation"] == null
    ) {
      throw new runtime.RequiredError(
        "bodyCalculationsGetFixedPeriodCalculation",
        'Required parameter "bodyCalculationsGetFixedPeriodCalculation" was null or undefined when calling calculationsGetFixedPeriodCalculation().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/calculations/bike-benefit-fixed-period`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: BodyCalculationsGetFixedPeriodCalculationToJSON(
          requestParameters["bodyCalculationsGetFixedPeriodCalculation"],
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BenefitCalculationFromJSON(jsonValue),
    );
  }

  /**
   * Get Fixed Period Calculation
   */
  async calculationsGetFixedPeriodCalculation(
    requestParameters: CalculationsGetFixedPeriodCalculationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BenefitCalculation> {
    const response = await this.calculationsGetFixedPeriodCalculationRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Get Fixed Period Sweden Pilot Calculation
   */
  async calculationsGetFixedPeriodSwedenPilotCalculationRaw(
    requestParameters: CalculationsGetFixedPeriodSwedenPilotCalculationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BenefitCalculation>> {
    if (
      requestParameters[
        "bodyCalculationsGetFixedPeriodSwedenPilotCalculation"
      ] == null
    ) {
      throw new runtime.RequiredError(
        "bodyCalculationsGetFixedPeriodSwedenPilotCalculation",
        'Required parameter "bodyCalculationsGetFixedPeriodSwedenPilotCalculation" was null or undefined when calling calculationsGetFixedPeriodSwedenPilotCalculation().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/calculations/bike-benefit-fixed-period-sweden-pilot`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: BodyCalculationsGetFixedPeriodSwedenPilotCalculationToJSON(
          requestParameters[
            "bodyCalculationsGetFixedPeriodSwedenPilotCalculation"
          ],
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BenefitCalculationFromJSON(jsonValue),
    );
  }

  /**
   * Get Fixed Period Sweden Pilot Calculation
   */
  async calculationsGetFixedPeriodSwedenPilotCalculation(
    requestParameters: CalculationsGetFixedPeriodSwedenPilotCalculationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BenefitCalculation> {
    const response =
      await this.calculationsGetFixedPeriodSwedenPilotCalculationRaw(
        requestParameters,
        initOverrides,
      );
    return await response.value();
  }

  /**
   * Get Public Fixed Monthly Payment With Tax Calculation
   */
  async calculationsGetPublicFixedMonthlyPaymentWithTaxCalculationRaw(
    requestParameters: CalculationsGetPublicFixedMonthlyPaymentWithTaxCalculationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<WithTaxDetailsBenefitCalculation>> {
    if (
      requestParameters[
        "bodyCalculationsGetPublicFixedMonthlyPaymentWithTaxCalculation"
      ] == null
    ) {
      throw new runtime.RequiredError(
        "bodyCalculationsGetPublicFixedMonthlyPaymentWithTaxCalculation",
        'Required parameter "bodyCalculationsGetPublicFixedMonthlyPaymentWithTaxCalculation" was null or undefined when calling calculationsGetPublicFixedMonthlyPaymentWithTaxCalculation().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/calculations/bike-benefit-public-fixed-monthly-payment-with-tax`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: BodyCalculationsGetPublicFixedMonthlyPaymentWithTaxCalculationToJSON(
          requestParameters[
            "bodyCalculationsGetPublicFixedMonthlyPaymentWithTaxCalculation"
          ],
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      WithTaxDetailsBenefitCalculationFromJSON(jsonValue),
    );
  }

  /**
   * Get Public Fixed Monthly Payment With Tax Calculation
   */
  async calculationsGetPublicFixedMonthlyPaymentWithTaxCalculation(
    requestParameters: CalculationsGetPublicFixedMonthlyPaymentWithTaxCalculationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<WithTaxDetailsBenefitCalculation> {
    const response =
      await this.calculationsGetPublicFixedMonthlyPaymentWithTaxCalculationRaw(
        requestParameters,
        initOverrides,
      );
    return await response.value();
  }

  /**
   * Get Shortest Period Calculation
   */
  async calculationsGetShortestPeriodCalculationRaw(
    requestParameters: CalculationsGetShortestPeriodCalculationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BenefitCalculation>> {
    if (
      requestParameters["bodyCalculationsGetShortestPeriodCalculation"] == null
    ) {
      throw new runtime.RequiredError(
        "bodyCalculationsGetShortestPeriodCalculation",
        'Required parameter "bodyCalculationsGetShortestPeriodCalculation" was null or undefined when calling calculationsGetShortestPeriodCalculation().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/calculations/bike-benefit-shortest-period`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: BodyCalculationsGetShortestPeriodCalculationToJSON(
          requestParameters["bodyCalculationsGetShortestPeriodCalculation"],
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BenefitCalculationFromJSON(jsonValue),
    );
  }

  /**
   * Get Shortest Period Calculation
   */
  async calculationsGetShortestPeriodCalculation(
    requestParameters: CalculationsGetShortestPeriodCalculationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BenefitCalculation> {
    const response = await this.calculationsGetShortestPeriodCalculationRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Get Sweden Suggested Benefit Values Calculation For Contract
   */
  async calculationsGetSwedenSuggestedBenefitValuesCalculationForContractRaw(
    requestParameters: CalculationsGetSwedenSuggestedBenefitValuesCalculationForContractRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SwedenSuggestedBenefitValuesCalculation>> {
    if (requestParameters["bikeBenefitContractId"] == null) {
      throw new runtime.RequiredError(
        "bikeBenefitContractId",
        'Required parameter "bikeBenefitContractId" was null or undefined when calling calculationsGetSwedenSuggestedBenefitValuesCalculationForContract().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/calculations/sweden-suggested-benefit-values-for-contract/{bike_benefit_contract_id}`.replace(
          `{${"bike_benefit_contract_id"}}`,
          encodeURIComponent(
            String(requestParameters["bikeBenefitContractId"]),
          ),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SwedenSuggestedBenefitValuesCalculationFromJSON(jsonValue),
    );
  }

  /**
   * Get Sweden Suggested Benefit Values Calculation For Contract
   */
  async calculationsGetSwedenSuggestedBenefitValuesCalculationForContract(
    requestParameters: CalculationsGetSwedenSuggestedBenefitValuesCalculationForContractRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SwedenSuggestedBenefitValuesCalculation> {
    const response =
      await this.calculationsGetSwedenSuggestedBenefitValuesCalculationForContractRaw(
        requestParameters,
        initOverrides,
      );
    return await response.value();
  }

  /**
   * Get Sweden Suggested Benefit Values Calculation For Order
   */
  async calculationsGetSwedenSuggestedBenefitValuesCalculationForOrderRaw(
    requestParameters: CalculationsGetSwedenSuggestedBenefitValuesCalculationForOrderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SwedenSuggestedBenefitValuesCalculation>> {
    if (requestParameters["bikeBenefitOrderId"] == null) {
      throw new runtime.RequiredError(
        "bikeBenefitOrderId",
        'Required parameter "bikeBenefitOrderId" was null or undefined when calling calculationsGetSwedenSuggestedBenefitValuesCalculationForOrder().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/calculations/sweden-suggested-benefit-values-for-order/{bike_benefit_order_id}`.replace(
          `{${"bike_benefit_order_id"}}`,
          encodeURIComponent(String(requestParameters["bikeBenefitOrderId"])),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SwedenSuggestedBenefitValuesCalculationFromJSON(jsonValue),
    );
  }

  /**
   * Get Sweden Suggested Benefit Values Calculation For Order
   */
  async calculationsGetSwedenSuggestedBenefitValuesCalculationForOrder(
    requestParameters: CalculationsGetSwedenSuggestedBenefitValuesCalculationForOrderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SwedenSuggestedBenefitValuesCalculation> {
    const response =
      await this.calculationsGetSwedenSuggestedBenefitValuesCalculationForOrderRaw(
        requestParameters,
        initOverrides,
      );
    return await response.value();
  }
}
