/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";

/**
 *
 * @export
 * @interface CalculationCorrectionData
 */
export interface CalculationCorrectionData {
  /**
   *
   * @type {string}
   * @memberof CalculationCorrectionData
   */
  correctedContractId: string;
}

/**
 * Check if a given object implements the CalculationCorrectionData interface.
 */
export function instanceOfCalculationCorrectionData(
  value: object,
): value is CalculationCorrectionData {
  if (
    !("correctedContractId" in value) ||
    value["correctedContractId"] === undefined
  )
    return false;
  return true;
}

export function CalculationCorrectionDataFromJSON(
  json: any,
): CalculationCorrectionData {
  return CalculationCorrectionDataFromJSONTyped(json, false);
}

export function CalculationCorrectionDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CalculationCorrectionData {
  if (json == null) {
    return json;
  }
  return {
    correctedContractId: json["corrected_contract_id"],
  };
}

export function CalculationCorrectionDataToJSON(
  json: any,
): CalculationCorrectionData {
  return CalculationCorrectionDataToJSONTyped(json, false);
}

export function CalculationCorrectionDataToJSONTyped(
  value?: CalculationCorrectionData | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    corrected_contract_id: value["correctedContractId"],
  };
}
