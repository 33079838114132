/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { UserBenefitDefinitionAccessRequestRead } from "./UserBenefitDefinitionAccessRequestRead";
import {
  UserBenefitDefinitionAccessRequestReadFromJSON,
  UserBenefitDefinitionAccessRequestReadFromJSONTyped,
  UserBenefitDefinitionAccessRequestReadToJSON,
  UserBenefitDefinitionAccessRequestReadToJSONTyped,
} from "./UserBenefitDefinitionAccessRequestRead";

/**
 *
 * @export
 * @interface UserBenefitActivationRead
 */
export interface UserBenefitActivationRead {
  /**
   *
   * @type {string}
   * @memberof UserBenefitActivationRead
   */
  userId: string;
  /**
   *
   * @type {string}
   * @memberof UserBenefitActivationRead
   */
  benefitDefinitionId: string;
  /**
   * If this value is null, it has been chosen by shop using the old 'choose what you want' logic
   * @type {string}
   * @memberof UserBenefitActivationRead
   */
  accessRulesId?: string;
  /**
   *
   * @type {Date}
   * @memberof UserBenefitActivationRead
   */
  revokedAt?: Date;
  /**
   *
   * @type {string}
   * @memberof UserBenefitActivationRead
   */
  revokedByUserId?: string;
  /**
   *
   * @type {string}
   * @memberof UserBenefitActivationRead
   */
  id: string;
  /**
   *
   * @type {UserBenefitDefinitionAccessRequestRead}
   * @memberof UserBenefitActivationRead
   */
  accessRequest?: UserBenefitDefinitionAccessRequestRead;
  /**
   *
   * @type {boolean}
   * @memberof UserBenefitActivationRead
   */
  isActive: boolean;
  /**
   *
   * @type {Date}
   * @memberof UserBenefitActivationRead
   */
  createdAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof UserBenefitActivationRead
   */
  updatedAt?: Date;
}

/**
 * Check if a given object implements the UserBenefitActivationRead interface.
 */
export function instanceOfUserBenefitActivationRead(
  value: object,
): value is UserBenefitActivationRead {
  if (!("userId" in value) || value["userId"] === undefined) return false;
  if (
    !("benefitDefinitionId" in value) ||
    value["benefitDefinitionId"] === undefined
  )
    return false;
  if (!("id" in value) || value["id"] === undefined) return false;
  if (!("isActive" in value) || value["isActive"] === undefined) return false;
  return true;
}

export function UserBenefitActivationReadFromJSON(
  json: any,
): UserBenefitActivationRead {
  return UserBenefitActivationReadFromJSONTyped(json, false);
}

export function UserBenefitActivationReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserBenefitActivationRead {
  if (json == null) {
    return json;
  }
  return {
    userId: json["user_id"],
    benefitDefinitionId: json["benefit_definition_id"],
    accessRulesId:
      json["access_rules_id"] == null ? undefined : json["access_rules_id"],
    revokedAt:
      json["revoked_at"] == null ? undefined : new Date(json["revoked_at"]),
    revokedByUserId:
      json["revoked_by_user_id"] == null
        ? undefined
        : json["revoked_by_user_id"],
    id: json["id"],
    accessRequest:
      json["access_request"] == null
        ? undefined
        : UserBenefitDefinitionAccessRequestReadFromJSON(
            json["access_request"],
          ),
    isActive: json["is_active"],
    createdAt:
      json["created_at"] == null ? undefined : new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] == null ? undefined : new Date(json["updated_at"]),
  };
}

export function UserBenefitActivationReadToJSON(
  json: any,
): UserBenefitActivationRead {
  return UserBenefitActivationReadToJSONTyped(json, false);
}

export function UserBenefitActivationReadToJSONTyped(
  value?: UserBenefitActivationRead | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    user_id: value["userId"],
    benefit_definition_id: value["benefitDefinitionId"],
    access_rules_id: value["accessRulesId"],
    revoked_at:
      value["revokedAt"] == null ? undefined : value["revokedAt"].toISOString(),
    revoked_by_user_id: value["revokedByUserId"],
    id: value["id"],
    access_request: UserBenefitDefinitionAccessRequestReadToJSON(
      value["accessRequest"],
    ),
    is_active: value["isActive"],
    created_at:
      value["createdAt"] == null ? undefined : value["createdAt"].toISOString(),
    updated_at:
      value["updatedAt"] == null ? undefined : value["updatedAt"].toISOString(),
  };
}
