/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";

/**
 *
 * @export
 * @interface UserSummaryRead
 */
export interface UserSummaryRead {
  /**
   *
   * @type {string}
   * @memberof UserSummaryRead
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UserSummaryRead
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UserSummaryRead
   */
  fullName?: string;
}

/**
 * Check if a given object implements the UserSummaryRead interface.
 */
export function instanceOfUserSummaryRead(
  value: object,
): value is UserSummaryRead {
  if (!("id" in value) || value["id"] === undefined) return false;
  if (!("email" in value) || value["email"] === undefined) return false;
  return true;
}

export function UserSummaryReadFromJSON(json: any): UserSummaryRead {
  return UserSummaryReadFromJSONTyped(json, false);
}

export function UserSummaryReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserSummaryRead {
  if (json == null) {
    return json;
  }
  return {
    id: json["id"],
    email: json["email"],
    fullName: json["full_name"] == null ? undefined : json["full_name"],
  };
}

export function UserSummaryReadToJSON(json: any): UserSummaryRead {
  return UserSummaryReadToJSONTyped(json, false);
}

export function UserSummaryReadToJSONTyped(
  value?: UserSummaryRead | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value["id"],
    email: value["email"],
    full_name: value["fullName"],
  };
}
