/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";

/**
 *
 * @export
 * @interface BookingCalculation
 */
export interface BookingCalculation {
  /**
   *
   * @type {number}
   * @memberof BookingCalculation
   */
  durationHours: number;
  /**
   *
   * @type {number}
   * @memberof BookingCalculation
   */
  price: number;
}

/**
 * Check if a given object implements the BookingCalculation interface.
 */
export function instanceOfBookingCalculation(
  value: object,
): value is BookingCalculation {
  if (!("durationHours" in value) || value["durationHours"] === undefined)
    return false;
  if (!("price" in value) || value["price"] === undefined) return false;
  return true;
}

export function BookingCalculationFromJSON(json: any): BookingCalculation {
  return BookingCalculationFromJSONTyped(json, false);
}

export function BookingCalculationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BookingCalculation {
  if (json == null) {
    return json;
  }
  return {
    durationHours: json["duration_hours"],
    price: json["price"],
  };
}

export function BookingCalculationToJSON(json: any): BookingCalculation {
  return BookingCalculationToJSONTyped(json, false);
}

export function BookingCalculationToJSONTyped(
  value?: BookingCalculation | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    duration_hours: value["durationHours"],
    price: value["price"],
  };
}
