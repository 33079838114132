/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";

/**
 *
 * @export
 * @interface WithTaxDetailsBenefitCalculation
 */
export interface WithTaxDetailsBenefitCalculation {
  /**
   *
   * @type {number}
   * @memberof WithTaxDetailsBenefitCalculation
   */
  leasingPeriodMonths: number;
  /**
   *
   * @type {number}
   * @memberof WithTaxDetailsBenefitCalculation
   */
  totalContractAmount: number;
  /**
   *
   * @type {number}
   * @memberof WithTaxDetailsBenefitCalculation
   */
  totalValueAmount: number;
  /**
   *
   * @type {number}
   * @memberof WithTaxDetailsBenefitCalculation
   */
  totalMaintenanceBudget: number;
  /**
   *
   * @type {number}
   * @memberof WithTaxDetailsBenefitCalculation
   */
  redemptionPercentage: number;
  /**
   *
   * @type {number}
   * @memberof WithTaxDetailsBenefitCalculation
   */
  residualValue: number;
  /**
   *
   * @type {number}
   * @memberof WithTaxDetailsBenefitCalculation
   */
  taxPercentageBefore: number;
  /**
   *
   * @type {number}
   * @memberof WithTaxDetailsBenefitCalculation
   */
  taxPercentageAfter: number;
  /**
   *
   * @type {number}
   * @memberof WithTaxDetailsBenefitCalculation
   */
  taxPercentageReduction: number;
  /**
   *
   * @type {number}
   * @memberof WithTaxDetailsBenefitCalculation
   */
  taxAmountBefore: number;
  /**
   *
   * @type {number}
   * @memberof WithTaxDetailsBenefitCalculation
   */
  taxAmountAfter: number;
  /**
   *
   * @type {number}
   * @memberof WithTaxDetailsBenefitCalculation
   */
  taxAmountReduction: number;
  /**
   *
   * @type {number}
   * @memberof WithTaxDetailsBenefitCalculation
   */
  totalFinancingCost: number;
  /**
   *
   * @type {number}
   * @memberof WithTaxDetailsBenefitCalculation
   */
  totalInsuranceCost: number;
  /**
   *
   * @type {number}
   * @memberof WithTaxDetailsBenefitCalculation
   */
  totalServiceCost: number;
  /**
   *
   * @type {number}
   * @memberof WithTaxDetailsBenefitCalculation
   */
  monthlyTaxReduction: number;
  /**
   *
   * @type {number}
   * @memberof WithTaxDetailsBenefitCalculation
   */
  actualMonthlyCost: number;
  /**
   *
   * @type {number}
   * @memberof WithTaxDetailsBenefitCalculation
   */
  vatRate: number;
}

/**
 * Check if a given object implements the WithTaxDetailsBenefitCalculation interface.
 */
export function instanceOfWithTaxDetailsBenefitCalculation(
  value: object,
): value is WithTaxDetailsBenefitCalculation {
  if (
    !("leasingPeriodMonths" in value) ||
    value["leasingPeriodMonths"] === undefined
  )
    return false;
  if (
    !("totalContractAmount" in value) ||
    value["totalContractAmount"] === undefined
  )
    return false;
  if (!("totalValueAmount" in value) || value["totalValueAmount"] === undefined)
    return false;
  if (
    !("totalMaintenanceBudget" in value) ||
    value["totalMaintenanceBudget"] === undefined
  )
    return false;
  if (
    !("redemptionPercentage" in value) ||
    value["redemptionPercentage"] === undefined
  )
    return false;
  if (!("residualValue" in value) || value["residualValue"] === undefined)
    return false;
  if (
    !("taxPercentageBefore" in value) ||
    value["taxPercentageBefore"] === undefined
  )
    return false;
  if (
    !("taxPercentageAfter" in value) ||
    value["taxPercentageAfter"] === undefined
  )
    return false;
  if (
    !("taxPercentageReduction" in value) ||
    value["taxPercentageReduction"] === undefined
  )
    return false;
  if (!("taxAmountBefore" in value) || value["taxAmountBefore"] === undefined)
    return false;
  if (!("taxAmountAfter" in value) || value["taxAmountAfter"] === undefined)
    return false;
  if (
    !("taxAmountReduction" in value) ||
    value["taxAmountReduction"] === undefined
  )
    return false;
  if (
    !("totalFinancingCost" in value) ||
    value["totalFinancingCost"] === undefined
  )
    return false;
  if (
    !("totalInsuranceCost" in value) ||
    value["totalInsuranceCost"] === undefined
  )
    return false;
  if (!("totalServiceCost" in value) || value["totalServiceCost"] === undefined)
    return false;
  if (
    !("monthlyTaxReduction" in value) ||
    value["monthlyTaxReduction"] === undefined
  )
    return false;
  if (
    !("actualMonthlyCost" in value) ||
    value["actualMonthlyCost"] === undefined
  )
    return false;
  if (!("vatRate" in value) || value["vatRate"] === undefined) return false;
  return true;
}

export function WithTaxDetailsBenefitCalculationFromJSON(
  json: any,
): WithTaxDetailsBenefitCalculation {
  return WithTaxDetailsBenefitCalculationFromJSONTyped(json, false);
}

export function WithTaxDetailsBenefitCalculationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): WithTaxDetailsBenefitCalculation {
  if (json == null) {
    return json;
  }
  return {
    leasingPeriodMonths: json["leasing_period_months"],
    totalContractAmount: json["total_contract_amount"],
    totalValueAmount: json["total_value_amount"],
    totalMaintenanceBudget: json["total_maintenance_budget"],
    redemptionPercentage: json["redemption_percentage"],
    residualValue: json["residual_value"],
    taxPercentageBefore: json["tax_percentage_before"],
    taxPercentageAfter: json["tax_percentage_after"],
    taxPercentageReduction: json["tax_percentage_reduction"],
    taxAmountBefore: json["tax_amount_before"],
    taxAmountAfter: json["tax_amount_after"],
    taxAmountReduction: json["tax_amount_reduction"],
    totalFinancingCost: json["total_financing_cost"],
    totalInsuranceCost: json["total_insurance_cost"],
    totalServiceCost: json["total_service_cost"],
    monthlyTaxReduction: json["monthly_tax_reduction"],
    actualMonthlyCost: json["actual_monthly_cost"],
    vatRate: json["vat_rate"],
  };
}

export function WithTaxDetailsBenefitCalculationToJSON(
  json: any,
): WithTaxDetailsBenefitCalculation {
  return WithTaxDetailsBenefitCalculationToJSONTyped(json, false);
}

export function WithTaxDetailsBenefitCalculationToJSONTyped(
  value?: WithTaxDetailsBenefitCalculation | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    leasing_period_months: value["leasingPeriodMonths"],
    total_contract_amount: value["totalContractAmount"],
    total_value_amount: value["totalValueAmount"],
    total_maintenance_budget: value["totalMaintenanceBudget"],
    redemption_percentage: value["redemptionPercentage"],
    residual_value: value["residualValue"],
    tax_percentage_before: value["taxPercentageBefore"],
    tax_percentage_after: value["taxPercentageAfter"],
    tax_percentage_reduction: value["taxPercentageReduction"],
    tax_amount_before: value["taxAmountBefore"],
    tax_amount_after: value["taxAmountAfter"],
    tax_amount_reduction: value["taxAmountReduction"],
    total_financing_cost: value["totalFinancingCost"],
    total_insurance_cost: value["totalInsuranceCost"],
    total_service_cost: value["totalServiceCost"],
    monthly_tax_reduction: value["monthlyTaxReduction"],
    actual_monthly_cost: value["actualMonthlyCost"],
    vat_rate: value["vatRate"],
  };
}
