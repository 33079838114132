/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const BenefitDefinitionCancellationCoverage = {
  NeverIncluded: "never_included",
  AlwaysIncludedEmployer: "always_included_employer",
  AlwaysIncludedEmployee: "always_included_employee",
  Free: "free",
} as const;
export type BenefitDefinitionCancellationCoverage =
  (typeof BenefitDefinitionCancellationCoverage)[keyof typeof BenefitDefinitionCancellationCoverage];

export function instanceOfBenefitDefinitionCancellationCoverage(
  value: any,
): boolean {
  for (const key in BenefitDefinitionCancellationCoverage) {
    if (
      Object.prototype.hasOwnProperty.call(
        BenefitDefinitionCancellationCoverage,
        key,
      )
    ) {
      if (
        BenefitDefinitionCancellationCoverage[
          key as keyof typeof BenefitDefinitionCancellationCoverage
        ] === value
      ) {
        return true;
      }
    }
  }
  return false;
}

export function BenefitDefinitionCancellationCoverageFromJSON(
  json: any,
): BenefitDefinitionCancellationCoverage {
  return BenefitDefinitionCancellationCoverageFromJSONTyped(json, false);
}

export function BenefitDefinitionCancellationCoverageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BenefitDefinitionCancellationCoverage {
  return json as BenefitDefinitionCancellationCoverage;
}

export function BenefitDefinitionCancellationCoverageToJSON(
  value?: BenefitDefinitionCancellationCoverage | null,
): any {
  return value as any;
}

export function BenefitDefinitionCancellationCoverageToJSONTyped(
  value: any,
  ignoreDiscriminator: boolean,
): BenefitDefinitionCancellationCoverage {
  return value as BenefitDefinitionCancellationCoverage;
}
