/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { Plan } from "./Plan";
import {
  PlanFromJSON,
  PlanFromJSONTyped,
  PlanToJSON,
  PlanToJSONTyped,
} from "./Plan";
import type { PledgedLender } from "./PledgedLender";
import {
  PledgedLenderFromJSON,
  PledgedLenderFromJSONTyped,
  PledgedLenderToJSON,
  PledgedLenderToJSONTyped,
} from "./PledgedLender";
import type { SupportedOrganisationCountries } from "./SupportedOrganisationCountries";
import {
  SupportedOrganisationCountriesFromJSON,
  SupportedOrganisationCountriesFromJSONTyped,
  SupportedOrganisationCountriesToJSON,
  SupportedOrganisationCountriesToJSONTyped,
} from "./SupportedOrganisationCountries";

/**
 *
 * @export
 * @interface OrganisationRead
 */
export interface OrganisationRead {
  /**
   *
   * @type {string}
   * @memberof OrganisationRead
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof OrganisationRead
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof OrganisationRead
   */
  businessId: string;
  /**
   *
   * @type {any}
   * @memberof OrganisationRead
   */
  path?: any | null;
  /**
   *
   * @type {string}
   * @memberof OrganisationRead
   */
  logoObjectId?: string;
  /**
   *
   * @type {SupportedOrganisationCountries}
   * @memberof OrganisationRead
   */
  country: SupportedOrganisationCountries;
  /**
   *
   * @type {string}
   * @memberof OrganisationRead
   */
  phoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof OrganisationRead
   */
  email?: string;
  /**
   *
   * @type {PledgedLender}
   * @memberof OrganisationRead
   */
  pledgedLender?: PledgedLender;
  /**
   *
   * @type {string}
   * @memberof OrganisationRead
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof OrganisationRead
   */
  parentId?: string;
  /**
   *
   * @type {string}
   * @memberof OrganisationRead
   */
  logoUrl?: string;
  /**
   *
   * @type {Plan}
   * @memberof OrganisationRead
   */
  plan?: Plan;
}

/**
 * Check if a given object implements the OrganisationRead interface.
 */
export function instanceOfOrganisationRead(
  value: object,
): value is OrganisationRead {
  if (!("name" in value) || value["name"] === undefined) return false;
  if (!("businessId" in value) || value["businessId"] === undefined)
    return false;
  if (!("country" in value) || value["country"] === undefined) return false;
  if (!("id" in value) || value["id"] === undefined) return false;
  return true;
}

export function OrganisationReadFromJSON(json: any): OrganisationRead {
  return OrganisationReadFromJSONTyped(json, false);
}

export function OrganisationReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): OrganisationRead {
  if (json == null) {
    return json;
  }
  return {
    name: json["name"],
    description: json["description"] == null ? undefined : json["description"],
    businessId: json["business_id"],
    path: json["path"] == null ? undefined : json["path"],
    logoObjectId:
      json["logo_object_id"] == null ? undefined : json["logo_object_id"],
    country: SupportedOrganisationCountriesFromJSON(json["country"]),
    phoneNumber:
      json["phone_number"] == null ? undefined : json["phone_number"],
    email: json["email"] == null ? undefined : json["email"],
    pledgedLender:
      json["pledged_lender"] == null
        ? undefined
        : PledgedLenderFromJSON(json["pledged_lender"]),
    id: json["id"],
    parentId: json["parent_id"] == null ? undefined : json["parent_id"],
    logoUrl: json["logo_url"] == null ? undefined : json["logo_url"],
    plan: json["plan"] == null ? undefined : PlanFromJSON(json["plan"]),
  };
}

export function OrganisationReadToJSON(json: any): OrganisationRead {
  return OrganisationReadToJSONTyped(json, false);
}

export function OrganisationReadToJSONTyped(
  value?: OrganisationRead | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    name: value["name"],
    description: value["description"],
    business_id: value["businessId"],
    path: value["path"],
    logo_object_id: value["logoObjectId"],
    country: SupportedOrganisationCountriesToJSON(value["country"]),
    phone_number: value["phoneNumber"],
    email: value["email"],
    pledged_lender: PledgedLenderToJSON(value["pledgedLender"]),
    id: value["id"],
    parent_id: value["parentId"],
    logo_url: value["logoUrl"],
    plan: PlanToJSON(value["plan"]),
  };
}
