/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const BikeDriveType = {
  Normal: "normal",
  Electric: "electric",
} as const;
export type BikeDriveType = (typeof BikeDriveType)[keyof typeof BikeDriveType];

export function instanceOfBikeDriveType(value: any): boolean {
  for (const key in BikeDriveType) {
    if (Object.prototype.hasOwnProperty.call(BikeDriveType, key)) {
      if (BikeDriveType[key as keyof typeof BikeDriveType] === value) {
        return true;
      }
    }
  }
  return false;
}

export function BikeDriveTypeFromJSON(json: any): BikeDriveType {
  return BikeDriveTypeFromJSONTyped(json, false);
}

export function BikeDriveTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BikeDriveType {
  return json as BikeDriveType;
}

export function BikeDriveTypeToJSON(value?: BikeDriveType | null): any {
  return value as any;
}

export function BikeDriveTypeToJSONTyped(
  value: any,
  ignoreDiscriminator: boolean,
): BikeDriveType {
  return value as BikeDriveType;
}
