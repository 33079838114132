/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const ReturnFee = {
  Never: "never",
  Always: "always",
} as const;
export type ReturnFee = (typeof ReturnFee)[keyof typeof ReturnFee];

export function instanceOfReturnFee(value: any): boolean {
  for (const key in ReturnFee) {
    if (Object.prototype.hasOwnProperty.call(ReturnFee, key)) {
      if (ReturnFee[key as keyof typeof ReturnFee] === value) {
        return true;
      }
    }
  }
  return false;
}

export function ReturnFeeFromJSON(json: any): ReturnFee {
  return ReturnFeeFromJSONTyped(json, false);
}

export function ReturnFeeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ReturnFee {
  return json as ReturnFee;
}

export function ReturnFeeToJSON(value?: ReturnFee | null): any {
  return value as any;
}

export function ReturnFeeToJSONTyped(
  value: any,
  ignoreDiscriminator: boolean,
): ReturnFee {
  return value as ReturnFee;
}
