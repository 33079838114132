/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";

/**
 *
 * @export
 * @interface CalculationRevisionData
 */
export interface CalculationRevisionData {
  /**
   *
   * @type {string}
   * @memberof CalculationRevisionData
   */
  revisedContractId: string;
  /**
   *
   * @type {Date}
   * @memberof CalculationRevisionData
   */
  revisionApplyDate: Date;
}

/**
 * Check if a given object implements the CalculationRevisionData interface.
 */
export function instanceOfCalculationRevisionData(
  value: object,
): value is CalculationRevisionData {
  if (
    !("revisedContractId" in value) ||
    value["revisedContractId"] === undefined
  )
    return false;
  if (
    !("revisionApplyDate" in value) ||
    value["revisionApplyDate"] === undefined
  )
    return false;
  return true;
}

export function CalculationRevisionDataFromJSON(
  json: any,
): CalculationRevisionData {
  return CalculationRevisionDataFromJSONTyped(json, false);
}

export function CalculationRevisionDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CalculationRevisionData {
  if (json == null) {
    return json;
  }
  return {
    revisedContractId: json["revised_contract_id"],
    revisionApplyDate: new Date(json["revision_apply_date"]),
  };
}

export function CalculationRevisionDataToJSON(
  json: any,
): CalculationRevisionData {
  return CalculationRevisionDataToJSONTyped(json, false);
}

export function CalculationRevisionDataToJSONTyped(
  value?: CalculationRevisionData | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    revised_contract_id: value["revisedContractId"],
    revision_apply_date: value["revisionApplyDate"]
      .toISOString()
      .substring(0, 10),
  };
}
