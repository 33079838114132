/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { Announcement } from "./Announcement";
import {
  AnnouncementFromJSON,
  AnnouncementFromJSONTyped,
  AnnouncementToJSON,
  AnnouncementToJSONTyped,
} from "./Announcement";

/**
 *
 * @export
 * @interface PaginatedAnnouncementOut
 */
export interface PaginatedAnnouncementOut {
  /**
   *
   * @type {number}
   * @memberof PaginatedAnnouncementOut
   */
  total: number;
  /**
   *
   * @type {Array<Announcement>}
   * @memberof PaginatedAnnouncementOut
   */
  items: Array<Announcement>;
}

/**
 * Check if a given object implements the PaginatedAnnouncementOut interface.
 */
export function instanceOfPaginatedAnnouncementOut(
  value: object,
): value is PaginatedAnnouncementOut {
  if (!("total" in value) || value["total"] === undefined) return false;
  if (!("items" in value) || value["items"] === undefined) return false;
  return true;
}

export function PaginatedAnnouncementOutFromJSON(
  json: any,
): PaginatedAnnouncementOut {
  return PaginatedAnnouncementOutFromJSONTyped(json, false);
}

export function PaginatedAnnouncementOutFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaginatedAnnouncementOut {
  if (json == null) {
    return json;
  }
  return {
    total: json["total"],
    items: (json["items"] as Array<any>).map(AnnouncementFromJSON),
  };
}

export function PaginatedAnnouncementOutToJSON(
  json: any,
): PaginatedAnnouncementOut {
  return PaginatedAnnouncementOutToJSONTyped(json, false);
}

export function PaginatedAnnouncementOutToJSONTyped(
  value?: PaginatedAnnouncementOut | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    total: value["total"],
    items: (value["items"] as Array<any>).map(AnnouncementToJSON),
  };
}
