/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";

/**
 *
 * @export
 * @interface BodyProviderBenefitDefinitionAccessRequestsAcceptAccessRequest
 */
export interface BodyProviderBenefitDefinitionAccessRequestsAcceptAccessRequest {
  /**
   *
   * @type {string}
   * @memberof BodyProviderBenefitDefinitionAccessRequestsAcceptAccessRequest
   */
  benefitDefinitionId?: string;
  /**
   *
   * @type {string}
   * @memberof BodyProviderBenefitDefinitionAccessRequestsAcceptAccessRequest
   */
  message?: string;
}

/**
 * Check if a given object implements the BodyProviderBenefitDefinitionAccessRequestsAcceptAccessRequest interface.
 */
export function instanceOfBodyProviderBenefitDefinitionAccessRequestsAcceptAccessRequest(
  value: object,
): value is BodyProviderBenefitDefinitionAccessRequestsAcceptAccessRequest {
  return true;
}

export function BodyProviderBenefitDefinitionAccessRequestsAcceptAccessRequestFromJSON(
  json: any,
): BodyProviderBenefitDefinitionAccessRequestsAcceptAccessRequest {
  return BodyProviderBenefitDefinitionAccessRequestsAcceptAccessRequestFromJSONTyped(
    json,
    false,
  );
}

export function BodyProviderBenefitDefinitionAccessRequestsAcceptAccessRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BodyProviderBenefitDefinitionAccessRequestsAcceptAccessRequest {
  if (json == null) {
    return json;
  }
  return {
    benefitDefinitionId:
      json["benefit_definition_id"] == null
        ? undefined
        : json["benefit_definition_id"],
    message: json["message"] == null ? undefined : json["message"],
  };
}

export function BodyProviderBenefitDefinitionAccessRequestsAcceptAccessRequestToJSON(
  json: any,
): BodyProviderBenefitDefinitionAccessRequestsAcceptAccessRequest {
  return BodyProviderBenefitDefinitionAccessRequestsAcceptAccessRequestToJSONTyped(
    json,
    false,
  );
}

export function BodyProviderBenefitDefinitionAccessRequestsAcceptAccessRequestToJSONTyped(
  value?: BodyProviderBenefitDefinitionAccessRequestsAcceptAccessRequest | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    benefit_definition_id: value["benefitDefinitionId"],
    message: value["message"],
  };
}
