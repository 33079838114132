/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { Accessory } from "./Accessory";
import {
  AccessoryFromJSON,
  AccessoryFromJSONTyped,
  AccessoryToJSON,
  AccessoryToJSONTyped,
} from "./Accessory";
import type { AdditionalService } from "./AdditionalService";
import {
  AdditionalServiceFromJSON,
  AdditionalServiceFromJSONTyped,
  AdditionalServiceToJSON,
  AdditionalServiceToJSONTyped,
} from "./AdditionalService";
import type { BenefitCancellationCoverage } from "./BenefitCancellationCoverage";
import {
  BenefitCancellationCoverageFromJSON,
  BenefitCancellationCoverageFromJSONTyped,
  BenefitCancellationCoverageToJSON,
  BenefitCancellationCoverageToJSONTyped,
} from "./BenefitCancellationCoverage";
import type { BenefitCancellationCoverageStatus } from "./BenefitCancellationCoverageStatus";
import {
  BenefitCancellationCoverageStatusFromJSON,
  BenefitCancellationCoverageStatusFromJSONTyped,
  BenefitCancellationCoverageStatusToJSON,
  BenefitCancellationCoverageStatusToJSONTyped,
} from "./BenefitCancellationCoverageStatus";
import type { BikeAdminRead } from "./BikeAdminRead";
import {
  BikeAdminReadFromJSON,
  BikeAdminReadFromJSONTyped,
  BikeAdminReadToJSON,
  BikeAdminReadToJSONTyped,
} from "./BikeAdminRead";
import type { BikeBenefitContractCancellationReason } from "./BikeBenefitContractCancellationReason";
import {
  BikeBenefitContractCancellationReasonFromJSON,
  BikeBenefitContractCancellationReasonFromJSONTyped,
  BikeBenefitContractCancellationReasonToJSON,
  BikeBenefitContractCancellationReasonToJSONTyped,
} from "./BikeBenefitContractCancellationReason";
import type { BikeBenefitContractState } from "./BikeBenefitContractState";
import {
  BikeBenefitContractStateFromJSON,
  BikeBenefitContractStateFromJSONTyped,
  BikeBenefitContractStateToJSON,
  BikeBenefitContractStateToJSONTyped,
} from "./BikeBenefitContractState";
import type { Currency } from "./Currency";
import {
  CurrencyFromJSON,
  CurrencyFromJSONTyped,
  CurrencyToJSON,
  CurrencyToJSONTyped,
} from "./Currency";
import type { FinancingMethods } from "./FinancingMethods";
import {
  FinancingMethodsFromJSON,
  FinancingMethodsFromJSONTyped,
  FinancingMethodsToJSON,
  FinancingMethodsToJSONTyped,
} from "./FinancingMethods";
import type { FundingSource } from "./FundingSource";
import {
  FundingSourceFromJSON,
  FundingSourceFromJSONTyped,
  FundingSourceToJSON,
  FundingSourceToJSONTyped,
} from "./FundingSource";
import type { Notification } from "./Notification";
import {
  NotificationFromJSON,
  NotificationFromJSONTyped,
  NotificationToJSON,
  NotificationToJSONTyped,
} from "./Notification";
import type { SupportedOrganisationCountries } from "./SupportedOrganisationCountries";
import {
  SupportedOrganisationCountriesFromJSON,
  SupportedOrganisationCountriesFromJSONTyped,
  SupportedOrganisationCountriesToJSON,
  SupportedOrganisationCountriesToJSONTyped,
} from "./SupportedOrganisationCountries";
import type { Transport } from "./Transport";
import {
  TransportFromJSON,
  TransportFromJSONTyped,
  TransportToJSON,
  TransportToJSONTyped,
} from "./Transport";
import type { UserRead } from "./UserRead";
import {
  UserReadFromJSON,
  UserReadFromJSONTyped,
  UserReadToJSON,
  UserReadToJSONTyped,
} from "./UserRead";

/**
 *
 * @export
 * @interface BikeBenefitContractAdminRead
 */
export interface BikeBenefitContractAdminRead {
  /**
   *
   * @type {Date}
   * @memberof BikeBenefitContractAdminRead
   */
  deliveryDate: Date;
  /**
   *
   * @type {Date}
   * @memberof BikeBenefitContractAdminRead
   */
  startDate: Date;
  /**
   *
   * @type {Date}
   * @memberof BikeBenefitContractAdminRead
   */
  endDate: Date;
  /**
   *
   * @type {Date}
   * @memberof BikeBenefitContractAdminRead
   */
  exceptionalEndDate?: Date;
  /**
   *
   * @type {BikeBenefitContractCancellationReason}
   * @memberof BikeBenefitContractAdminRead
   */
  cancellationReason?: BikeBenefitContractCancellationReason;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractAdminRead
   */
  cancellationReasonDescription?: string;
  /**
   *
   * @type {boolean}
   * @memberof BikeBenefitContractAdminRead
   */
  isInvalidated?: boolean;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  leasingPeriodMonths: number;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractAdminRead
   */
  userId: string;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractAdminRead
   */
  bikeBenefitOrderId: string;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractAdminRead
   */
  benefitDefinitionId: string;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractAdminRead
   */
  benefitDefinitionTermsId: string;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractAdminRead
   */
  bikeId: string;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  bikePrice: number;
  /**
   *
   * @type {Currency}
   * @memberof BikeBenefitContractAdminRead
   */
  currency: Currency;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  insuranceFee: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  netFixedMonthlyTaxableValue?: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  monthlyMaintenanceBudget: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  initialTotalPackagePrice: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  netTaxablePackagePrice: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  netMonthlyLeasingFee: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  netMonthlyTaxableValue: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  netTaxableValue: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  initialVatRate: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  netResidualValue: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  downPaymentAmount: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  extendedWarranty: number;
  /**
   *
   * @type {boolean}
   * @memberof BikeBenefitContractAdminRead
   */
  isDownPaymentInsured: boolean;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  yearlyDownPaymentInsuranceFee?: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  redemptionPercentage: number;
  /**
   *
   * @type {BenefitCancellationCoverage}
   * @memberof BikeBenefitContractAdminRead
   */
  cancellationCoverage?: BenefitCancellationCoverage;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractAdminRead
   */
  notes?: string;
  /**
   *
   * @type {FinancingMethods}
   * @memberof BikeBenefitContractAdminRead
   */
  financingMethod?: FinancingMethods;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  financierSellingPrice?: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  tukirahoitusMonthlyLeasingPrice?: number;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractAdminRead
   */
  tukirahoitusInterestRatesId?: string;
  /**
   *
   * @type {FundingSource}
   * @memberof BikeBenefitContractAdminRead
   */
  fundingSource?: FundingSource;
  /**
   *
   * @type {boolean}
   * @memberof BikeBenefitContractAdminRead
   */
  isEndedNotificationSent?: boolean;
  /**
   *
   * @type {Array<Notification>}
   * @memberof BikeBenefitContractAdminRead
   */
  sentNotifications?: Array<Notification>;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractAdminRead
   */
  id: string;
  /**
   *
   * @type {SupportedOrganisationCountries}
   * @memberof BikeBenefitContractAdminRead
   */
  benefitCountry: SupportedOrganisationCountries;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  currentFlatVatRate: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  monthlyTaxableValue: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  fixedMonthlyTaxableValue?: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  initialMonthlyTaxableValue: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  initialFixedMonthlyTaxableValue?: number;
  /**
   *
   * @type {Date}
   * @memberof BikeBenefitContractAdminRead
   */
  leasingPeriodStartDate: Date;
  /**
   *
   * @type {Date}
   * @memberof BikeBenefitContractAdminRead
   */
  effectiveEndDate: Date;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  totalPackagePrice: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  taxablePackagePrice: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  monthlyLeasingFee: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  taxableValue: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  taxablePackagePriceReduction: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  totalMaintenanceBudget: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  spentMaintenanceBudget: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  remainingMaintenanceBudget: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  residualRatio: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  residualValue: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  discountedResidualValue: number;
  /**
   *
   * @type {BikeAdminRead}
   * @memberof BikeBenefitContractAdminRead
   */
  bike: BikeAdminRead;
  /**
   *
   * @type {Array<Accessory>}
   * @memberof BikeBenefitContractAdminRead
   */
  accessories: Array<Accessory>;
  /**
   *
   * @type {BikeBenefitContractState}
   * @memberof BikeBenefitContractAdminRead
   */
  state: BikeBenefitContractState;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractAdminRead
   */
  vapausCode: string;
  /**
   *
   * @type {Array<AdditionalService>}
   * @memberof BikeBenefitContractAdminRead
   */
  additionalServices: Array<AdditionalService>;
  /**
   *
   * @type {Date}
   * @memberof BikeBenefitContractAdminRead
   */
  createdAt: Date;
  /**
   *
   * @type {Date}
   * @memberof BikeBenefitContractAdminRead
   */
  updatedAt: Date;
  /**
   *
   * @type {Array<Transport>}
   * @memberof BikeBenefitContractAdminRead
   */
  transports: Array<Transport>;
  /**
   *
   * @type {Transport}
   * @memberof BikeBenefitContractAdminRead
   */
  deliveryTransport?: Transport;
  /**
   *
   * @type {Array<string>}
   * @memberof BikeBenefitContractAdminRead
   */
  correctedByBikeBenefitOrderIds: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof BikeBenefitContractAdminRead
   */
  revisedByBikeBenefitOrderIds: Array<string>;
  /**
   *
   * @type {BenefitCancellationCoverageStatus}
   * @memberof BikeBenefitContractAdminRead
   */
  cancellationCoverageStatus: BenefitCancellationCoverageStatus;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  remainingLeasingPeriodMonths: number;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractAdminRead
   */
  currentRedemptionProcessId?: string;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractAdminRead
   */
  currentReturnProcessId?: string;
  /**
   *
   * @type {UserRead}
   * @memberof BikeBenefitContractAdminRead
   */
  user: UserRead;
}

/**
 * Check if a given object implements the BikeBenefitContractAdminRead interface.
 */
export function instanceOfBikeBenefitContractAdminRead(
  value: object,
): value is BikeBenefitContractAdminRead {
  if (!("deliveryDate" in value) || value["deliveryDate"] === undefined)
    return false;
  if (!("startDate" in value) || value["startDate"] === undefined) return false;
  if (!("endDate" in value) || value["endDate"] === undefined) return false;
  if (
    !("leasingPeriodMonths" in value) ||
    value["leasingPeriodMonths"] === undefined
  )
    return false;
  if (!("userId" in value) || value["userId"] === undefined) return false;
  if (
    !("bikeBenefitOrderId" in value) ||
    value["bikeBenefitOrderId"] === undefined
  )
    return false;
  if (
    !("benefitDefinitionId" in value) ||
    value["benefitDefinitionId"] === undefined
  )
    return false;
  if (
    !("benefitDefinitionTermsId" in value) ||
    value["benefitDefinitionTermsId"] === undefined
  )
    return false;
  if (!("bikeId" in value) || value["bikeId"] === undefined) return false;
  if (!("bikePrice" in value) || value["bikePrice"] === undefined) return false;
  if (!("currency" in value) || value["currency"] === undefined) return false;
  if (!("insuranceFee" in value) || value["insuranceFee"] === undefined)
    return false;
  if (
    !("monthlyMaintenanceBudget" in value) ||
    value["monthlyMaintenanceBudget"] === undefined
  )
    return false;
  if (
    !("initialTotalPackagePrice" in value) ||
    value["initialTotalPackagePrice"] === undefined
  )
    return false;
  if (
    !("netTaxablePackagePrice" in value) ||
    value["netTaxablePackagePrice"] === undefined
  )
    return false;
  if (
    !("netMonthlyLeasingFee" in value) ||
    value["netMonthlyLeasingFee"] === undefined
  )
    return false;
  if (
    !("netMonthlyTaxableValue" in value) ||
    value["netMonthlyTaxableValue"] === undefined
  )
    return false;
  if (!("netTaxableValue" in value) || value["netTaxableValue"] === undefined)
    return false;
  if (!("initialVatRate" in value) || value["initialVatRate"] === undefined)
    return false;
  if (!("netResidualValue" in value) || value["netResidualValue"] === undefined)
    return false;
  if (
    !("downPaymentAmount" in value) ||
    value["downPaymentAmount"] === undefined
  )
    return false;
  if (!("extendedWarranty" in value) || value["extendedWarranty"] === undefined)
    return false;
  if (
    !("isDownPaymentInsured" in value) ||
    value["isDownPaymentInsured"] === undefined
  )
    return false;
  if (
    !("redemptionPercentage" in value) ||
    value["redemptionPercentage"] === undefined
  )
    return false;
  if (!("id" in value) || value["id"] === undefined) return false;
  if (!("benefitCountry" in value) || value["benefitCountry"] === undefined)
    return false;
  if (
    !("currentFlatVatRate" in value) ||
    value["currentFlatVatRate"] === undefined
  )
    return false;
  if (
    !("monthlyTaxableValue" in value) ||
    value["monthlyTaxableValue"] === undefined
  )
    return false;
  if (
    !("initialMonthlyTaxableValue" in value) ||
    value["initialMonthlyTaxableValue"] === undefined
  )
    return false;
  if (
    !("leasingPeriodStartDate" in value) ||
    value["leasingPeriodStartDate"] === undefined
  )
    return false;
  if (!("effectiveEndDate" in value) || value["effectiveEndDate"] === undefined)
    return false;
  if (
    !("totalPackagePrice" in value) ||
    value["totalPackagePrice"] === undefined
  )
    return false;
  if (
    !("taxablePackagePrice" in value) ||
    value["taxablePackagePrice"] === undefined
  )
    return false;
  if (
    !("monthlyLeasingFee" in value) ||
    value["monthlyLeasingFee"] === undefined
  )
    return false;
  if (!("taxableValue" in value) || value["taxableValue"] === undefined)
    return false;
  if (
    !("taxablePackagePriceReduction" in value) ||
    value["taxablePackagePriceReduction"] === undefined
  )
    return false;
  if (
    !("totalMaintenanceBudget" in value) ||
    value["totalMaintenanceBudget"] === undefined
  )
    return false;
  if (
    !("spentMaintenanceBudget" in value) ||
    value["spentMaintenanceBudget"] === undefined
  )
    return false;
  if (
    !("remainingMaintenanceBudget" in value) ||
    value["remainingMaintenanceBudget"] === undefined
  )
    return false;
  if (!("residualRatio" in value) || value["residualRatio"] === undefined)
    return false;
  if (!("residualValue" in value) || value["residualValue"] === undefined)
    return false;
  if (
    !("discountedResidualValue" in value) ||
    value["discountedResidualValue"] === undefined
  )
    return false;
  if (!("bike" in value) || value["bike"] === undefined) return false;
  if (!("accessories" in value) || value["accessories"] === undefined)
    return false;
  if (!("state" in value) || value["state"] === undefined) return false;
  if (!("vapausCode" in value) || value["vapausCode"] === undefined)
    return false;
  if (
    !("additionalServices" in value) ||
    value["additionalServices"] === undefined
  )
    return false;
  if (!("createdAt" in value) || value["createdAt"] === undefined) return false;
  if (!("updatedAt" in value) || value["updatedAt"] === undefined) return false;
  if (!("transports" in value) || value["transports"] === undefined)
    return false;
  if (
    !("correctedByBikeBenefitOrderIds" in value) ||
    value["correctedByBikeBenefitOrderIds"] === undefined
  )
    return false;
  if (
    !("revisedByBikeBenefitOrderIds" in value) ||
    value["revisedByBikeBenefitOrderIds"] === undefined
  )
    return false;
  if (
    !("cancellationCoverageStatus" in value) ||
    value["cancellationCoverageStatus"] === undefined
  )
    return false;
  if (
    !("remainingLeasingPeriodMonths" in value) ||
    value["remainingLeasingPeriodMonths"] === undefined
  )
    return false;
  if (!("user" in value) || value["user"] === undefined) return false;
  return true;
}

export function BikeBenefitContractAdminReadFromJSON(
  json: any,
): BikeBenefitContractAdminRead {
  return BikeBenefitContractAdminReadFromJSONTyped(json, false);
}

export function BikeBenefitContractAdminReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BikeBenefitContractAdminRead {
  if (json == null) {
    return json;
  }
  return {
    deliveryDate: new Date(json["delivery_date"]),
    startDate: new Date(json["start_date"]),
    endDate: new Date(json["end_date"]),
    exceptionalEndDate:
      json["exceptional_end_date"] == null
        ? undefined
        : new Date(json["exceptional_end_date"]),
    cancellationReason:
      json["cancellation_reason"] == null
        ? undefined
        : BikeBenefitContractCancellationReasonFromJSON(
            json["cancellation_reason"],
          ),
    cancellationReasonDescription:
      json["cancellation_reason_description"] == null
        ? undefined
        : json["cancellation_reason_description"],
    isInvalidated:
      json["is_invalidated"] == null ? undefined : json["is_invalidated"],
    leasingPeriodMonths: json["leasing_period_months"],
    userId: json["user_id"],
    bikeBenefitOrderId: json["bike_benefit_order_id"],
    benefitDefinitionId: json["benefit_definition_id"],
    benefitDefinitionTermsId: json["benefit_definition_terms_id"],
    bikeId: json["bike_id"],
    bikePrice: json["bike_price"],
    currency: CurrencyFromJSON(json["currency"]),
    insuranceFee: json["insurance_fee"],
    netFixedMonthlyTaxableValue:
      json["net_fixed_monthly_taxable_value"] == null
        ? undefined
        : json["net_fixed_monthly_taxable_value"],
    monthlyMaintenanceBudget: json["monthly_maintenance_budget"],
    initialTotalPackagePrice: json["initial_total_package_price"],
    netTaxablePackagePrice: json["net_taxable_package_price"],
    netMonthlyLeasingFee: json["net_monthly_leasing_fee"],
    netMonthlyTaxableValue: json["net_monthly_taxable_value"],
    netTaxableValue: json["net_taxable_value"],
    initialVatRate: json["initial_vat_rate"],
    netResidualValue: json["net_residual_value"],
    downPaymentAmount: json["down_payment_amount"],
    extendedWarranty: json["extended_warranty"],
    isDownPaymentInsured: json["is_down_payment_insured"],
    yearlyDownPaymentInsuranceFee:
      json["yearly_down_payment_insurance_fee"] == null
        ? undefined
        : json["yearly_down_payment_insurance_fee"],
    redemptionPercentage: json["redemption_percentage"],
    cancellationCoverage:
      json["cancellation_coverage"] == null
        ? undefined
        : BenefitCancellationCoverageFromJSON(json["cancellation_coverage"]),
    notes: json["notes"] == null ? undefined : json["notes"],
    financingMethod:
      json["financing_method"] == null
        ? undefined
        : FinancingMethodsFromJSON(json["financing_method"]),
    financierSellingPrice:
      json["financier_selling_price"] == null
        ? undefined
        : json["financier_selling_price"],
    tukirahoitusMonthlyLeasingPrice:
      json["tukirahoitus_monthly_leasing_price"] == null
        ? undefined
        : json["tukirahoitus_monthly_leasing_price"],
    tukirahoitusInterestRatesId:
      json["tukirahoitus_interest_rates_id"] == null
        ? undefined
        : json["tukirahoitus_interest_rates_id"],
    fundingSource:
      json["funding_source"] == null
        ? undefined
        : FundingSourceFromJSON(json["funding_source"]),
    isEndedNotificationSent:
      json["is_ended_notification_sent"] == null
        ? undefined
        : json["is_ended_notification_sent"],
    sentNotifications:
      json["sent_notifications"] == null
        ? undefined
        : (json["sent_notifications"] as Array<any>).map(NotificationFromJSON),
    id: json["id"],
    benefitCountry: SupportedOrganisationCountriesFromJSON(
      json["benefit_country"],
    ),
    currentFlatVatRate: json["current_flat_vat_rate"],
    monthlyTaxableValue: json["monthly_taxable_value"],
    fixedMonthlyTaxableValue:
      json["fixed_monthly_taxable_value"] == null
        ? undefined
        : json["fixed_monthly_taxable_value"],
    initialMonthlyTaxableValue: json["initial_monthly_taxable_value"],
    initialFixedMonthlyTaxableValue:
      json["initial_fixed_monthly_taxable_value"] == null
        ? undefined
        : json["initial_fixed_monthly_taxable_value"],
    leasingPeriodStartDate: new Date(json["leasing_period_start_date"]),
    effectiveEndDate: new Date(json["effective_end_date"]),
    totalPackagePrice: json["total_package_price"],
    taxablePackagePrice: json["taxable_package_price"],
    monthlyLeasingFee: json["monthly_leasing_fee"],
    taxableValue: json["taxable_value"],
    taxablePackagePriceReduction: json["taxable_package_price_reduction"],
    totalMaintenanceBudget: json["total_maintenance_budget"],
    spentMaintenanceBudget: json["spent_maintenance_budget"],
    remainingMaintenanceBudget: json["remaining_maintenance_budget"],
    residualRatio: json["residual_ratio"],
    residualValue: json["residual_value"],
    discountedResidualValue: json["discounted_residual_value"],
    bike: BikeAdminReadFromJSON(json["bike"]),
    accessories: (json["accessories"] as Array<any>).map(AccessoryFromJSON),
    state: BikeBenefitContractStateFromJSON(json["state"]),
    vapausCode: json["vapaus_code"],
    additionalServices: (json["additional_services"] as Array<any>).map(
      AdditionalServiceFromJSON,
    ),
    createdAt: new Date(json["created_at"]),
    updatedAt: new Date(json["updated_at"]),
    transports: (json["transports"] as Array<any>).map(TransportFromJSON),
    deliveryTransport:
      json["delivery_transport"] == null
        ? undefined
        : TransportFromJSON(json["delivery_transport"]),
    correctedByBikeBenefitOrderIds: json["corrected_by_bike_benefit_order_ids"],
    revisedByBikeBenefitOrderIds: json["revised_by_bike_benefit_order_ids"],
    cancellationCoverageStatus: BenefitCancellationCoverageStatusFromJSON(
      json["cancellation_coverage_status"],
    ),
    remainingLeasingPeriodMonths: json["remaining_leasing_period_months"],
    currentRedemptionProcessId:
      json["current_redemption_process_id"] == null
        ? undefined
        : json["current_redemption_process_id"],
    currentReturnProcessId:
      json["current_return_process_id"] == null
        ? undefined
        : json["current_return_process_id"],
    user: UserReadFromJSON(json["user"]),
  };
}

export function BikeBenefitContractAdminReadToJSON(
  json: any,
): BikeBenefitContractAdminRead {
  return BikeBenefitContractAdminReadToJSONTyped(json, false);
}

export function BikeBenefitContractAdminReadToJSONTyped(
  value?: BikeBenefitContractAdminRead | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    delivery_date: value["deliveryDate"].toISOString().substring(0, 10),
    start_date: value["startDate"].toISOString().substring(0, 10),
    end_date: value["endDate"].toISOString().substring(0, 10),
    exceptional_end_date:
      value["exceptionalEndDate"] == null
        ? undefined
        : value["exceptionalEndDate"].toISOString().substring(0, 10),
    cancellation_reason: BikeBenefitContractCancellationReasonToJSON(
      value["cancellationReason"],
    ),
    cancellation_reason_description: value["cancellationReasonDescription"],
    is_invalidated: value["isInvalidated"],
    leasing_period_months: value["leasingPeriodMonths"],
    user_id: value["userId"],
    bike_benefit_order_id: value["bikeBenefitOrderId"],
    benefit_definition_id: value["benefitDefinitionId"],
    benefit_definition_terms_id: value["benefitDefinitionTermsId"],
    bike_id: value["bikeId"],
    bike_price: value["bikePrice"],
    currency: CurrencyToJSON(value["currency"]),
    insurance_fee: value["insuranceFee"],
    net_fixed_monthly_taxable_value: value["netFixedMonthlyTaxableValue"],
    monthly_maintenance_budget: value["monthlyMaintenanceBudget"],
    initial_total_package_price: value["initialTotalPackagePrice"],
    net_taxable_package_price: value["netTaxablePackagePrice"],
    net_monthly_leasing_fee: value["netMonthlyLeasingFee"],
    net_monthly_taxable_value: value["netMonthlyTaxableValue"],
    net_taxable_value: value["netTaxableValue"],
    initial_vat_rate: value["initialVatRate"],
    net_residual_value: value["netResidualValue"],
    down_payment_amount: value["downPaymentAmount"],
    extended_warranty: value["extendedWarranty"],
    is_down_payment_insured: value["isDownPaymentInsured"],
    yearly_down_payment_insurance_fee: value["yearlyDownPaymentInsuranceFee"],
    redemption_percentage: value["redemptionPercentage"],
    cancellation_coverage: BenefitCancellationCoverageToJSON(
      value["cancellationCoverage"],
    ),
    notes: value["notes"],
    financing_method: FinancingMethodsToJSON(value["financingMethod"]),
    financier_selling_price: value["financierSellingPrice"],
    tukirahoitus_monthly_leasing_price:
      value["tukirahoitusMonthlyLeasingPrice"],
    tukirahoitus_interest_rates_id: value["tukirahoitusInterestRatesId"],
    funding_source: FundingSourceToJSON(value["fundingSource"]),
    is_ended_notification_sent: value["isEndedNotificationSent"],
    sent_notifications:
      value["sentNotifications"] == null
        ? undefined
        : (value["sentNotifications"] as Array<any>).map(NotificationToJSON),
    id: value["id"],
    benefit_country: SupportedOrganisationCountriesToJSON(
      value["benefitCountry"],
    ),
    current_flat_vat_rate: value["currentFlatVatRate"],
    monthly_taxable_value: value["monthlyTaxableValue"],
    fixed_monthly_taxable_value: value["fixedMonthlyTaxableValue"],
    initial_monthly_taxable_value: value["initialMonthlyTaxableValue"],
    initial_fixed_monthly_taxable_value:
      value["initialFixedMonthlyTaxableValue"],
    leasing_period_start_date: value["leasingPeriodStartDate"]
      .toISOString()
      .substring(0, 10),
    effective_end_date: value["effectiveEndDate"]
      .toISOString()
      .substring(0, 10),
    total_package_price: value["totalPackagePrice"],
    taxable_package_price: value["taxablePackagePrice"],
    monthly_leasing_fee: value["monthlyLeasingFee"],
    taxable_value: value["taxableValue"],
    taxable_package_price_reduction: value["taxablePackagePriceReduction"],
    total_maintenance_budget: value["totalMaintenanceBudget"],
    spent_maintenance_budget: value["spentMaintenanceBudget"],
    remaining_maintenance_budget: value["remainingMaintenanceBudget"],
    residual_ratio: value["residualRatio"],
    residual_value: value["residualValue"],
    discounted_residual_value: value["discountedResidualValue"],
    bike: BikeAdminReadToJSON(value["bike"]),
    accessories: (value["accessories"] as Array<any>).map(AccessoryToJSON),
    state: BikeBenefitContractStateToJSON(value["state"]),
    vapaus_code: value["vapausCode"],
    additional_services: (value["additionalServices"] as Array<any>).map(
      AdditionalServiceToJSON,
    ),
    created_at: value["createdAt"].toISOString(),
    updated_at: value["updatedAt"].toISOString(),
    transports: (value["transports"] as Array<any>).map(TransportToJSON),
    delivery_transport: TransportToJSON(value["deliveryTransport"]),
    corrected_by_bike_benefit_order_ids:
      value["correctedByBikeBenefitOrderIds"],
    revised_by_bike_benefit_order_ids: value["revisedByBikeBenefitOrderIds"],
    cancellation_coverage_status: BenefitCancellationCoverageStatusToJSON(
      value["cancellationCoverageStatus"],
    ),
    remaining_leasing_period_months: value["remainingLeasingPeriodMonths"],
    current_redemption_process_id: value["currentRedemptionProcessId"],
    current_return_process_id: value["currentReturnProcessId"],
    user: UserReadToJSON(value["user"]),
  };
}
