/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";

/**
 *
 * @export
 * @interface BodyUserMergeUsers
 */
export interface BodyUserMergeUsers {
  /**
   *
   * @type {string}
   * @memberof BodyUserMergeUsers
   */
  obsoleteUserId: string;
  /**
   *
   * @type {string}
   * @memberof BodyUserMergeUsers
   */
  targetUserId: string;
}

/**
 * Check if a given object implements the BodyUserMergeUsers interface.
 */
export function instanceOfBodyUserMergeUsers(
  value: object,
): value is BodyUserMergeUsers {
  if (!("obsoleteUserId" in value) || value["obsoleteUserId"] === undefined)
    return false;
  if (!("targetUserId" in value) || value["targetUserId"] === undefined)
    return false;
  return true;
}

export function BodyUserMergeUsersFromJSON(json: any): BodyUserMergeUsers {
  return BodyUserMergeUsersFromJSONTyped(json, false);
}

export function BodyUserMergeUsersFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BodyUserMergeUsers {
  if (json == null) {
    return json;
  }
  return {
    obsoleteUserId: json["obsolete_user_id"],
    targetUserId: json["target_user_id"],
  };
}

export function BodyUserMergeUsersToJSON(json: any): BodyUserMergeUsers {
  return BodyUserMergeUsersToJSONTyped(json, false);
}

export function BodyUserMergeUsersToJSONTyped(
  value?: BodyUserMergeUsers | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    obsolete_user_id: value["obsoleteUserId"],
    target_user_id: value["targetUserId"],
  };
}
