/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";

/**
 *
 * @export
 * @interface BikeBrand
 */
export interface BikeBrand {
  /**
   *
   * @type {string}
   * @memberof BikeBrand
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof BikeBrand
   */
  active?: boolean;
  /**
   *
   * @type {string}
   * @memberof BikeBrand
   */
  id?: string;
  /**
   *
   * @type {Date}
   * @memberof BikeBrand
   */
  createdAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof BikeBrand
   */
  updatedAt?: Date;
}

/**
 * Check if a given object implements the BikeBrand interface.
 */
export function instanceOfBikeBrand(value: object): value is BikeBrand {
  if (!("name" in value) || value["name"] === undefined) return false;
  return true;
}

export function BikeBrandFromJSON(json: any): BikeBrand {
  return BikeBrandFromJSONTyped(json, false);
}

export function BikeBrandFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BikeBrand {
  if (json == null) {
    return json;
  }
  return {
    name: json["name"],
    active: json["active"] == null ? undefined : json["active"],
    id: json["id"] == null ? undefined : json["id"],
    createdAt:
      json["created_at"] == null ? undefined : new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] == null ? undefined : new Date(json["updated_at"]),
  };
}

export function BikeBrandToJSON(json: any): BikeBrand {
  return BikeBrandToJSONTyped(json, false);
}

export function BikeBrandToJSONTyped(
  value?: BikeBrand | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    name: value["name"],
    active: value["active"],
    id: value["id"],
    created_at:
      value["createdAt"] == null ? undefined : value["createdAt"].toISOString(),
    updated_at:
      value["updatedAt"] == null ? undefined : value["updatedAt"].toISOString(),
  };
}
