/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { BikeBenefitContractReturnOrRedemptionReason } from "./BikeBenefitContractReturnOrRedemptionReason";
import {
  BikeBenefitContractReturnOrRedemptionReasonFromJSON,
  BikeBenefitContractReturnOrRedemptionReasonFromJSONTyped,
  BikeBenefitContractReturnOrRedemptionReasonToJSON,
  BikeBenefitContractReturnOrRedemptionReasonToJSONTyped,
} from "./BikeBenefitContractReturnOrRedemptionReason";
import type { RedemptionProcessStatus } from "./RedemptionProcessStatus";
import {
  RedemptionProcessStatusFromJSON,
  RedemptionProcessStatusFromJSONTyped,
  RedemptionProcessStatusToJSON,
  RedemptionProcessStatusToJSONTyped,
} from "./RedemptionProcessStatus";

/**
 *
 * @export
 * @interface RedemptionProcessRead
 */
export interface RedemptionProcessRead {
  /**
   *
   * @type {string}
   * @memberof RedemptionProcessRead
   */
  bikeBenefitContractId: string;
  /**
   *
   * @type {Date}
   * @memberof RedemptionProcessRead
   */
  plannedRedemptionDate: Date;
  /**
   *
   * @type {BikeBenefitContractReturnOrRedemptionReason}
   * @memberof RedemptionProcessRead
   */
  redemptionReason: BikeBenefitContractReturnOrRedemptionReason;
  /**
   *
   * @type {string}
   * @memberof RedemptionProcessRead
   */
  requestingUserId?: string;
  /**
   *
   * @type {string}
   * @memberof RedemptionProcessRead
   */
  saleInvoiceId?: string;
  /**
   *
   * @type {string}
   * @memberof RedemptionProcessRead
   */
  cancellingUserId?: string;
  /**
   *
   * @type {Date}
   * @memberof RedemptionProcessRead
   */
  cancelledAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof RedemptionProcessRead
   */
  finalisedAt?: Date;
  /**
   *
   * @type {string}
   * @memberof RedemptionProcessRead
   */
  id: string;
  /**
   *
   * @type {RedemptionProcessStatus}
   * @memberof RedemptionProcessRead
   */
  status: RedemptionProcessStatus;
}

/**
 * Check if a given object implements the RedemptionProcessRead interface.
 */
export function instanceOfRedemptionProcessRead(
  value: object,
): value is RedemptionProcessRead {
  if (
    !("bikeBenefitContractId" in value) ||
    value["bikeBenefitContractId"] === undefined
  )
    return false;
  if (
    !("plannedRedemptionDate" in value) ||
    value["plannedRedemptionDate"] === undefined
  )
    return false;
  if (!("redemptionReason" in value) || value["redemptionReason"] === undefined)
    return false;
  if (!("id" in value) || value["id"] === undefined) return false;
  if (!("status" in value) || value["status"] === undefined) return false;
  return true;
}

export function RedemptionProcessReadFromJSON(
  json: any,
): RedemptionProcessRead {
  return RedemptionProcessReadFromJSONTyped(json, false);
}

export function RedemptionProcessReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): RedemptionProcessRead {
  if (json == null) {
    return json;
  }
  return {
    bikeBenefitContractId: json["bike_benefit_contract_id"],
    plannedRedemptionDate: new Date(json["planned_redemption_date"]),
    redemptionReason: BikeBenefitContractReturnOrRedemptionReasonFromJSON(
      json["redemption_reason"],
    ),
    requestingUserId:
      json["requesting_user_id"] == null
        ? undefined
        : json["requesting_user_id"],
    saleInvoiceId:
      json["sale_invoice_id"] == null ? undefined : json["sale_invoice_id"],
    cancellingUserId:
      json["cancelling_user_id"] == null
        ? undefined
        : json["cancelling_user_id"],
    cancelledAt:
      json["cancelled_at"] == null ? undefined : new Date(json["cancelled_at"]),
    finalisedAt:
      json["finalised_at"] == null ? undefined : new Date(json["finalised_at"]),
    id: json["id"],
    status: RedemptionProcessStatusFromJSON(json["status"]),
  };
}

export function RedemptionProcessReadToJSON(json: any): RedemptionProcessRead {
  return RedemptionProcessReadToJSONTyped(json, false);
}

export function RedemptionProcessReadToJSONTyped(
  value?: RedemptionProcessRead | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    bike_benefit_contract_id: value["bikeBenefitContractId"],
    planned_redemption_date: value["plannedRedemptionDate"]
      .toISOString()
      .substring(0, 10),
    redemption_reason: BikeBenefitContractReturnOrRedemptionReasonToJSON(
      value["redemptionReason"],
    ),
    requesting_user_id: value["requestingUserId"],
    sale_invoice_id: value["saleInvoiceId"],
    cancelling_user_id: value["cancellingUserId"],
    cancelled_at:
      value["cancelledAt"] == null
        ? undefined
        : value["cancelledAt"].toISOString(),
    finalised_at:
      value["finalisedAt"] == null
        ? undefined
        : value["finalisedAt"].toISOString(),
    id: value["id"],
    status: RedemptionProcessStatusToJSON(value["status"]),
  };
}
