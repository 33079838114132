import { useState } from "react";

import { sortBy } from "lodash-es";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";

import {
  BenefitDefinitionReadWithOrganisationSummary,
  SupportedOrganisationCountries,
} from "@vapaus/api-codegen";
import { BenefitTermsModal } from "@vapaus/common-v2";
import { useCurrencyFormat } from "@vapaus/i18n";
import {
  Button,
  CardRow,
  Flex,
  HorizontalLine,
  RowItem,
  Tooltip,
} from "@vapaus/ui-v2";

export function BenefitGuideline() {
  const { t } = useTranslation();
  const { benefitDefinition } = useOutletContext<{
    benefitDefinition: BenefitDefinitionReadWithOrganisationSummary;
  }>();
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const formatCurrency = useCurrencyFormat(benefitDefinition?.currency);
  const isSwedishBenefit =
    benefitDefinition?.organisation.country ===
    SupportedOrganisationCountries.Se;

  const handleOnClick = () => {
    if (benefitDefinition?.currentBenefitDefinitionTerm?.content) {
      return setIsTermsModalOpen(true);
    }
    window.open(
      benefitDefinition?.currentBenefitDefinitionTerm?.pdfObject?.publicUrl,
      "_blank",
    );
  };

  const getMonthlyPayment = () => {
    const fixedValues = sortBy(benefitDefinition?.fixedMonthlyTaxableValues);

    if (fixedValues && fixedValues.length > 0) {
      return fixedValues.length === 1
        ? {
            value: t("admin:adminPage.benefitTab.monthlyPayment.fixedValue", {
              value: formatCurrency(fixedValues[0]),
            }),
            tooltip: isSwedishBenefit
              ? t(
                  "admin:adminPage.benefitTab.monthlyRentalFee.singleFixedValue",
                  { value: formatCurrency(fixedValues[0]) },
                )
              : t(
                  "admin:adminPage.benefitTab.monthlyPayment.singleFixedValue",
                  { value: formatCurrency(fixedValues[0]) },
                ),
          }
        : {
            value: `${formatCurrency(fixedValues[0])}-${formatCurrency(
              fixedValues[fixedValues.length - 1],
            )}`,
            tooltip: isSwedishBenefit
              ? t(
                  "admin:adminPage.benefitTab.monthlyRentalFee.multipleFixedValues",
                  { value: formatCurrency(fixedValues[0]) },
                )
              : t(
                  "admin:adminPage.benefitTab.monthlyPayment.multipleFixedValues",
                  { value: formatCurrency(fixedValues[0]) },
                ),
          };
    }

    return {
      value: `${formatCurrency(
        benefitDefinition?.minimumMonthlyTaxableValue,
      )}-${formatCurrency(benefitDefinition?.maximumMonthlyTaxableValue)}`,
      tooltip: isSwedishBenefit
        ? t("admin:adminPage.benefitTab.monthlyRentalFee.valueRange")
        : t("admin:adminPage.benefitTab.monthlyPayment.valueRange"),
    };
  };

  return (
    <Flex direction="column">
      <RowItem
        label={t("admin:adminPage.benefitTab.maxBikePackage.label")}
        value={
          <Flex align="center">
            <span>
              {formatCurrency(benefitDefinition?.maximumTaxablePackagePrice)}
            </span>
            <Tooltip>
              {t("admin:adminPage.benefitTab.maxBikePackage.tooltip")}
            </Tooltip>
          </Flex>
        }
      />
      <RowItem
        label={t("admin:adminPage.benefitTab.downPayment.label")}
        value={
          <Flex align="center">
            <span>
              {benefitDefinition?.isDownpaymentAllowed
                ? t("admin:adminPage.benefitTab.downPayment.allowed")
                : t("admin:adminPage.benefitTab.downPayment.notAllowed")}
            </span>
            <Tooltip>
              {benefitDefinition?.isDownpaymentAllowed
                ? t("admin:adminPage.benefitTab.downPayment.tooltip.allowed")
                : t(
                    "admin:adminPage.benefitTab.downPayment.tooltip.notAllowed",
                  )}
            </Tooltip>
          </Flex>
        }
      />
      {benefitDefinition?.isDownpaymentAllowed ? (
        <RowItem
          label={t("admin:adminPage.benefitTab.maxPriceWithDownPayment.label")}
          value={
            <Flex align="center">
              <span>
                {formatCurrency(benefitDefinition?.maximumTotalPackagePrice)}
              </span>
              <Tooltip>
                {t(
                  "admin:adminPage.benefitTab.maxPriceWithDownPayment.tooltip",
                )}
              </Tooltip>
            </Flex>
          }
        />
      ) : null}
      <RowItem
        label={t("admin:adminPage.benefitTab.leasingPeriod.label")}
        value={
          <Flex align="center">
            <span>
              {t("admin:adminPage.benefitTab.leasingPeriod.value", {
                minPeriod: benefitDefinition?.minimumLeasingPeriod,
                maxPeriod: benefitDefinition?.maximumLeasingPeriod,
              })}
            </span>
            <Tooltip>
              {t("admin:adminPage.benefitTab.leasingPeriod.tooltip")}
            </Tooltip>
          </Flex>
        }
      />
      <RowItem
        label={
          isSwedishBenefit
            ? t("admin:adminPage.benefitTab.monthlyRentalFee.label")
            : t("admin:adminPage.benefitTab.monthlyPayment.label")
        }
        value={
          <Flex align="center">
            <span>{getMonthlyPayment().value}</span>
            <Tooltip>{getMonthlyPayment().tooltip}</Tooltip>
          </Flex>
        }
      />
      <HorizontalLine marginBottom="sm" />
      <CardRow
        title={t("admin:adminPage.benefitTab.guideCard.title")}
        description={t("admin:adminPage.benefitTab.guideCard.description")}
        action={
          <Button size="small" onClick={handleOnClick}>
            {t("admin:adminPage.benefitTab.guideCard.button")}
          </Button>
        }
      />
      <HorizontalLine marginTop="sm" marginBottom="none" />
      <BenefitTermsModal
        isOpen={isTermsModalOpen}
        onClose={() => setIsTermsModalOpen(false)}
        title={benefitDefinition?.name ?? ""}
        content={benefitDefinition?.currentBenefitDefinitionTerm?.content ?? ""}
      />
    </Flex>
  );
}
