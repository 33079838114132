/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const AdditionalServiceType = {
  Assembly: "assembly",
  BikeFitting: "bike_fitting",
  Other: "other",
} as const;
export type AdditionalServiceType =
  (typeof AdditionalServiceType)[keyof typeof AdditionalServiceType];

export function instanceOfAdditionalServiceType(value: any): boolean {
  for (const key in AdditionalServiceType) {
    if (Object.prototype.hasOwnProperty.call(AdditionalServiceType, key)) {
      if (
        AdditionalServiceType[key as keyof typeof AdditionalServiceType] ===
        value
      ) {
        return true;
      }
    }
  }
  return false;
}

export function AdditionalServiceTypeFromJSON(
  json: any,
): AdditionalServiceType {
  return AdditionalServiceTypeFromJSONTyped(json, false);
}

export function AdditionalServiceTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AdditionalServiceType {
  return json as AdditionalServiceType;
}

export function AdditionalServiceTypeToJSON(
  value?: AdditionalServiceType | null,
): any {
  return value as any;
}

export function AdditionalServiceTypeToJSONTyped(
  value: any,
  ignoreDiscriminator: boolean,
): AdditionalServiceType {
  return value as AdditionalServiceType;
}
