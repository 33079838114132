/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { BenefitDefinitionCancellationCoverage } from "./BenefitDefinitionCancellationCoverage";
import {
  BenefitDefinitionCancellationCoverageFromJSON,
  BenefitDefinitionCancellationCoverageFromJSONTyped,
  BenefitDefinitionCancellationCoverageToJSON,
  BenefitDefinitionCancellationCoverageToJSONTyped,
} from "./BenefitDefinitionCancellationCoverage";
import type { Currency } from "./Currency";
import {
  CurrencyFromJSON,
  CurrencyFromJSONTyped,
  CurrencyToJSON,
  CurrencyToJSONTyped,
} from "./Currency";
import type { OrganisationSummaryRead } from "./OrganisationSummaryRead";
import {
  OrganisationSummaryReadFromJSON,
  OrganisationSummaryReadFromJSONTyped,
  OrganisationSummaryReadToJSON,
  OrganisationSummaryReadToJSONTyped,
} from "./OrganisationSummaryRead";
import type { Plan } from "./Plan";
import {
  PlanFromJSON,
  PlanFromJSONTyped,
  PlanToJSON,
  PlanToJSONTyped,
} from "./Plan";

/**
 *
 * @export
 * @interface BenefitDefinitionSummaryRead
 */
export interface BenefitDefinitionSummaryRead {
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionSummaryRead
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionSummaryRead
   */
  name: string;
  /**
   *
   * @type {Date}
   * @memberof BenefitDefinitionSummaryRead
   */
  startDate: Date;
  /**
   *
   * @type {Date}
   * @memberof BenefitDefinitionSummaryRead
   */
  endDate: Date;
  /**
   *
   * @type {OrganisationSummaryRead}
   * @memberof BenefitDefinitionSummaryRead
   */
  organisation: OrganisationSummaryRead;
  /**
   *
   * @type {boolean}
   * @memberof BenefitDefinitionSummaryRead
   */
  active: boolean;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionSummaryRead
   */
  toWhom?: string;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionSummaryRead
   */
  accessRulesUuid?: string;
  /**
   *
   * @type {boolean}
   * @memberof BenefitDefinitionSummaryRead
   */
  isEmployeeNumberMandatory: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BenefitDefinitionSummaryRead
   */
  isCompanyMandatory: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BenefitDefinitionSummaryRead
   */
  isCostCenterMandatory: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BenefitDefinitionSummaryRead
   */
  isDownpaymentAllowed: boolean;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionSummaryRead
   */
  minimumLeasingPeriod: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionSummaryRead
   */
  maximumLeasingPeriod: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionSummaryRead
   */
  maximumTotalPackagePrice: number;
  /**
   *
   * @type {Currency}
   * @memberof BenefitDefinitionSummaryRead
   */
  currency: Currency;
  /**
   *
   * @type {Plan}
   * @memberof BenefitDefinitionSummaryRead
   */
  plan: Plan;
  /**
   *
   * @type {BenefitDefinitionCancellationCoverage}
   * @memberof BenefitDefinitionSummaryRead
   */
  cancellationCoverage: BenefitDefinitionCancellationCoverage;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionSummaryRead
   */
  individualOnetimeCancellationCoverage: number;
}

/**
 * Check if a given object implements the BenefitDefinitionSummaryRead interface.
 */
export function instanceOfBenefitDefinitionSummaryRead(
  value: object,
): value is BenefitDefinitionSummaryRead {
  if (!("id" in value) || value["id"] === undefined) return false;
  if (!("name" in value) || value["name"] === undefined) return false;
  if (!("startDate" in value) || value["startDate"] === undefined) return false;
  if (!("endDate" in value) || value["endDate"] === undefined) return false;
  if (!("organisation" in value) || value["organisation"] === undefined)
    return false;
  if (!("active" in value) || value["active"] === undefined) return false;
  if (
    !("isEmployeeNumberMandatory" in value) ||
    value["isEmployeeNumberMandatory"] === undefined
  )
    return false;
  if (
    !("isCompanyMandatory" in value) ||
    value["isCompanyMandatory"] === undefined
  )
    return false;
  if (
    !("isCostCenterMandatory" in value) ||
    value["isCostCenterMandatory"] === undefined
  )
    return false;
  if (
    !("isDownpaymentAllowed" in value) ||
    value["isDownpaymentAllowed"] === undefined
  )
    return false;
  if (
    !("minimumLeasingPeriod" in value) ||
    value["minimumLeasingPeriod"] === undefined
  )
    return false;
  if (
    !("maximumLeasingPeriod" in value) ||
    value["maximumLeasingPeriod"] === undefined
  )
    return false;
  if (
    !("maximumTotalPackagePrice" in value) ||
    value["maximumTotalPackagePrice"] === undefined
  )
    return false;
  if (!("currency" in value) || value["currency"] === undefined) return false;
  if (!("plan" in value) || value["plan"] === undefined) return false;
  if (
    !("cancellationCoverage" in value) ||
    value["cancellationCoverage"] === undefined
  )
    return false;
  if (
    !("individualOnetimeCancellationCoverage" in value) ||
    value["individualOnetimeCancellationCoverage"] === undefined
  )
    return false;
  return true;
}

export function BenefitDefinitionSummaryReadFromJSON(
  json: any,
): BenefitDefinitionSummaryRead {
  return BenefitDefinitionSummaryReadFromJSONTyped(json, false);
}

export function BenefitDefinitionSummaryReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BenefitDefinitionSummaryRead {
  if (json == null) {
    return json;
  }
  return {
    id: json["id"],
    name: json["name"],
    startDate: new Date(json["start_date"]),
    endDate: new Date(json["end_date"]),
    organisation: OrganisationSummaryReadFromJSON(json["organisation"]),
    active: json["active"],
    toWhom: json["to_whom"] == null ? undefined : json["to_whom"],
    accessRulesUuid:
      json["access_rules_uuid"] == null ? undefined : json["access_rules_uuid"],
    isEmployeeNumberMandatory: json["is_employee_number_mandatory"],
    isCompanyMandatory: json["is_company_mandatory"],
    isCostCenterMandatory: json["is_cost_center_mandatory"],
    isDownpaymentAllowed: json["is_downpayment_allowed"],
    minimumLeasingPeriod: json["minimum_leasing_period"],
    maximumLeasingPeriod: json["maximum_leasing_period"],
    maximumTotalPackagePrice: json["maximum_total_package_price"],
    currency: CurrencyFromJSON(json["currency"]),
    plan: PlanFromJSON(json["plan"]),
    cancellationCoverage: BenefitDefinitionCancellationCoverageFromJSON(
      json["cancellation_coverage"],
    ),
    individualOnetimeCancellationCoverage:
      json["individual_onetime_cancellation_coverage"],
  };
}

export function BenefitDefinitionSummaryReadToJSON(
  json: any,
): BenefitDefinitionSummaryRead {
  return BenefitDefinitionSummaryReadToJSONTyped(json, false);
}

export function BenefitDefinitionSummaryReadToJSONTyped(
  value?: BenefitDefinitionSummaryRead | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value["id"],
    name: value["name"],
    start_date: value["startDate"].toISOString().substring(0, 10),
    end_date: value["endDate"].toISOString().substring(0, 10),
    organisation: OrganisationSummaryReadToJSON(value["organisation"]),
    active: value["active"],
    to_whom: value["toWhom"],
    access_rules_uuid: value["accessRulesUuid"],
    is_employee_number_mandatory: value["isEmployeeNumberMandatory"],
    is_company_mandatory: value["isCompanyMandatory"],
    is_cost_center_mandatory: value["isCostCenterMandatory"],
    is_downpayment_allowed: value["isDownpaymentAllowed"],
    minimum_leasing_period: value["minimumLeasingPeriod"],
    maximum_leasing_period: value["maximumLeasingPeriod"],
    maximum_total_package_price: value["maximumTotalPackagePrice"],
    currency: CurrencyToJSON(value["currency"]),
    plan: PlanToJSON(value["plan"]),
    cancellation_coverage: BenefitDefinitionCancellationCoverageToJSON(
      value["cancellationCoverage"],
    ),
    individual_onetime_cancellation_coverage:
      value["individualOnetimeCancellationCoverage"],
  };
}
