/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  ErrorResponse,
  ErrorWithCodeResponse,
  HTTPValidationError,
} from "../models/index";
import {
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ErrorWithCodeResponseFromJSON,
  ErrorWithCodeResponseToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
} from "../models/index";
import * as runtime from "../runtime";

export interface EIdentEIdentReturnRequest {
  code: string;
  state: string;
}

export interface EIdentPostEIdentRequest {
  ssn?: string;
  redirectUriPath?: string;
}

/**
 *
 */
export class EIdentApi extends runtime.BaseAPI {
  /**
   * E Ident Return
   */
  async eIdentEIdentReturnRaw(
    requestParameters: EIdentEIdentReturnRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters["code"] == null) {
      throw new runtime.RequiredError(
        "code",
        'Required parameter "code" was null or undefined when calling eIdentEIdentReturn().',
      );
    }

    if (requestParameters["state"] == null) {
      throw new runtime.RequiredError(
        "state",
        'Required parameter "state" was null or undefined when calling eIdentEIdentReturn().',
      );
    }

    const queryParameters: any = {};

    if (requestParameters["code"] != null) {
      queryParameters["code"] = requestParameters["code"];
    }

    if (requestParameters["state"] != null) {
      queryParameters["state"] = requestParameters["state"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/eident/return`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * E Ident Return
   */
  async eIdentEIdentReturn(
    requestParameters: EIdentEIdentReturnRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.eIdentEIdentReturnRaw(requestParameters, initOverrides);
  }

  /**
   * Post E Ident
   */
  async eIdentPostEIdentRaw(
    requestParameters: EIdentPostEIdentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<any>> {
    const queryParameters: any = {};

    if (requestParameters["ssn"] != null) {
      queryParameters["ssn"] = requestParameters["ssn"];
    }

    if (requestParameters["redirectUriPath"] != null) {
      queryParameters["redirect_uri_path"] =
        requestParameters["redirectUriPath"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/eident`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get("content-type"))) {
      return new runtime.JSONApiResponse<any>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Post E Ident
   */
  async eIdentPostEIdent(
    requestParameters: EIdentPostEIdentRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<any> {
    const response = await this.eIdentPostEIdentRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
