/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";

/**
 *
 * @export
 * @interface BenefitUser
 */
export interface BenefitUser {
  /**
   *
   * @type {string}
   * @memberof BenefitUser
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof BenefitUser
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof BenefitUser
   */
  fullName?: string;
  /**
   *
   * @type {boolean}
   * @memberof BenefitUser
   */
  hasActiveBenefit: boolean;
}

/**
 * Check if a given object implements the BenefitUser interface.
 */
export function instanceOfBenefitUser(value: object): value is BenefitUser {
  if (!("id" in value) || value["id"] === undefined) return false;
  if (!("email" in value) || value["email"] === undefined) return false;
  if (!("hasActiveBenefit" in value) || value["hasActiveBenefit"] === undefined)
    return false;
  return true;
}

export function BenefitUserFromJSON(json: any): BenefitUser {
  return BenefitUserFromJSONTyped(json, false);
}

export function BenefitUserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BenefitUser {
  if (json == null) {
    return json;
  }
  return {
    id: json["id"],
    email: json["email"],
    fullName: json["full_name"] == null ? undefined : json["full_name"],
    hasActiveBenefit: json["has_active_benefit"],
  };
}

export function BenefitUserToJSON(json: any): BenefitUser {
  return BenefitUserToJSONTyped(json, false);
}

export function BenefitUserToJSONTyped(
  value?: BenefitUser | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value["id"],
    email: value["email"],
    full_name: value["fullName"],
    has_active_benefit: value["hasActiveBenefit"],
  };
}
