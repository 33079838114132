/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const FundingSource = {
  _1: "fundu_loan_1",
  _2: "fundu_loan_2",
} as const;
export type FundingSource = (typeof FundingSource)[keyof typeof FundingSource];

export function instanceOfFundingSource(value: any): boolean {
  for (const key in FundingSource) {
    if (Object.prototype.hasOwnProperty.call(FundingSource, key)) {
      if (FundingSource[key as keyof typeof FundingSource] === value) {
        return true;
      }
    }
  }
  return false;
}

export function FundingSourceFromJSON(json: any): FundingSource {
  return FundingSourceFromJSONTyped(json, false);
}

export function FundingSourceFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): FundingSource {
  return json as FundingSource;
}

export function FundingSourceToJSON(value?: FundingSource | null): any {
  return value as any;
}

export function FundingSourceToJSONTyped(
  value: any,
  ignoreDiscriminator: boolean,
): FundingSource {
  return value as FundingSource;
}
