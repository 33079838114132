/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { BenefitDefinitionAccessRulesRequestRequirements } from "./BenefitDefinitionAccessRulesRequestRequirements";
import {
  BenefitDefinitionAccessRulesRequestRequirementsFromJSON,
  BenefitDefinitionAccessRulesRequestRequirementsFromJSONTyped,
  BenefitDefinitionAccessRulesRequestRequirementsToJSON,
  BenefitDefinitionAccessRulesRequestRequirementsToJSONTyped,
} from "./BenefitDefinitionAccessRulesRequestRequirements";

/**
 *
 * @export
 * @interface BenefitDefinitionAccessRulesRead
 */
export interface BenefitDefinitionAccessRulesRead {
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionAccessRulesRead
   */
  name: string;
  /**
   * array of authorized emails. Supports specific emails (someone@somewhere.eu) and wildcards (@somewhere.eu)
   * @type {Array<string>}
   * @memberof BenefitDefinitionAccessRulesRead
   */
  allowedEmailAddresses?: Array<string>;
  /**
   * array of blacklisted emails.
   * @type {Array<string>}
   * @memberof BenefitDefinitionAccessRulesRead
   */
  blacklistEmails?: Array<string>;
  /**
   *
   * @type {BenefitDefinitionAccessRulesRequestRequirements}
   * @memberof BenefitDefinitionAccessRulesRead
   */
  requestRequirement: BenefitDefinitionAccessRulesRequestRequirements;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionAccessRulesRead
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionAccessRulesRead
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionAccessRulesRead
   */
  organisationId: string;
  /**
   *
   * @type {Date}
   * @memberof BenefitDefinitionAccessRulesRead
   */
  createdAt?: Date;
  /**
   *
   * @type {boolean}
   * @memberof BenefitDefinitionAccessRulesRead
   */
  isActive: boolean;
}

/**
 * Check if a given object implements the BenefitDefinitionAccessRulesRead interface.
 */
export function instanceOfBenefitDefinitionAccessRulesRead(
  value: object,
): value is BenefitDefinitionAccessRulesRead {
  if (!("name" in value) || value["name"] === undefined) return false;
  if (
    !("requestRequirement" in value) ||
    value["requestRequirement"] === undefined
  )
    return false;
  if (!("uuid" in value) || value["uuid"] === undefined) return false;
  if (!("id" in value) || value["id"] === undefined) return false;
  if (!("organisationId" in value) || value["organisationId"] === undefined)
    return false;
  if (!("isActive" in value) || value["isActive"] === undefined) return false;
  return true;
}

export function BenefitDefinitionAccessRulesReadFromJSON(
  json: any,
): BenefitDefinitionAccessRulesRead {
  return BenefitDefinitionAccessRulesReadFromJSONTyped(json, false);
}

export function BenefitDefinitionAccessRulesReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BenefitDefinitionAccessRulesRead {
  if (json == null) {
    return json;
  }
  return {
    name: json["name"],
    allowedEmailAddresses:
      json["allowed_email_addresses"] == null
        ? undefined
        : json["allowed_email_addresses"],
    blacklistEmails:
      json["blacklist_emails"] == null ? undefined : json["blacklist_emails"],
    requestRequirement: BenefitDefinitionAccessRulesRequestRequirementsFromJSON(
      json["request_requirement"],
    ),
    uuid: json["uuid"],
    id: json["id"],
    organisationId: json["organisation_id"],
    createdAt:
      json["created_at"] == null ? undefined : new Date(json["created_at"]),
    isActive: json["is_active"],
  };
}

export function BenefitDefinitionAccessRulesReadToJSON(
  json: any,
): BenefitDefinitionAccessRulesRead {
  return BenefitDefinitionAccessRulesReadToJSONTyped(json, false);
}

export function BenefitDefinitionAccessRulesReadToJSONTyped(
  value?: BenefitDefinitionAccessRulesRead | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    name: value["name"],
    allowed_email_addresses: value["allowedEmailAddresses"],
    blacklist_emails: value["blacklistEmails"],
    request_requirement: BenefitDefinitionAccessRulesRequestRequirementsToJSON(
      value["requestRequirement"],
    ),
    uuid: value["uuid"],
    id: value["id"],
    organisation_id: value["organisationId"],
    created_at:
      value["createdAt"] == null ? undefined : value["createdAt"].toISOString(),
    is_active: value["isActive"],
  };
}
