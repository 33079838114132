/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const AnnouncementVariant = {
  Success: "success",
  Error: "error",
  Warning: "warning",
  Neutral: "neutral",
} as const;
export type AnnouncementVariant =
  (typeof AnnouncementVariant)[keyof typeof AnnouncementVariant];

export function instanceOfAnnouncementVariant(value: any): boolean {
  for (const key in AnnouncementVariant) {
    if (Object.prototype.hasOwnProperty.call(AnnouncementVariant, key)) {
      if (
        AnnouncementVariant[key as keyof typeof AnnouncementVariant] === value
      ) {
        return true;
      }
    }
  }
  return false;
}

export function AnnouncementVariantFromJSON(json: any): AnnouncementVariant {
  return AnnouncementVariantFromJSONTyped(json, false);
}

export function AnnouncementVariantFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AnnouncementVariant {
  return json as AnnouncementVariant;
}

export function AnnouncementVariantToJSON(
  value?: AnnouncementVariant | null,
): any {
  return value as any;
}

export function AnnouncementVariantToJSONTyped(
  value: any,
  ignoreDiscriminator: boolean,
): AnnouncementVariant {
  return value as AnnouncementVariant;
}
