/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { AccessoryStatus } from "./AccessoryStatus";
import {
  AccessoryStatusFromJSON,
  AccessoryStatusFromJSONTyped,
  AccessoryStatusToJSON,
  AccessoryStatusToJSONTyped,
} from "./AccessoryStatus";
import type { AccessoryType } from "./AccessoryType";
import {
  AccessoryTypeFromJSON,
  AccessoryTypeFromJSONTyped,
  AccessoryTypeToJSON,
  AccessoryTypeToJSONTyped,
} from "./AccessoryType";
import type { Currency } from "./Currency";
import {
  CurrencyFromJSON,
  CurrencyFromJSONTyped,
  CurrencyToJSON,
  CurrencyToJSONTyped,
} from "./Currency";

/**
 *
 * @export
 * @interface Accessory
 */
export interface Accessory {
  /**
   *
   * @type {AccessoryType}
   * @memberof Accessory
   */
  accessoryType: AccessoryType;
  /**
   *
   * @type {string}
   * @memberof Accessory
   */
  manufacturer: string;
  /**
   *
   * @type {string}
   * @memberof Accessory
   */
  model: string;
  /**
   *
   * @type {string}
   * @memberof Accessory
   */
  size?: string;
  /**
   *
   * @type {string}
   * @memberof Accessory
   */
  colour?: string;
  /**
   *
   * @type {number}
   * @memberof Accessory
   */
  purchasePrice: number;
  /**
   *
   * @type {Currency}
   * @memberof Accessory
   */
  purchaseCurrency: Currency;
  /**
   *
   * @type {number}
   * @memberof Accessory
   */
  purchaseVatRate: number;
  /**
   *
   * @type {string}
   * @memberof Accessory
   */
  shopId: string;
  /**
   *
   * @type {AccessoryStatus}
   * @memberof Accessory
   */
  status: AccessoryStatus;
  /**
   *
   * @type {string}
   * @memberof Accessory
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof Accessory
   */
  id?: string;
  /**
   *
   * @type {Date}
   * @memberof Accessory
   */
  createdAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof Accessory
   */
  updatedAt?: Date;
}

/**
 * Check if a given object implements the Accessory interface.
 */
export function instanceOfAccessory(value: object): value is Accessory {
  if (!("accessoryType" in value) || value["accessoryType"] === undefined)
    return false;
  if (!("manufacturer" in value) || value["manufacturer"] === undefined)
    return false;
  if (!("model" in value) || value["model"] === undefined) return false;
  if (!("purchasePrice" in value) || value["purchasePrice"] === undefined)
    return false;
  if (!("purchaseCurrency" in value) || value["purchaseCurrency"] === undefined)
    return false;
  if (!("purchaseVatRate" in value) || value["purchaseVatRate"] === undefined)
    return false;
  if (!("shopId" in value) || value["shopId"] === undefined) return false;
  if (!("status" in value) || value["status"] === undefined) return false;
  return true;
}

export function AccessoryFromJSON(json: any): Accessory {
  return AccessoryFromJSONTyped(json, false);
}

export function AccessoryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Accessory {
  if (json == null) {
    return json;
  }
  return {
    accessoryType: AccessoryTypeFromJSON(json["accessory_type"]),
    manufacturer: json["manufacturer"],
    model: json["model"],
    size: json["size"] == null ? undefined : json["size"],
    colour: json["colour"] == null ? undefined : json["colour"],
    purchasePrice: json["purchase_price"],
    purchaseCurrency: CurrencyFromJSON(json["purchase_currency"]),
    purchaseVatRate: json["purchase_vat_rate"],
    shopId: json["shop_id"],
    status: AccessoryStatusFromJSON(json["status"]),
    url: json["url"] == null ? undefined : json["url"],
    id: json["id"] == null ? undefined : json["id"],
    createdAt:
      json["created_at"] == null ? undefined : new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] == null ? undefined : new Date(json["updated_at"]),
  };
}

export function AccessoryToJSON(json: any): Accessory {
  return AccessoryToJSONTyped(json, false);
}

export function AccessoryToJSONTyped(
  value?: Accessory | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    accessory_type: AccessoryTypeToJSON(value["accessoryType"]),
    manufacturer: value["manufacturer"],
    model: value["model"],
    size: value["size"],
    colour: value["colour"],
    purchase_price: value["purchasePrice"],
    purchase_currency: CurrencyToJSON(value["purchaseCurrency"]),
    purchase_vat_rate: value["purchaseVatRate"],
    shop_id: value["shopId"],
    status: AccessoryStatusToJSON(value["status"]),
    url: value["url"],
    id: value["id"],
    created_at:
      value["createdAt"] == null ? undefined : value["createdAt"].toISOString(),
    updated_at:
      value["updatedAt"] == null ? undefined : value["updatedAt"].toISOString(),
  };
}
