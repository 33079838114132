/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const AvailableScopes = {
  User: "user",
  Shop: "shop",
  Provider: "provider",
  Workshop: "workshop",
} as const;
export type AvailableScopes =
  (typeof AvailableScopes)[keyof typeof AvailableScopes];

export function instanceOfAvailableScopes(value: any): boolean {
  for (const key in AvailableScopes) {
    if (Object.prototype.hasOwnProperty.call(AvailableScopes, key)) {
      if (AvailableScopes[key as keyof typeof AvailableScopes] === value) {
        return true;
      }
    }
  }
  return false;
}

export function AvailableScopesFromJSON(json: any): AvailableScopes {
  return AvailableScopesFromJSONTyped(json, false);
}

export function AvailableScopesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AvailableScopes {
  return json as AvailableScopes;
}

export function AvailableScopesToJSON(value?: AvailableScopes | null): any {
  return value as any;
}

export function AvailableScopesToJSONTyped(
  value: any,
  ignoreDiscriminator: boolean,
): AvailableScopes {
  return value as AvailableScopes;
}
