/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { SupportedCountriesEnum } from "./SupportedCountriesEnum";
import {
  SupportedCountriesEnumFromJSON,
  SupportedCountriesEnumFromJSONTyped,
  SupportedCountriesEnumToJSON,
  SupportedCountriesEnumToJSONTyped,
} from "./SupportedCountriesEnum";
import type { SupportedLanguagesEnum } from "./SupportedLanguagesEnum";
import {
  SupportedLanguagesEnumFromJSON,
  SupportedLanguagesEnumFromJSONTyped,
  SupportedLanguagesEnumToJSON,
  SupportedLanguagesEnumToJSONTyped,
} from "./SupportedLanguagesEnum";

/**
 *
 * @export
 * @interface UserAdminCreate
 */
export interface UserAdminCreate {
  /**
   *
   * @type {string}
   * @memberof UserAdminCreate
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UserAdminCreate
   */
  password?: string;
  /**
   *
   * @type {string}
   * @memberof UserAdminCreate
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof UserAdminCreate
   */
  postCode?: string;
  /**
   *
   * @type {string}
   * @memberof UserAdminCreate
   */
  city?: string;
  /**
   *
   * @type {SupportedCountriesEnum}
   * @memberof UserAdminCreate
   */
  country?: SupportedCountriesEnum;
  /**
   *
   * @type {SupportedLanguagesEnum}
   * @memberof UserAdminCreate
   */
  language?: SupportedLanguagesEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof UserAdminCreate
   */
  fleetAccessCodes?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof UserAdminCreate
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof UserAdminCreate
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof UserAdminCreate
   */
  phoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof UserAdminCreate
   */
  company?: string;
  /**
   *
   * @type {string}
   * @memberof UserAdminCreate
   */
  costCenter?: string;
  /**
   *
   * @type {string}
   * @memberof UserAdminCreate
   */
  employeeNumber?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof UserAdminCreate
   */
  benefitDefinitionAccessCodes?: Array<string>;
}

/**
 * Check if a given object implements the UserAdminCreate interface.
 */
export function instanceOfUserAdminCreate(
  value: object,
): value is UserAdminCreate {
  if (!("email" in value) || value["email"] === undefined) return false;
  return true;
}

export function UserAdminCreateFromJSON(json: any): UserAdminCreate {
  return UserAdminCreateFromJSONTyped(json, false);
}

export function UserAdminCreateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserAdminCreate {
  if (json == null) {
    return json;
  }
  return {
    email: json["email"],
    password: json["password"] == null ? undefined : json["password"],
    address: json["address"] == null ? undefined : json["address"],
    postCode: json["post_code"] == null ? undefined : json["post_code"],
    city: json["city"] == null ? undefined : json["city"],
    country:
      json["country"] == null
        ? undefined
        : SupportedCountriesEnumFromJSON(json["country"]),
    language:
      json["language"] == null
        ? undefined
        : SupportedLanguagesEnumFromJSON(json["language"]),
    fleetAccessCodes:
      json["fleet_access_codes"] == null
        ? undefined
        : json["fleet_access_codes"],
    firstName: json["first_name"] == null ? undefined : json["first_name"],
    lastName: json["last_name"] == null ? undefined : json["last_name"],
    phoneNumber:
      json["phone_number"] == null ? undefined : json["phone_number"],
    company: json["company"] == null ? undefined : json["company"],
    costCenter: json["cost_center"] == null ? undefined : json["cost_center"],
    employeeNumber:
      json["employee_number"] == null ? undefined : json["employee_number"],
    benefitDefinitionAccessCodes:
      json["benefit_definition_access_codes"] == null
        ? undefined
        : json["benefit_definition_access_codes"],
  };
}

export function UserAdminCreateToJSON(json: any): UserAdminCreate {
  return UserAdminCreateToJSONTyped(json, false);
}

export function UserAdminCreateToJSONTyped(
  value?: UserAdminCreate | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    email: value["email"],
    password: value["password"],
    address: value["address"],
    post_code: value["postCode"],
    city: value["city"],
    country: SupportedCountriesEnumToJSON(value["country"]),
    language: SupportedLanguagesEnumToJSON(value["language"]),
    fleet_access_codes: value["fleetAccessCodes"],
    first_name: value["firstName"],
    last_name: value["lastName"],
    phone_number: value["phoneNumber"],
    company: value["company"],
    cost_center: value["costCenter"],
    employee_number: value["employeeNumber"],
    benefit_definition_access_codes: value["benefitDefinitionAccessCodes"],
  };
}
