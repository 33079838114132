/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const BikeBenefitContractReturnOrRedemptionReason = {
  EmploymentTerminatedByEmployer: "employment_terminated_by_employer",
  ChangingJobsOrLeavingCompany: "changing_jobs_or_leaving_company",
  LongLeave: "long_leave",
  WantDifferentBike: "want_different_bike",
  NotUsingBikeEnough: "not_using_bike_enough",
  WantToSpendMoneyDifferently: "want_to_spend_money_differently",
  Other: "other",
  EndOfLease: "end_of_lease",
} as const;
export type BikeBenefitContractReturnOrRedemptionReason =
  (typeof BikeBenefitContractReturnOrRedemptionReason)[keyof typeof BikeBenefitContractReturnOrRedemptionReason];

export function instanceOfBikeBenefitContractReturnOrRedemptionReason(
  value: any,
): boolean {
  for (const key in BikeBenefitContractReturnOrRedemptionReason) {
    if (
      Object.prototype.hasOwnProperty.call(
        BikeBenefitContractReturnOrRedemptionReason,
        key,
      )
    ) {
      if (
        BikeBenefitContractReturnOrRedemptionReason[
          key as keyof typeof BikeBenefitContractReturnOrRedemptionReason
        ] === value
      ) {
        return true;
      }
    }
  }
  return false;
}

export function BikeBenefitContractReturnOrRedemptionReasonFromJSON(
  json: any,
): BikeBenefitContractReturnOrRedemptionReason {
  return BikeBenefitContractReturnOrRedemptionReasonFromJSONTyped(json, false);
}

export function BikeBenefitContractReturnOrRedemptionReasonFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BikeBenefitContractReturnOrRedemptionReason {
  return json as BikeBenefitContractReturnOrRedemptionReason;
}

export function BikeBenefitContractReturnOrRedemptionReasonToJSON(
  value?: BikeBenefitContractReturnOrRedemptionReason | null,
): any {
  return value as any;
}

export function BikeBenefitContractReturnOrRedemptionReasonToJSONTyped(
  value: any,
  ignoreDiscriminator: boolean,
): BikeBenefitContractReturnOrRedemptionReason {
  return value as BikeBenefitContractReturnOrRedemptionReason;
}
