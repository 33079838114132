/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const MasterPermissions = {
  MasterRoot: "MASTER_ROOT",
  ErpSaleInvoice: "ERP_SALE_INVOICE",
} as const;
export type MasterPermissions =
  (typeof MasterPermissions)[keyof typeof MasterPermissions];

export function instanceOfMasterPermissions(value: any): boolean {
  for (const key in MasterPermissions) {
    if (Object.prototype.hasOwnProperty.call(MasterPermissions, key)) {
      if (MasterPermissions[key as keyof typeof MasterPermissions] === value) {
        return true;
      }
    }
  }
  return false;
}

export function MasterPermissionsFromJSON(json: any): MasterPermissions {
  return MasterPermissionsFromJSONTyped(json, false);
}

export function MasterPermissionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): MasterPermissions {
  return json as MasterPermissions;
}

export function MasterPermissionsToJSON(value?: MasterPermissions | null): any {
  return value as any;
}

export function MasterPermissionsToJSONTyped(
  value: any,
  ignoreDiscriminator: boolean,
): MasterPermissions {
  return value as MasterPermissions;
}
