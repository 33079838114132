import styled, { css } from "styled-components";

import { Flex } from "@vapaus/ui-v2";

import { ContractProps } from "../../type";
import { ContractBikePackage } from "./components/ContractBikePackage";
import { ContractBreakdown } from "./components/ContractBreakdown";
import { ContractEmployeeDetails } from "./components/ContractEmployeeDetails";
import { ContractMaintenanceBudget } from "./components/ContractMaintenanceBudget";
import { ContractSummary } from "./components/ContractSummary";
import { useContractParams } from "./hooks/useContractParams";

export function ContractBodySection({ contract }: ContractProps) {
  const contractParams = useContractParams({ contract });
  return (
    <StyledRoot direction="column" gap="none">
      <ContractSummary contract={contract} contractParams={contractParams} />
      <ContractBreakdown contract={contract} contractParams={contractParams} />
      <ContractBikePackage contract={contract} />
      <ContractMaintenanceBudget contract={contract} />
      <ContractEmployeeDetails contract={contract} />
    </StyledRoot>
  );
}

const StyledRoot = styled(Flex)`
  padding: ${({ theme }) => theme.spacing.xl};
  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      padding: ${({ theme }) => theme.spacing.xxl}
        ${({ theme }) => theme.spacing.md} ${({ theme }) => theme.spacing.xl};
    `)}
`;
