/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const ProviderNotificationCategory = {
  AccessRequests: "access_requests",
  ContractUpdates: "contract_updates",
} as const;
export type ProviderNotificationCategory =
  (typeof ProviderNotificationCategory)[keyof typeof ProviderNotificationCategory];

export function instanceOfProviderNotificationCategory(value: any): boolean {
  for (const key in ProviderNotificationCategory) {
    if (
      Object.prototype.hasOwnProperty.call(ProviderNotificationCategory, key)
    ) {
      if (
        ProviderNotificationCategory[
          key as keyof typeof ProviderNotificationCategory
        ] === value
      ) {
        return true;
      }
    }
  }
  return false;
}

export function ProviderNotificationCategoryFromJSON(
  json: any,
): ProviderNotificationCategory {
  return ProviderNotificationCategoryFromJSONTyped(json, false);
}

export function ProviderNotificationCategoryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ProviderNotificationCategory {
  return json as ProviderNotificationCategory;
}

export function ProviderNotificationCategoryToJSON(
  value?: ProviderNotificationCategory | null,
): any {
  return value as any;
}

export function ProviderNotificationCategoryToJSONTyped(
  value: any,
  ignoreDiscriminator: boolean,
): ProviderNotificationCategory {
  return value as ProviderNotificationCategory;
}
