/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";

/**
 *
 * @export
 * @interface PasswordResetToken
 */
export interface PasswordResetToken {
  /**
   *
   * @type {string}
   * @memberof PasswordResetToken
   */
  token: string;
  /**
   *
   * @type {string}
   * @memberof PasswordResetToken
   */
  newPassword: string;
}

/**
 * Check if a given object implements the PasswordResetToken interface.
 */
export function instanceOfPasswordResetToken(
  value: object,
): value is PasswordResetToken {
  if (!("token" in value) || value["token"] === undefined) return false;
  if (!("newPassword" in value) || value["newPassword"] === undefined)
    return false;
  return true;
}

export function PasswordResetTokenFromJSON(json: any): PasswordResetToken {
  return PasswordResetTokenFromJSONTyped(json, false);
}

export function PasswordResetTokenFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PasswordResetToken {
  if (json == null) {
    return json;
  }
  return {
    token: json["token"],
    newPassword: json["new_password"],
  };
}

export function PasswordResetTokenToJSON(json: any): PasswordResetToken {
  return PasswordResetTokenToJSONTyped(json, false);
}

export function PasswordResetTokenToJSONTyped(
  value?: PasswordResetToken | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    token: value["token"],
    new_password: value["newPassword"],
  };
}
