/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { BenefitDefinitionRead } from "./BenefitDefinitionRead";
import {
  BenefitDefinitionReadFromJSON,
  BenefitDefinitionReadFromJSONTyped,
  BenefitDefinitionReadToJSON,
  BenefitDefinitionReadToJSONTyped,
} from "./BenefitDefinitionRead";

/**
 *
 * @export
 * @interface PaginatedBenefitDefinitionOut
 */
export interface PaginatedBenefitDefinitionOut {
  /**
   *
   * @type {number}
   * @memberof PaginatedBenefitDefinitionOut
   */
  total: number;
  /**
   *
   * @type {Array<BenefitDefinitionRead>}
   * @memberof PaginatedBenefitDefinitionOut
   */
  items: Array<BenefitDefinitionRead>;
}

/**
 * Check if a given object implements the PaginatedBenefitDefinitionOut interface.
 */
export function instanceOfPaginatedBenefitDefinitionOut(
  value: object,
): value is PaginatedBenefitDefinitionOut {
  if (!("total" in value) || value["total"] === undefined) return false;
  if (!("items" in value) || value["items"] === undefined) return false;
  return true;
}

export function PaginatedBenefitDefinitionOutFromJSON(
  json: any,
): PaginatedBenefitDefinitionOut {
  return PaginatedBenefitDefinitionOutFromJSONTyped(json, false);
}

export function PaginatedBenefitDefinitionOutFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaginatedBenefitDefinitionOut {
  if (json == null) {
    return json;
  }
  return {
    total: json["total"],
    items: (json["items"] as Array<any>).map(BenefitDefinitionReadFromJSON),
  };
}

export function PaginatedBenefitDefinitionOutToJSON(
  json: any,
): PaginatedBenefitDefinitionOut {
  return PaginatedBenefitDefinitionOutToJSONTyped(json, false);
}

export function PaginatedBenefitDefinitionOutToJSONTyped(
  value?: PaginatedBenefitDefinitionOut | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    total: value["total"],
    items: (value["items"] as Array<any>).map(BenefitDefinitionReadToJSON),
  };
}
