/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  ErrorResponse,
  ErrorWithCodeResponse,
  HTTPValidationError,
  UserMepcoIntegrationSettingsCreateUpdate,
  UserMepcoIntegrationSettingsRead,
} from "../models/index";
import {
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ErrorWithCodeResponseFromJSON,
  ErrorWithCodeResponseToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  UserMepcoIntegrationSettingsCreateUpdateFromJSON,
  UserMepcoIntegrationSettingsCreateUpdateToJSON,
  UserMepcoIntegrationSettingsReadFromJSON,
  UserMepcoIntegrationSettingsReadToJSON,
} from "../models/index";
import * as runtime from "../runtime";

export interface ProviderMepcoGetUserMepcoIntegrationSettingsRequest {
  userId: string;
  organisationId: string;
}

export interface ProviderMepcoSetUserMepcoIntegrationSettingsRequest {
  userId: string;
  organisationId: string;
  userMepcoIntegrationSettingsCreateUpdate: UserMepcoIntegrationSettingsCreateUpdate;
}

/**
 *
 */
export class ProviderMepcoApi extends runtime.BaseAPI {
  /**
   * Get User Mepco Integration Settings
   */
  async providerMepcoGetUserMepcoIntegrationSettingsRaw(
    requestParameters: ProviderMepcoGetUserMepcoIntegrationSettingsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UserMepcoIntegrationSettingsRead>> {
    if (requestParameters["userId"] == null) {
      throw new runtime.RequiredError(
        "userId",
        'Required parameter "userId" was null or undefined when calling providerMepcoGetUserMepcoIntegrationSettings().',
      );
    }

    if (requestParameters["organisationId"] == null) {
      throw new runtime.RequiredError(
        "organisationId",
        'Required parameter "organisationId" was null or undefined when calling providerMepcoGetUserMepcoIntegrationSettings().',
      );
    }

    const queryParameters: any = {};

    if (requestParameters["organisationId"] != null) {
      queryParameters["organisation_id"] = requestParameters["organisationId"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/provider-api/mepco/user-integration-settings/{user_id}`.replace(
          `{${"user_id"}}`,
          encodeURIComponent(String(requestParameters["userId"])),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserMepcoIntegrationSettingsReadFromJSON(jsonValue),
    );
  }

  /**
   * Get User Mepco Integration Settings
   */
  async providerMepcoGetUserMepcoIntegrationSettings(
    requestParameters: ProviderMepcoGetUserMepcoIntegrationSettingsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UserMepcoIntegrationSettingsRead> {
    const response = await this.providerMepcoGetUserMepcoIntegrationSettingsRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Set User Mepco Integration Settings
   */
  async providerMepcoSetUserMepcoIntegrationSettingsRaw(
    requestParameters: ProviderMepcoSetUserMepcoIntegrationSettingsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UserMepcoIntegrationSettingsRead>> {
    if (requestParameters["userId"] == null) {
      throw new runtime.RequiredError(
        "userId",
        'Required parameter "userId" was null or undefined when calling providerMepcoSetUserMepcoIntegrationSettings().',
      );
    }

    if (requestParameters["organisationId"] == null) {
      throw new runtime.RequiredError(
        "organisationId",
        'Required parameter "organisationId" was null or undefined when calling providerMepcoSetUserMepcoIntegrationSettings().',
      );
    }

    if (requestParameters["userMepcoIntegrationSettingsCreateUpdate"] == null) {
      throw new runtime.RequiredError(
        "userMepcoIntegrationSettingsCreateUpdate",
        'Required parameter "userMepcoIntegrationSettingsCreateUpdate" was null or undefined when calling providerMepcoSetUserMepcoIntegrationSettings().',
      );
    }

    const queryParameters: any = {};

    if (requestParameters["organisationId"] != null) {
      queryParameters["organisation_id"] = requestParameters["organisationId"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/provider-api/mepco/user-integration-settings/{user_id}`.replace(
          `{${"user_id"}}`,
          encodeURIComponent(String(requestParameters["userId"])),
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: UserMepcoIntegrationSettingsCreateUpdateToJSON(
          requestParameters["userMepcoIntegrationSettingsCreateUpdate"],
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserMepcoIntegrationSettingsReadFromJSON(jsonValue),
    );
  }

  /**
   * Set User Mepco Integration Settings
   */
  async providerMepcoSetUserMepcoIntegrationSettings(
    requestParameters: ProviderMepcoSetUserMepcoIntegrationSettingsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UserMepcoIntegrationSettingsRead> {
    const response = await this.providerMepcoSetUserMepcoIntegrationSettingsRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
