/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { BikeBrand } from "./BikeBrand";
import {
  BikeBrandFromJSON,
  BikeBrandFromJSONTyped,
  BikeBrandToJSON,
  BikeBrandToJSONTyped,
} from "./BikeBrand";
import type { BikeCondition } from "./BikeCondition";
import {
  BikeConditionFromJSON,
  BikeConditionFromJSONTyped,
  BikeConditionToJSON,
  BikeConditionToJSONTyped,
} from "./BikeCondition";
import type { BikeDriveType } from "./BikeDriveType";
import {
  BikeDriveTypeFromJSON,
  BikeDriveTypeFromJSONTyped,
  BikeDriveTypeToJSON,
  BikeDriveTypeToJSONTyped,
} from "./BikeDriveType";
import type { BikeFrameType } from "./BikeFrameType";
import {
  BikeFrameTypeFromJSON,
  BikeFrameTypeFromJSONTyped,
  BikeFrameTypeToJSON,
  BikeFrameTypeToJSONTyped,
} from "./BikeFrameType";
import type { BikeStatus } from "./BikeStatus";
import {
  BikeStatusFromJSON,
  BikeStatusFromJSONTyped,
  BikeStatusToJSON,
  BikeStatusToJSONTyped,
} from "./BikeStatus";
import type { Currency } from "./Currency";
import {
  CurrencyFromJSON,
  CurrencyFromJSONTyped,
  CurrencyToJSON,
  CurrencyToJSONTyped,
} from "./Currency";

/**
 *
 * @export
 * @interface BikeAdminRead
 */
export interface BikeAdminRead {
  /**
   *
   * @type {string}
   * @memberof BikeAdminRead
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof BikeAdminRead
   */
  serialNumber: string;
  /**
   *
   * @type {Date}
   * @memberof BikeAdminRead
   */
  createdAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof BikeAdminRead
   */
  updatedAt?: Date;
  /**
   *
   * @type {BikeCondition}
   * @memberof BikeAdminRead
   */
  condition: BikeCondition;
  /**
   *
   * @type {BikeStatus}
   * @memberof BikeAdminRead
   */
  status: BikeStatus;
  /**
   *
   * @type {string}
   * @memberof BikeAdminRead
   */
  bikeBrandId: string;
  /**
   *
   * @type {string}
   * @memberof BikeAdminRead
   */
  model: string;
  /**
   *
   * @type {BikeDriveType}
   * @memberof BikeAdminRead
   */
  driveType?: BikeDriveType;
  /**
   *
   * @type {BikeFrameType}
   * @memberof BikeAdminRead
   */
  frameType?: BikeFrameType;
  /**
   *
   * @type {string}
   * @memberof BikeAdminRead
   */
  size: string;
  /**
   *
   * @type {string}
   * @memberof BikeAdminRead
   */
  colour: string;
  /**
   *
   * @type {number}
   * @memberof BikeAdminRead
   */
  purchasePrice: number;
  /**
   *
   * @type {Currency}
   * @memberof BikeAdminRead
   */
  purchaseCurrency: Currency;
  /**
   *
   * @type {number}
   * @memberof BikeAdminRead
   */
  purchaseVatRate: number;
  /**
   *
   * @type {number}
   * @memberof BikeAdminRead
   */
  netPurchasePrice: number;
  /**
   *
   * @type {string}
   * @memberof BikeAdminRead
   */
  shopId: string;
  /**
   *
   * @type {string}
   * @memberof BikeAdminRead
   */
  pictureId?: string;
  /**
   *
   * @type {string}
   * @memberof BikeAdminRead
   */
  url?: string;
  /**
   *
   * @type {BikeBrand}
   * @memberof BikeAdminRead
   */
  bikeBrand: BikeBrand;
}

/**
 * Check if a given object implements the BikeAdminRead interface.
 */
export function instanceOfBikeAdminRead(value: object): value is BikeAdminRead {
  if (!("id" in value) || value["id"] === undefined) return false;
  if (!("serialNumber" in value) || value["serialNumber"] === undefined)
    return false;
  if (!("condition" in value) || value["condition"] === undefined) return false;
  if (!("status" in value) || value["status"] === undefined) return false;
  if (!("bikeBrandId" in value) || value["bikeBrandId"] === undefined)
    return false;
  if (!("model" in value) || value["model"] === undefined) return false;
  if (!("size" in value) || value["size"] === undefined) return false;
  if (!("colour" in value) || value["colour"] === undefined) return false;
  if (!("purchasePrice" in value) || value["purchasePrice"] === undefined)
    return false;
  if (!("purchaseCurrency" in value) || value["purchaseCurrency"] === undefined)
    return false;
  if (!("purchaseVatRate" in value) || value["purchaseVatRate"] === undefined)
    return false;
  if (!("netPurchasePrice" in value) || value["netPurchasePrice"] === undefined)
    return false;
  if (!("shopId" in value) || value["shopId"] === undefined) return false;
  if (!("bikeBrand" in value) || value["bikeBrand"] === undefined) return false;
  return true;
}

export function BikeAdminReadFromJSON(json: any): BikeAdminRead {
  return BikeAdminReadFromJSONTyped(json, false);
}

export function BikeAdminReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BikeAdminRead {
  if (json == null) {
    return json;
  }
  return {
    id: json["id"],
    serialNumber: json["serial_number"],
    createdAt:
      json["created_at"] == null ? undefined : new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] == null ? undefined : new Date(json["updated_at"]),
    condition: BikeConditionFromJSON(json["condition"]),
    status: BikeStatusFromJSON(json["status"]),
    bikeBrandId: json["bike_brand_id"],
    model: json["model"],
    driveType:
      json["drive_type"] == null
        ? undefined
        : BikeDriveTypeFromJSON(json["drive_type"]),
    frameType:
      json["frame_type"] == null
        ? undefined
        : BikeFrameTypeFromJSON(json["frame_type"]),
    size: json["size"],
    colour: json["colour"],
    purchasePrice: json["purchase_price"],
    purchaseCurrency: CurrencyFromJSON(json["purchase_currency"]),
    purchaseVatRate: json["purchase_vat_rate"],
    netPurchasePrice: json["net_purchase_price"],
    shopId: json["shop_id"],
    pictureId: json["picture_id"] == null ? undefined : json["picture_id"],
    url: json["url"] == null ? undefined : json["url"],
    bikeBrand: BikeBrandFromJSON(json["bike_brand"]),
  };
}

export function BikeAdminReadToJSON(json: any): BikeAdminRead {
  return BikeAdminReadToJSONTyped(json, false);
}

export function BikeAdminReadToJSONTyped(
  value?: BikeAdminRead | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value["id"],
    serial_number: value["serialNumber"],
    created_at:
      value["createdAt"] == null ? undefined : value["createdAt"].toISOString(),
    updated_at:
      value["updatedAt"] == null ? undefined : value["updatedAt"].toISOString(),
    condition: BikeConditionToJSON(value["condition"]),
    status: BikeStatusToJSON(value["status"]),
    bike_brand_id: value["bikeBrandId"],
    model: value["model"],
    drive_type: BikeDriveTypeToJSON(value["driveType"]),
    frame_type: BikeFrameTypeToJSON(value["frameType"]),
    size: value["size"],
    colour: value["colour"],
    purchase_price: value["purchasePrice"],
    purchase_currency: CurrencyToJSON(value["purchaseCurrency"]),
    purchase_vat_rate: value["purchaseVatRate"],
    net_purchase_price: value["netPurchasePrice"],
    shop_id: value["shopId"],
    picture_id: value["pictureId"],
    url: value["url"],
    bike_brand: BikeBrandToJSON(value["bikeBrand"]),
  };
}
