/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { UserBenefitActivationRead } from "./UserBenefitActivationRead";
import {
  UserBenefitActivationReadFromJSON,
  UserBenefitActivationReadFromJSONTyped,
  UserBenefitActivationReadToJSON,
  UserBenefitActivationReadToJSONTyped,
} from "./UserBenefitActivationRead";

/**
 *
 * @export
 * @interface PaginatedUserBenefitActivationOut
 */
export interface PaginatedUserBenefitActivationOut {
  /**
   *
   * @type {number}
   * @memberof PaginatedUserBenefitActivationOut
   */
  total: number;
  /**
   *
   * @type {Array<UserBenefitActivationRead>}
   * @memberof PaginatedUserBenefitActivationOut
   */
  items: Array<UserBenefitActivationRead>;
}

/**
 * Check if a given object implements the PaginatedUserBenefitActivationOut interface.
 */
export function instanceOfPaginatedUserBenefitActivationOut(
  value: object,
): value is PaginatedUserBenefitActivationOut {
  if (!("total" in value) || value["total"] === undefined) return false;
  if (!("items" in value) || value["items"] === undefined) return false;
  return true;
}

export function PaginatedUserBenefitActivationOutFromJSON(
  json: any,
): PaginatedUserBenefitActivationOut {
  return PaginatedUserBenefitActivationOutFromJSONTyped(json, false);
}

export function PaginatedUserBenefitActivationOutFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaginatedUserBenefitActivationOut {
  if (json == null) {
    return json;
  }
  return {
    total: json["total"],
    items: (json["items"] as Array<any>).map(UserBenefitActivationReadFromJSON),
  };
}

export function PaginatedUserBenefitActivationOutToJSON(
  json: any,
): PaginatedUserBenefitActivationOut {
  return PaginatedUserBenefitActivationOutToJSONTyped(json, false);
}

export function PaginatedUserBenefitActivationOutToJSONTyped(
  value?: PaginatedUserBenefitActivationOut | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    total: value["total"],
    items: (value["items"] as Array<any>).map(UserBenefitActivationReadToJSON),
  };
}
