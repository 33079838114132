/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { EnumEntry } from "./EnumEntry";
import {
  EnumEntryFromJSON,
  EnumEntryFromJSONTyped,
  EnumEntryToJSON,
  EnumEntryToJSONTyped,
} from "./EnumEntry";

/**
 *
 * @export
 * @interface Enum
 */
export interface Enum {
  /**
   *
   * @type {string}
   * @memberof Enum
   */
  identifier: string;
  /**
   *
   * @type {Array<EnumEntry>}
   * @memberof Enum
   */
  entries: Array<EnumEntry>;
}

/**
 * Check if a given object implements the Enum interface.
 */
export function instanceOfEnum(value: object): value is Enum {
  if (!("identifier" in value) || value["identifier"] === undefined)
    return false;
  if (!("entries" in value) || value["entries"] === undefined) return false;
  return true;
}

export function EnumFromJSON(json: any): Enum {
  return EnumFromJSONTyped(json, false);
}

export function EnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Enum {
  if (json == null) {
    return json;
  }
  return {
    identifier: json["identifier"],
    entries: (json["entries"] as Array<any>).map(EnumEntryFromJSON),
  };
}

export function EnumToJSON(json: any): Enum {
  return EnumToJSONTyped(json, false);
}

export function EnumToJSONTyped(
  value?: Enum | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    identifier: value["identifier"],
    entries: (value["entries"] as Array<any>).map(EnumEntryToJSON),
  };
}
