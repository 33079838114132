/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";

/**
 *
 * @export
 * @interface PasswordChange
 */
export interface PasswordChange {
  /**
   *
   * @type {string}
   * @memberof PasswordChange
   */
  oldPassword: string;
  /**
   *
   * @type {string}
   * @memberof PasswordChange
   */
  newPassword: string;
  /**
   *
   * @type {string}
   * @memberof PasswordChange
   */
  newPasswordConfirmation: string;
}

/**
 * Check if a given object implements the PasswordChange interface.
 */
export function instanceOfPasswordChange(
  value: object,
): value is PasswordChange {
  if (!("oldPassword" in value) || value["oldPassword"] === undefined)
    return false;
  if (!("newPassword" in value) || value["newPassword"] === undefined)
    return false;
  if (
    !("newPasswordConfirmation" in value) ||
    value["newPasswordConfirmation"] === undefined
  )
    return false;
  return true;
}

export function PasswordChangeFromJSON(json: any): PasswordChange {
  return PasswordChangeFromJSONTyped(json, false);
}

export function PasswordChangeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PasswordChange {
  if (json == null) {
    return json;
  }
  return {
    oldPassword: json["old_password"],
    newPassword: json["new_password"],
    newPasswordConfirmation: json["new_password_confirmation"],
  };
}

export function PasswordChangeToJSON(json: any): PasswordChange {
  return PasswordChangeToJSONTyped(json, false);
}

export function PasswordChangeToJSONTyped(
  value?: PasswordChange | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    old_password: value["oldPassword"],
    new_password: value["newPassword"],
    new_password_confirmation: value["newPasswordConfirmation"],
  };
}
