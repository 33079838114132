/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";

/**
 *
 * @export
 * @interface UserEmailRead
 */
export interface UserEmailRead {
  /**
   *
   * @type {string}
   * @memberof UserEmailRead
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UserEmailRead
   */
  userId: string;
  /**
   *
   * @type {string}
   * @memberof UserEmailRead
   */
  email: string;
  /**
   *
   * @type {boolean}
   * @memberof UserEmailRead
   */
  emailVerified: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserEmailRead
   */
  primary: boolean;
}

/**
 * Check if a given object implements the UserEmailRead interface.
 */
export function instanceOfUserEmailRead(value: object): value is UserEmailRead {
  if (!("id" in value) || value["id"] === undefined) return false;
  if (!("userId" in value) || value["userId"] === undefined) return false;
  if (!("email" in value) || value["email"] === undefined) return false;
  if (!("emailVerified" in value) || value["emailVerified"] === undefined)
    return false;
  if (!("primary" in value) || value["primary"] === undefined) return false;
  return true;
}

export function UserEmailReadFromJSON(json: any): UserEmailRead {
  return UserEmailReadFromJSONTyped(json, false);
}

export function UserEmailReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserEmailRead {
  if (json == null) {
    return json;
  }
  return {
    id: json["id"],
    userId: json["user_id"],
    email: json["email"],
    emailVerified: json["email_verified"],
    primary: json["primary"],
  };
}

export function UserEmailReadToJSON(json: any): UserEmailRead {
  return UserEmailReadToJSONTyped(json, false);
}

export function UserEmailReadToJSONTyped(
  value?: UserEmailRead | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value["id"],
    user_id: value["userId"],
    email: value["email"],
    email_verified: value["emailVerified"],
    primary: value["primary"],
  };
}
