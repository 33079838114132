/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  Announcement,
  AnnouncementRead,
  AnnouncementWrite,
  AvailableScopes,
  ErrorResponse,
  ErrorWithCodeResponse,
  HTTPValidationError,
  OrderDirection,
  PaginatedAnnouncementOut,
  SupportedCountriesEnum,
} from "../models/index";
import {
  AnnouncementFromJSON,
  AnnouncementReadFromJSON,
  AnnouncementReadToJSON,
  AnnouncementToJSON,
  AnnouncementWriteFromJSON,
  AnnouncementWriteToJSON,
  AvailableScopesFromJSON,
  AvailableScopesToJSON,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ErrorWithCodeResponseFromJSON,
  ErrorWithCodeResponseToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  OrderDirectionFromJSON,
  OrderDirectionToJSON,
  PaginatedAnnouncementOutFromJSON,
  PaginatedAnnouncementOutToJSON,
  SupportedCountriesEnumFromJSON,
  SupportedCountriesEnumToJSON,
} from "../models/index";
import * as runtime from "../runtime";

export interface AnnouncementsCreateAnnouncementRequest {
  announcementWrite: AnnouncementWrite;
}

export interface AnnouncementsGetAnnouncementRequest {
  announcementId: string;
}

export interface AnnouncementsGetAnnouncementsRequest {
  country: SupportedCountriesEnum;
  scope: AvailableScopes;
}

export interface AnnouncementsSearchAnnouncementsRequest {
  q?: string;
  ids?: Array<string>;
  skip?: number;
  limit?: number;
  sortField?: string;
  sortDirection?: OrderDirection;
  xFilterIds?: string;
}

export interface AnnouncementsUpdateAnnouncementRequest {
  announcementId: string;
  announcementWrite: AnnouncementWrite;
}

/**
 *
 */
export class AnnouncementsApi extends runtime.BaseAPI {
  /**
   * Create Announcement
   */
  async announcementsCreateAnnouncementRaw(
    requestParameters: AnnouncementsCreateAnnouncementRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Announcement>> {
    if (requestParameters["announcementWrite"] == null) {
      throw new runtime.RequiredError(
        "announcementWrite",
        'Required parameter "announcementWrite" was null or undefined when calling announcementsCreateAnnouncement().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/admin-api/announcements`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: AnnouncementWriteToJSON(requestParameters["announcementWrite"]),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AnnouncementFromJSON(jsonValue),
    );
  }

  /**
   * Create Announcement
   */
  async announcementsCreateAnnouncement(
    requestParameters: AnnouncementsCreateAnnouncementRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Announcement> {
    const response = await this.announcementsCreateAnnouncementRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Get Announcement
   */
  async announcementsGetAnnouncementRaw(
    requestParameters: AnnouncementsGetAnnouncementRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Announcement>> {
    if (requestParameters["announcementId"] == null) {
      throw new runtime.RequiredError(
        "announcementId",
        'Required parameter "announcementId" was null or undefined when calling announcementsGetAnnouncement().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/admin-api/announcements/{announcement_id}`.replace(
          `{${"announcement_id"}}`,
          encodeURIComponent(String(requestParameters["announcementId"])),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AnnouncementFromJSON(jsonValue),
    );
  }

  /**
   * Get Announcement
   */
  async announcementsGetAnnouncement(
    requestParameters: AnnouncementsGetAnnouncementRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Announcement> {
    const response = await this.announcementsGetAnnouncementRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Get Announcements
   */
  async announcementsGetAnnouncementsRaw(
    requestParameters: AnnouncementsGetAnnouncementsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<AnnouncementRead>>> {
    if (requestParameters["country"] == null) {
      throw new runtime.RequiredError(
        "country",
        'Required parameter "country" was null or undefined when calling announcementsGetAnnouncements().',
      );
    }

    if (requestParameters["scope"] == null) {
      throw new runtime.RequiredError(
        "scope",
        'Required parameter "scope" was null or undefined when calling announcementsGetAnnouncements().',
      );
    }

    const queryParameters: any = {};

    if (requestParameters["country"] != null) {
      queryParameters["country"] = requestParameters["country"];
    }

    if (requestParameters["scope"] != null) {
      queryParameters["scope"] = requestParameters["scope"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/announcements`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(AnnouncementReadFromJSON),
    );
  }

  /**
   * Get Announcements
   */
  async announcementsGetAnnouncements(
    requestParameters: AnnouncementsGetAnnouncementsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<AnnouncementRead>> {
    const response = await this.announcementsGetAnnouncementsRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Search Announcements
   */
  async announcementsSearchAnnouncementsRaw(
    requestParameters: AnnouncementsSearchAnnouncementsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedAnnouncementOut>> {
    const queryParameters: any = {};

    if (requestParameters["q"] != null) {
      queryParameters["q"] = requestParameters["q"];
    }

    if (requestParameters["ids"] != null) {
      queryParameters["ids"] = requestParameters["ids"];
    }

    if (requestParameters["skip"] != null) {
      queryParameters["skip"] = requestParameters["skip"];
    }

    if (requestParameters["limit"] != null) {
      queryParameters["limit"] = requestParameters["limit"];
    }

    if (requestParameters["sortField"] != null) {
      queryParameters["sort_field"] = requestParameters["sortField"];
    }

    if (requestParameters["sortDirection"] != null) {
      queryParameters["sort_direction"] = requestParameters["sortDirection"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters["xFilterIds"] != null) {
      headerParameters["X-Filter-Ids"] = String(
        requestParameters["xFilterIds"],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/admin-api/announcements`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedAnnouncementOutFromJSON(jsonValue),
    );
  }

  /**
   * Search Announcements
   */
  async announcementsSearchAnnouncements(
    requestParameters: AnnouncementsSearchAnnouncementsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedAnnouncementOut> {
    const response = await this.announcementsSearchAnnouncementsRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Update Announcement
   */
  async announcementsUpdateAnnouncementRaw(
    requestParameters: AnnouncementsUpdateAnnouncementRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Announcement>> {
    if (requestParameters["announcementId"] == null) {
      throw new runtime.RequiredError(
        "announcementId",
        'Required parameter "announcementId" was null or undefined when calling announcementsUpdateAnnouncement().',
      );
    }

    if (requestParameters["announcementWrite"] == null) {
      throw new runtime.RequiredError(
        "announcementWrite",
        'Required parameter "announcementWrite" was null or undefined when calling announcementsUpdateAnnouncement().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/admin-api/announcements/{announcement_id}`.replace(
          `{${"announcement_id"}}`,
          encodeURIComponent(String(requestParameters["announcementId"])),
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: AnnouncementWriteToJSON(requestParameters["announcementWrite"]),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AnnouncementFromJSON(jsonValue),
    );
  }

  /**
   * Update Announcement
   */
  async announcementsUpdateAnnouncement(
    requestParameters: AnnouncementsUpdateAnnouncementRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Announcement> {
    const response = await this.announcementsUpdateAnnouncementRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
