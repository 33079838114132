import { ReactElement } from "react";

import { TFunction } from "i18next";
import { get } from "lodash-es";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled, { css } from "styled-components";

import {
  BikeBenefitContractCancellationReason,
  BikeBenefitContractProviderRead,
  BikeBenefitContractState,
  RedemptionProcessRead,
} from "@vapaus/api-codegen";
import { useDateFormat } from "@vapaus/i18n";
import { Flex, PaletteVariants, TextButton, Typography } from "@vapaus/ui-v2";

import { useGetRedemptionProcess } from "../../../../../api/redemptionProcess";
import { Path } from "../../../../../constants";
import { renderBenefitName } from "../../../../../utils/renderBenefitName";
import { ContractStatus } from "../../../../ContractStatus";
import { ContractProps } from "../../type";
import { ContractSplit } from "./components/ContractSplit";

export function ContractHeaderSection({ contract }: ContractProps) {
  const { t } = useTranslation();
  const formatDate = useDateFormat();
  const { data: redemptionProcess } = useGetRedemptionProcess(
    contract.currentRedemptionProcessId,
  );
  const { background, description } = getContractDescriptionProps(
    t,
    formatDate,
    contract,
    redemptionProcess,
  );
  const { organisationId } = useParams();

  return (
    <StyledRoot background={background}>
      <Flex direction="column" align="center" gap="lg">
        <Flex direction="column" align="center" gap="sm">
          <Flex direction="column" align="center">
            <ContractStatus status={contract.state} size="large" />
            <Typography weight="bold">
              <TextButton
                to={`/${organisationId}/${Path.employee}/${contract.userId}`}
                size="md"
              >
                {contract.user.fullName}
              </TextButton>
              {" · "}
              <span>{contract.vapausCode}</span>
            </Typography>
          </Flex>
          <Typography variant="h1" align="center">
            {contract.bike.bikeBrand.name} {contract.bike.model}
          </Typography>
          <Typography weight="bold">
            {renderBenefitName(
              contract.benefitDefinition.name,
              contract.benefitDefinition.active,
              t,
            )}
          </Typography>
          <Typography variant="bodyLg" align="center">
            {description}
          </Typography>
        </Flex>
        {contract.state !== BikeBenefitContractState.Invalidated ? (
          <ContractSplit contract={contract} />
        ) : null}
      </Flex>
    </StyledRoot>
  );
}

interface GetContractDescriptionPropsReturn {
  description: ReactElement | string;
  background: PaletteVariants;
}

function getContractDescriptionProps(
  t: TFunction,
  formatDate: ReturnType<typeof useDateFormat>,
  contract: BikeBenefitContractProviderRead,
  redemptionProcess?: RedemptionProcessRead,
): GetContractDescriptionPropsReturn {
  switch (contract.state) {
    case BikeBenefitContractState.Scheduled:
      return {
        description: (
          <Trans
            t={t}
            i18nKey="admin:contractDetails.hero.scheduledDescription"
            values={{ date: formatDate(contract.leasingPeriodStartDate) }}
            components={{ b: <b /> }}
          />
        ),
        background: "secondary3.lighter",
      };
    case BikeBenefitContractState.Active:
      return {
        description: (
          <Trans
            t={t}
            i18nKey="admin:contractDetails.hero.activeDescription"
            values={{ date: formatDate(contract.leasingPeriodStartDate) }}
            components={{ b: <b /> }}
          />
        ),
        background: "secondary1.lighter",
      };
    case BikeBenefitContractState.Cancelled:
      return {
        description: getCancelledContractDescription(formatDate, contract),
        background: "common.white",
      };
    case BikeBenefitContractState.CancelledPendingPayment:
      return {
        description: t(
          "admin:contractDetails.hero.cancelledPendingPaymentDescription",
        ),
        background: "common.white",
      };
    case BikeBenefitContractState.Ended:
      return {
        description: (
          <Trans
            t={t}
            i18nKey="admin:contractDetails.hero.endedDescription"
            values={{ date: formatDate(contract.effectiveEndDate) }}
            components={{ b: <b /> }}
          />
        ),
        background: "common.white",
      };
    case BikeBenefitContractState.EndedPendingPayment:
      return {
        description: t(
          "admin:contractDetails.hero.endedPendingPaymentDescription",
        ),
        background: "secondary2.lighter",
      };
    case BikeBenefitContractState.ActivePendingRevision:
      return {
        description: t(
          "admin:contractDetails.hero.activePendingRevisionDescription",
        ),
        background: "secondary3.lighter",
      };
    case BikeBenefitContractState.ActivePendingCorrection:
      return {
        description: t(
          "admin:contractDetails.hero.activePendingCorrectionDescription",
        ),
        background: "secondary3.lighter",
      };
    case BikeBenefitContractState.ActivePendingRedemption:
      return {
        description: (
          <Trans
            t={t}
            i18nKey="admin:contractDetails.hero.activePendingRedemptionDescription"
            values={{
              date: formatDate(redemptionProcess?.plannedRedemptionDate),
            }}
            components={{ b: <b /> }}
          />
        ),
        background: "secondary3.lighter",
      };
    case BikeBenefitContractState.ActivePendingReturn:
      return {
        description: (
          <Trans
            t={t}
            i18nKey="admin:contractDetails.hero.activePendingReturnDescription"
          />
        ),
        background: "secondary3.lighter",
      };
    case BikeBenefitContractState.Invalidated:
      return {
        description: (
          <Trans
            t={t}
            i18nKey="admin:contractDetails.hero.invalidatedDescription"
            values={{ date: formatDate(contract.updatedAt) }}
            components={{ b: <b /> }}
          />
        ),
        background: "common.white",
      };
  }

  return {
    description: t("admin:contractDetails.hero.unknownDescription"),
    background: "neutral.lighter",
  };
}

function getCancelledContractDescription(
  formatDate: ReturnType<typeof useDateFormat>,
  contract: BikeBenefitContractProviderRead,
): string | ReactElement {
  switch (contract.cancellationReason) {
    case BikeBenefitContractCancellationReason.InsuranceAccident:
      return (
        <Trans
          i18nKey="admin:contractDetails.hero.cancelledAccidentDescription"
          values={{ date: formatDate(contract.effectiveEndDate) }}
          components={{ b: <b /> }}
        />
      );
    case BikeBenefitContractCancellationReason.InsuranceTheft:
      return (
        <Trans
          i18nKey="admin:contractDetails.hero.cancelledTheftDescription"
          values={{ date: formatDate(contract.effectiveEndDate) }}
          components={{ b: <b /> }}
        />
      );
    case BikeBenefitContractCancellationReason.MergerAcquisition:
      return (
        <Trans
          i18nKey="admin:contractDetails.hero.cancelledMergerDescription"
          values={{ date: formatDate(contract.effectiveEndDate) }}
          components={{ b: <b /> }}
        />
      );
    case BikeBenefitContractCancellationReason.Redeemed:
      return (
        <Trans
          i18nKey="admin:contractDetails.hero.cancelledRedeemedDescription"
          values={{ date: formatDate(contract.effectiveEndDate) }}
          components={{ b: <b /> }}
        />
      );
    case BikeBenefitContractCancellationReason.Returned:
      return (
        <Trans
          i18nKey="admin:contractDetails.hero.cancelledReturnedDescription"
          values={{ date: formatDate(contract.effectiveEndDate) }}
          components={{ b: <b /> }}
        />
      );
    case BikeBenefitContractCancellationReason.Revised:
      return (
        <Trans
          i18nKey="admin:contractDetails.hero.cancelledRevisedDescription"
          values={{ date: formatDate(contract.effectiveEndDate) }}
          components={{ b: <b /> }}
        />
      );
    default:
      return (
        <Trans
          i18nKey="admin:contractDetails.hero.contractCancelledDescription"
          values={{ date: formatDate(contract.effectiveEndDate) }}
          components={{ b: <b /> }}
        />
      );
  }
}

interface StyledRootProps {
  background: PaletteVariants;
}

const StyledRoot = styled.div<StyledRootProps>`
  background-color: ${({ theme, background }) =>
    get(theme.palette, background)};
  border-bottom: ${({ theme }) => theme.border.secondary.main};
  padding: ${({ theme }) => theme.spacing.xl};
  padding-top: ${({ theme }) => theme.spacing.xxl};

  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      padding: 0 ${({ theme }) => theme.spacing.md}
        ${({ theme }) => theme.spacing.xl};
    `)}
`;
