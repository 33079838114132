/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";

/**
 *
 * @export
 * @interface ProviderTerms
 */
export interface ProviderTerms {
  /**
   *
   * @type {string}
   * @memberof ProviderTerms
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ProviderTerms
   */
  content: string;
  /**
   *
   * @type {boolean}
   * @memberof ProviderTerms
   */
  isCurrent: boolean;
}

/**
 * Check if a given object implements the ProviderTerms interface.
 */
export function instanceOfProviderTerms(value: object): value is ProviderTerms {
  if (!("id" in value) || value["id"] === undefined) return false;
  if (!("content" in value) || value["content"] === undefined) return false;
  if (!("isCurrent" in value) || value["isCurrent"] === undefined) return false;
  return true;
}

export function ProviderTermsFromJSON(json: any): ProviderTerms {
  return ProviderTermsFromJSONTyped(json, false);
}

export function ProviderTermsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ProviderTerms {
  if (json == null) {
    return json;
  }
  return {
    id: json["id"],
    content: json["content"],
    isCurrent: json["is_current"],
  };
}

export function ProviderTermsToJSON(json: any): ProviderTerms {
  return ProviderTermsToJSONTyped(json, false);
}

export function ProviderTermsToJSONTyped(
  value?: ProviderTerms | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value["id"],
    content: value["content"],
    is_current: value["isCurrent"],
  };
}
