/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { StorageObjectRead } from "./StorageObjectRead";
import {
  StorageObjectReadFromJSON,
  StorageObjectReadFromJSONTyped,
  StorageObjectReadToJSON,
  StorageObjectReadToJSONTyped,
} from "./StorageObjectRead";
import type { SupportedOrganisationCountries } from "./SupportedOrganisationCountries";
import {
  SupportedOrganisationCountriesFromJSON,
  SupportedOrganisationCountriesFromJSONTyped,
  SupportedOrganisationCountriesToJSON,
  SupportedOrganisationCountriesToJSONTyped,
} from "./SupportedOrganisationCountries";

/**
 *
 * @export
 * @interface OrganisationSummaryRead
 */
export interface OrganisationSummaryRead {
  /**
   *
   * @type {string}
   * @memberof OrganisationSummaryRead
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof OrganisationSummaryRead
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof OrganisationSummaryRead
   */
  businessId: string;
  /**
   *
   * @type {StorageObjectRead}
   * @memberof OrganisationSummaryRead
   */
  logoObject?: StorageObjectRead;
  /**
   *
   * @type {SupportedOrganisationCountries}
   * @memberof OrganisationSummaryRead
   */
  country: SupportedOrganisationCountries;
}

/**
 * Check if a given object implements the OrganisationSummaryRead interface.
 */
export function instanceOfOrganisationSummaryRead(
  value: object,
): value is OrganisationSummaryRead {
  if (!("id" in value) || value["id"] === undefined) return false;
  if (!("name" in value) || value["name"] === undefined) return false;
  if (!("businessId" in value) || value["businessId"] === undefined)
    return false;
  if (!("country" in value) || value["country"] === undefined) return false;
  return true;
}

export function OrganisationSummaryReadFromJSON(
  json: any,
): OrganisationSummaryRead {
  return OrganisationSummaryReadFromJSONTyped(json, false);
}

export function OrganisationSummaryReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): OrganisationSummaryRead {
  if (json == null) {
    return json;
  }
  return {
    id: json["id"],
    name: json["name"],
    businessId: json["business_id"],
    logoObject:
      json["logo_object"] == null
        ? undefined
        : StorageObjectReadFromJSON(json["logo_object"]),
    country: SupportedOrganisationCountriesFromJSON(json["country"]),
  };
}

export function OrganisationSummaryReadToJSON(
  json: any,
): OrganisationSummaryRead {
  return OrganisationSummaryReadToJSONTyped(json, false);
}

export function OrganisationSummaryReadToJSONTyped(
  value?: OrganisationSummaryRead | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value["id"],
    name: value["name"],
    business_id: value["businessId"],
    logo_object: StorageObjectReadToJSON(value["logoObject"]),
    country: SupportedOrganisationCountriesToJSON(value["country"]),
  };
}
