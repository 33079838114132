/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const PledgedLender = {
  Norion: "norion",
} as const;
export type PledgedLender = (typeof PledgedLender)[keyof typeof PledgedLender];

export function instanceOfPledgedLender(value: any): boolean {
  for (const key in PledgedLender) {
    if (Object.prototype.hasOwnProperty.call(PledgedLender, key)) {
      if (PledgedLender[key as keyof typeof PledgedLender] === value) {
        return true;
      }
    }
  }
  return false;
}

export function PledgedLenderFromJSON(json: any): PledgedLender {
  return PledgedLenderFromJSONTyped(json, false);
}

export function PledgedLenderFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PledgedLender {
  return json as PledgedLender;
}

export function PledgedLenderToJSON(value?: PledgedLender | null): any {
  return value as any;
}

export function PledgedLenderToJSONTyped(
  value: any,
  ignoreDiscriminator: boolean,
): PledgedLender {
  return value as PledgedLender;
}
