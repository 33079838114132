/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const RedemptionProcessStatus = {
  WaitingForPayment: "waiting_for_payment",
  PaymentOverdue: "payment_overdue",
  PendingContractTermination: "pending_contract_termination",
  ContractTerminated: "contract_terminated",
  Cancelled: "cancelled",
  Finalised: "finalised",
} as const;
export type RedemptionProcessStatus =
  (typeof RedemptionProcessStatus)[keyof typeof RedemptionProcessStatus];

export function instanceOfRedemptionProcessStatus(value: any): boolean {
  for (const key in RedemptionProcessStatus) {
    if (Object.prototype.hasOwnProperty.call(RedemptionProcessStatus, key)) {
      if (
        RedemptionProcessStatus[key as keyof typeof RedemptionProcessStatus] ===
        value
      ) {
        return true;
      }
    }
  }
  return false;
}

export function RedemptionProcessStatusFromJSON(
  json: any,
): RedemptionProcessStatus {
  return RedemptionProcessStatusFromJSONTyped(json, false);
}

export function RedemptionProcessStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): RedemptionProcessStatus {
  return json as RedemptionProcessStatus;
}

export function RedemptionProcessStatusToJSON(
  value?: RedemptionProcessStatus | null,
): any {
  return value as any;
}

export function RedemptionProcessStatusToJSONTyped(
  value: any,
  ignoreDiscriminator: boolean,
): RedemptionProcessStatus {
  return value as RedemptionProcessStatus;
}
