/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const BikeStatus = {
  Draft: "draft",
  InStorage: "in_storage",
  Reserved: "reserved",
  BenefitBike: "benefit_bike",
  Stolen: "stolen",
  Sold: "sold",
  InFleet: "in_fleet",
  Retired: "retired",
  Unknown: "unknown",
} as const;
export type BikeStatus = (typeof BikeStatus)[keyof typeof BikeStatus];

export function instanceOfBikeStatus(value: any): boolean {
  for (const key in BikeStatus) {
    if (Object.prototype.hasOwnProperty.call(BikeStatus, key)) {
      if (BikeStatus[key as keyof typeof BikeStatus] === value) {
        return true;
      }
    }
  }
  return false;
}

export function BikeStatusFromJSON(json: any): BikeStatus {
  return BikeStatusFromJSONTyped(json, false);
}

export function BikeStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BikeStatus {
  return json as BikeStatus;
}

export function BikeStatusToJSON(value?: BikeStatus | null): any {
  return value as any;
}

export function BikeStatusToJSONTyped(
  value: any,
  ignoreDiscriminator: boolean,
): BikeStatus {
  return value as BikeStatus;
}
