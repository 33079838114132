/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { StorageObjectRead } from "./StorageObjectRead";
import {
  StorageObjectReadFromJSON,
  StorageObjectReadFromJSONTyped,
  StorageObjectReadToJSON,
  StorageObjectReadToJSONTyped,
} from "./StorageObjectRead";

/**
 *
 * @export
 * @interface BenefitDefinitionTermsRead
 */
export interface BenefitDefinitionTermsRead {
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionTermsRead
   */
  benefitDefinitionId: string;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionTermsRead
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionTermsRead
   */
  pdfObjectId?: string;
  /**
   *
   * @type {boolean}
   * @memberof BenefitDefinitionTermsRead
   */
  isCurrent?: boolean;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionTermsRead
   */
  id: string;
  /**
   *
   * @type {StorageObjectRead}
   * @memberof BenefitDefinitionTermsRead
   */
  pdfObject?: StorageObjectRead;
  /**
   *
   * @type {Date}
   * @memberof BenefitDefinitionTermsRead
   */
  createdAt?: Date;
}

/**
 * Check if a given object implements the BenefitDefinitionTermsRead interface.
 */
export function instanceOfBenefitDefinitionTermsRead(
  value: object,
): value is BenefitDefinitionTermsRead {
  if (
    !("benefitDefinitionId" in value) ||
    value["benefitDefinitionId"] === undefined
  )
    return false;
  if (!("id" in value) || value["id"] === undefined) return false;
  return true;
}

export function BenefitDefinitionTermsReadFromJSON(
  json: any,
): BenefitDefinitionTermsRead {
  return BenefitDefinitionTermsReadFromJSONTyped(json, false);
}

export function BenefitDefinitionTermsReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BenefitDefinitionTermsRead {
  if (json == null) {
    return json;
  }
  return {
    benefitDefinitionId: json["benefit_definition_id"],
    content: json["content"] == null ? undefined : json["content"],
    pdfObjectId:
      json["pdf_object_id"] == null ? undefined : json["pdf_object_id"],
    isCurrent: json["is_current"] == null ? undefined : json["is_current"],
    id: json["id"],
    pdfObject:
      json["pdf_object"] == null
        ? undefined
        : StorageObjectReadFromJSON(json["pdf_object"]),
    createdAt:
      json["created_at"] == null ? undefined : new Date(json["created_at"]),
  };
}

export function BenefitDefinitionTermsReadToJSON(
  json: any,
): BenefitDefinitionTermsRead {
  return BenefitDefinitionTermsReadToJSONTyped(json, false);
}

export function BenefitDefinitionTermsReadToJSONTyped(
  value?: BenefitDefinitionTermsRead | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    benefit_definition_id: value["benefitDefinitionId"],
    content: value["content"],
    pdf_object_id: value["pdfObjectId"],
    is_current: value["isCurrent"],
    id: value["id"],
    pdf_object: StorageObjectReadToJSON(value["pdfObject"]),
    created_at:
      value["createdAt"] == null ? undefined : value["createdAt"].toISOString(),
  };
}
