/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { ShopType } from "./ShopType";
import {
  ShopTypeFromJSON,
  ShopTypeFromJSONTyped,
  ShopTypeToJSON,
  ShopTypeToJSONTyped,
} from "./ShopType";
import type { SupportedOrganisationCountries } from "./SupportedOrganisationCountries";
import {
  SupportedOrganisationCountriesFromJSON,
  SupportedOrganisationCountriesFromJSONTyped,
  SupportedOrganisationCountriesToJSON,
  SupportedOrganisationCountriesToJSONTyped,
} from "./SupportedOrganisationCountries";

/**
 *
 * @export
 * @interface ShopRead
 */
export interface ShopRead {
  /**
   *
   * @type {boolean}
   * @memberof ShopRead
   */
  isActive?: boolean;
  /**
   *
   * @type {string}
   * @memberof ShopRead
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ShopRead
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ShopRead
   */
  organisationId: string;
  /**
   *
   * @type {string}
   * @memberof ShopRead
   */
  logoObjectId?: string;
  /**
   *
   * @type {Array<ShopType>}
   * @memberof ShopRead
   */
  types?: Array<ShopType>;
  /**
   *
   * @type {string}
   * @memberof ShopRead
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof ShopRead
   */
  phoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ShopRead
   */
  website?: string;
  /**
   * Payment terms in days
   * @type {number}
   * @memberof ShopRead
   */
  paymentTerms?: number;
  /**
   *
   * @type {string}
   * @memberof ShopRead
   */
  streetAddress?: string;
  /**
   *
   * @type {string}
   * @memberof ShopRead
   */
  postalCode?: string;
  /**
   *
   * @type {string}
   * @memberof ShopRead
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof ShopRead
   */
  country?: string;
  /**
   * Two letters ISO 3166 country code
   * @type {Array<SupportedOrganisationCountries>}
   * @memberof ShopRead
   */
  operationCountries?: Array<SupportedOrganisationCountries>;
  /**
   *
   * @type {boolean}
   * @memberof ShopRead
   */
  isFeatured?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ShopRead
   */
  isHiddenFromLists?: boolean;
  /**
   *
   * @type {string}
   * @memberof ShopRead
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ShopRead
   */
  logoUrl?: string;
  /**
   *
   * @type {number}
   * @memberof ShopRead
   */
  currentDiscountBike: number;
  /**
   *
   * @type {number}
   * @memberof ShopRead
   */
  currentDiscountAccessory: number;
  /**
   *
   * @type {Date}
   * @memberof ShopRead
   */
  currentDiscountValidFrom?: Date;
}

/**
 * Check if a given object implements the ShopRead interface.
 */
export function instanceOfShopRead(value: object): value is ShopRead {
  if (!("name" in value) || value["name"] === undefined) return false;
  if (!("organisationId" in value) || value["organisationId"] === undefined)
    return false;
  if (!("id" in value) || value["id"] === undefined) return false;
  if (
    !("currentDiscountBike" in value) ||
    value["currentDiscountBike"] === undefined
  )
    return false;
  if (
    !("currentDiscountAccessory" in value) ||
    value["currentDiscountAccessory"] === undefined
  )
    return false;
  return true;
}

export function ShopReadFromJSON(json: any): ShopRead {
  return ShopReadFromJSONTyped(json, false);
}

export function ShopReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ShopRead {
  if (json == null) {
    return json;
  }
  return {
    isActive: json["is_active"] == null ? undefined : json["is_active"],
    name: json["name"],
    description: json["description"] == null ? undefined : json["description"],
    organisationId: json["organisation_id"],
    logoObjectId:
      json["logo_object_id"] == null ? undefined : json["logo_object_id"],
    types:
      json["types"] == null
        ? undefined
        : (json["types"] as Array<any>).map(ShopTypeFromJSON),
    email: json["email"] == null ? undefined : json["email"],
    phoneNumber:
      json["phone_number"] == null ? undefined : json["phone_number"],
    website: json["website"] == null ? undefined : json["website"],
    paymentTerms:
      json["payment_terms"] == null ? undefined : json["payment_terms"],
    streetAddress:
      json["street_address"] == null ? undefined : json["street_address"],
    postalCode: json["postal_code"] == null ? undefined : json["postal_code"],
    city: json["city"] == null ? undefined : json["city"],
    country: json["country"] == null ? undefined : json["country"],
    operationCountries:
      json["operation_countries"] == null
        ? undefined
        : (json["operation_countries"] as Array<any>).map(
            SupportedOrganisationCountriesFromJSON,
          ),
    isFeatured: json["is_featured"] == null ? undefined : json["is_featured"],
    isHiddenFromLists:
      json["is_hidden_from_lists"] == null
        ? undefined
        : json["is_hidden_from_lists"],
    id: json["id"],
    logoUrl: json["logo_url"] == null ? undefined : json["logo_url"],
    currentDiscountBike: json["current_discount_bike"],
    currentDiscountAccessory: json["current_discount_accessory"],
    currentDiscountValidFrom:
      json["current_discount_valid_from"] == null
        ? undefined
        : new Date(json["current_discount_valid_from"]),
  };
}

export function ShopReadToJSON(json: any): ShopRead {
  return ShopReadToJSONTyped(json, false);
}

export function ShopReadToJSONTyped(
  value?: ShopRead | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    is_active: value["isActive"],
    name: value["name"],
    description: value["description"],
    organisation_id: value["organisationId"],
    logo_object_id: value["logoObjectId"],
    types:
      value["types"] == null
        ? undefined
        : (value["types"] as Array<any>).map(ShopTypeToJSON),
    email: value["email"],
    phone_number: value["phoneNumber"],
    website: value["website"],
    payment_terms: value["paymentTerms"],
    street_address: value["streetAddress"],
    postal_code: value["postalCode"],
    city: value["city"],
    country: value["country"],
    operation_countries:
      value["operationCountries"] == null
        ? undefined
        : (value["operationCountries"] as Array<any>).map(
            SupportedOrganisationCountriesToJSON,
          ),
    is_featured: value["isFeatured"],
    is_hidden_from_lists: value["isHiddenFromLists"],
    id: value["id"],
    logo_url: value["logoUrl"],
    current_discount_bike: value["currentDiscountBike"],
    current_discount_accessory: value["currentDiscountAccessory"],
    current_discount_valid_from:
      value["currentDiscountValidFrom"] == null
        ? undefined
        : value["currentDiscountValidFrom"].toISOString().substring(0, 10),
  };
}
