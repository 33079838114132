/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";

/**
 *
 * @export
 * @interface Msg
 */
export interface Msg {
  /**
   *
   * @type {string}
   * @memberof Msg
   */
  msg: string;
}

/**
 * Check if a given object implements the Msg interface.
 */
export function instanceOfMsg(value: object): value is Msg {
  if (!("msg" in value) || value["msg"] === undefined) return false;
  return true;
}

export function MsgFromJSON(json: any): Msg {
  return MsgFromJSONTyped(json, false);
}

export function MsgFromJSONTyped(json: any, ignoreDiscriminator: boolean): Msg {
  if (json == null) {
    return json;
  }
  return {
    msg: json["msg"],
  };
}

export function MsgToJSON(json: any): Msg {
  return MsgToJSONTyped(json, false);
}

export function MsgToJSONTyped(
  value?: Msg | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    msg: value["msg"],
  };
}
