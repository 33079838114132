import { useTranslation } from "react-i18next";

import { UserBenefitActivationRead, UserRead } from "@vapaus/api-codegen";
import { useDateFormat } from "@vapaus/i18n";

import { useGetUser } from "../../../../../api/user";
import { PermissionStatusType } from "../utils/getPermissionStatus";

type PermissionMessageType = {
  shortMessage: string;
  longMessage: string;
};

export function usePermissionMessage(
  permissionStatus?: PermissionStatusType,
  activation?: UserBenefitActivationRead,
  user?: UserRead,
): PermissionMessageType | undefined {
  const resolvedByUserId = activation?.accessRequest?.resolvedByUserId;
  const { data: revokedUser } = useGetUser(activation?.revokedByUserId);
  const { data: resolvedUser } = useGetUser(resolvedByUserId);

  const { t } = useTranslation();

  const formatDate = useDateFormat();
  const revokeDate = formatDate(activation?.revokedAt);
  const grantDate = formatDate(
    activation?.accessRequest?.resolvedAt ?? activation?.createdAt,
  );

  if (activation) {
    const requestEmail = activation.accessRequest?.userEmail;
    const userName = user?.fullName;

    switch (permissionStatus?.status) {
      case "authorised":
        if (resolvedByUserId && resolvedUser?.fullName) {
          return {
            longMessage: t(
              "admin:employeeViewPage.permissions.statusMessage.authorised.longMessageByUser",
              {
                userName: userName,
                userEmail: requestEmail,
                grantedBy: resolvedUser.fullName,
                grantDate,
              },
            ),
            shortMessage: t(
              "admin:employeeViewPage.permissions.statusMessage.authorised.shortMessageByUser",
              {
                grantedBy: resolvedUser.fullName,
                grantDate,
              },
            ),
          };
        }
        if (resolvedByUserId) {
          return {
            longMessage: t(
              "admin:employeeViewPage.permissions.statusMessage.authorised.longMessage",
              {
                userName: userName,
                userEmail: requestEmail,
                grantDate,
              },
            ),
            shortMessage: t(
              "admin:employeeViewPage.permissions.statusMessage.authorised.shortMessage",
              {
                grantDate,
              },
            ),
          };
        }
        return {
          longMessage: t(
            "admin:employeeViewPage.permissions.statusMessage.authorised.longMessageAuto",
            {
              userName: userName,
              userEmail: user?.email,
              grantDate,
            },
          ),
          shortMessage: t(
            "admin:employeeViewPage.permissions.statusMessage.authorised.shortMessageAuto",
            {
              grantDate,
            },
          ),
        };

      case "revoked":
        return {
          longMessage: t(
            "admin:employeeViewPage.permissions.statusMessage.revoked.longMessage",
            {
              revokedBy: revokedUser?.fullName,
              userName: user?.fullName,
              userEmail: activation.accessRequest?.userEmail,
              date: revokeDate,
            },
          ),
          shortMessage: t(
            "admin:employeeViewPage.permissions.statusMessage.revoked.shortMessage",
            {
              revokedBy: revokedUser?.fullName,
              date: revokeDate,
            },
          ),
        };
      case "deactivated":
        return {
          longMessage: t(
            "admin:employeeViewPage.permissions.statusMessage.deactivated.longMessage",
          ),
          shortMessage: t(
            "admin:employeeViewPage.permissions.statusMessage.deactivated.shortMessage",
          ),
        };
    }
  }
}
