/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const Payer = {
  Employee: "employee",
  Employer: "employer",
} as const;
export type Payer = (typeof Payer)[keyof typeof Payer];

export function instanceOfPayer(value: any): boolean {
  for (const key in Payer) {
    if (Object.prototype.hasOwnProperty.call(Payer, key)) {
      if (Payer[key as keyof typeof Payer] === value) {
        return true;
      }
    }
  }
  return false;
}

export function PayerFromJSON(json: any): Payer {
  return PayerFromJSONTyped(json, false);
}

export function PayerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Payer {
  return json as Payer;
}

export function PayerToJSON(value?: Payer | null): any {
  return value as any;
}

export function PayerToJSONTyped(
  value: any,
  ignoreDiscriminator: boolean,
): Payer {
  return value as Payer;
}
