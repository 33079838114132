/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const AccessoryStatus = {
  BenefitBike: "benefit_bike",
  InStorage: "in_storage",
  Sold: "sold",
  Stolen: "stolen",
} as const;
export type AccessoryStatus =
  (typeof AccessoryStatus)[keyof typeof AccessoryStatus];

export function instanceOfAccessoryStatus(value: any): boolean {
  for (const key in AccessoryStatus) {
    if (Object.prototype.hasOwnProperty.call(AccessoryStatus, key)) {
      if (AccessoryStatus[key as keyof typeof AccessoryStatus] === value) {
        return true;
      }
    }
  }
  return false;
}

export function AccessoryStatusFromJSON(json: any): AccessoryStatus {
  return AccessoryStatusFromJSONTyped(json, false);
}

export function AccessoryStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AccessoryStatus {
  return json as AccessoryStatus;
}

export function AccessoryStatusToJSON(value?: AccessoryStatus | null): any {
  return value as any;
}

export function AccessoryStatusToJSONTyped(
  value: any,
  ignoreDiscriminator: boolean,
): AccessoryStatus {
  return value as AccessoryStatus;
}
