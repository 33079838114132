/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";

/**
 *
 * @export
 * @interface Transport
 */
export interface Transport {
  /**
   *
   * @type {Date}
   * @memberof Transport
   */
  expectedDeliveryDate?: Date;
  /**
   *
   * @type {Date}
   * @memberof Transport
   */
  deliveryDate?: Date;
  /**
   *
   * @type {string}
   * @memberof Transport
   */
  trackingUrl: string;
  /**
   *
   * @type {number}
   * @memberof Transport
   */
  priceGross: number;
  /**
   *
   * @type {number}
   * @memberof Transport
   */
  vatRate: number;
  /**
   *
   * @type {string}
   * @memberof Transport
   */
  bikeBenefitOrderId?: string;
  /**
   *
   * @type {string}
   * @memberof Transport
   */
  bikeBenefitContractId?: string;
  /**
   *
   * @type {string}
   * @memberof Transport
   */
  bikeId?: string;
  /**
   *
   * @type {string}
   * @memberof Transport
   */
  id?: string;
  /**
   *
   * @type {Date}
   * @memberof Transport
   */
  createdAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof Transport
   */
  updatedAt?: Date;
}

/**
 * Check if a given object implements the Transport interface.
 */
export function instanceOfTransport(value: object): value is Transport {
  if (!("trackingUrl" in value) || value["trackingUrl"] === undefined)
    return false;
  if (!("priceGross" in value) || value["priceGross"] === undefined)
    return false;
  if (!("vatRate" in value) || value["vatRate"] === undefined) return false;
  return true;
}

export function TransportFromJSON(json: any): Transport {
  return TransportFromJSONTyped(json, false);
}

export function TransportFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Transport {
  if (json == null) {
    return json;
  }
  return {
    expectedDeliveryDate:
      json["expected_delivery_date"] == null
        ? undefined
        : new Date(json["expected_delivery_date"]),
    deliveryDate:
      json["delivery_date"] == null
        ? undefined
        : new Date(json["delivery_date"]),
    trackingUrl: json["tracking_url"],
    priceGross: json["price_gross"],
    vatRate: json["vat_rate"],
    bikeBenefitOrderId:
      json["bike_benefit_order_id"] == null
        ? undefined
        : json["bike_benefit_order_id"],
    bikeBenefitContractId:
      json["bike_benefit_contract_id"] == null
        ? undefined
        : json["bike_benefit_contract_id"],
    bikeId: json["bike_id"] == null ? undefined : json["bike_id"],
    id: json["id"] == null ? undefined : json["id"],
    createdAt:
      json["created_at"] == null ? undefined : new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] == null ? undefined : new Date(json["updated_at"]),
  };
}

export function TransportToJSON(json: any): Transport {
  return TransportToJSONTyped(json, false);
}

export function TransportToJSONTyped(
  value?: Transport | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    expected_delivery_date:
      value["expectedDeliveryDate"] == null
        ? undefined
        : value["expectedDeliveryDate"].toISOString().substring(0, 10),
    delivery_date:
      value["deliveryDate"] == null
        ? undefined
        : value["deliveryDate"].toISOString().substring(0, 10),
    tracking_url: value["trackingUrl"],
    price_gross: value["priceGross"],
    vat_rate: value["vatRate"],
    bike_benefit_order_id: value["bikeBenefitOrderId"],
    bike_benefit_contract_id: value["bikeBenefitContractId"],
    bike_id: value["bikeId"],
    id: value["id"],
    created_at:
      value["createdAt"] == null ? undefined : value["createdAt"].toISOString(),
    updated_at:
      value["updatedAt"] == null ? undefined : value["updatedAt"].toISOString(),
  };
}
