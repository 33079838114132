/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const BenefitCancellationCoverage = {
  Employer: "employer",
  Employee: "employee",
  Free: "free",
  No: "no",
} as const;
export type BenefitCancellationCoverage =
  (typeof BenefitCancellationCoverage)[keyof typeof BenefitCancellationCoverage];

export function instanceOfBenefitCancellationCoverage(value: any): boolean {
  for (const key in BenefitCancellationCoverage) {
    if (
      Object.prototype.hasOwnProperty.call(BenefitCancellationCoverage, key)
    ) {
      if (
        BenefitCancellationCoverage[
          key as keyof typeof BenefitCancellationCoverage
        ] === value
      ) {
        return true;
      }
    }
  }
  return false;
}

export function BenefitCancellationCoverageFromJSON(
  json: any,
): BenefitCancellationCoverage {
  return BenefitCancellationCoverageFromJSONTyped(json, false);
}

export function BenefitCancellationCoverageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BenefitCancellationCoverage {
  return json as BenefitCancellationCoverage;
}

export function BenefitCancellationCoverageToJSON(
  value?: BenefitCancellationCoverage | null,
): any {
  return value as any;
}

export function BenefitCancellationCoverageToJSONTyped(
  value: any,
  ignoreDiscriminator: boolean,
): BenefitCancellationCoverage {
  return value as BenefitCancellationCoverage;
}
