import { now } from "lodash-es";
import { useTranslation } from "react-i18next";

import { getDateDifferenceInDays } from "@vapaus/common-v2";
import { useDateFormat } from "@vapaus/i18n";
import { Flex, RowItem, Separator } from "@vapaus/ui-v2";

import { useGetUser } from "../../../../../../api/user";
import { CommonRows } from "./components/CommonRows";
import {
  RequestModal,
  RequestModalCommonProps,
} from "./components/RequestModal";

export function RequestDeniedModal({
  isOpen,
  onClose,
  request,
}: RequestModalCommonProps) {
  const { t } = useTranslation();
  const formatDate = useDateFormat();
  const { data } = useGetUser(request?.resolvedByUserId);

  if (!request || !request.resolvedAt) {
    return null;
  }

  const daysAgo = getDateDifferenceInDays(request.resolvedAt, new Date(now()));
  const description = t("admin:home.requests.deniedModal.description", {
    daysAgo,
    deniedBy: data?.fullName,
  });

  return (
    <RequestModal
      isOpen={isOpen}
      onClose={onClose}
      request={request}
      description={description}
    >
      <Separator />
      <Flex fullWidth direction="column">
        <RowItem
          label={t("admin:home.requests.deniedModal.received")}
          value={formatDate(request.createdAt)}
        />
        <RowItem
          label={t("admin:home.requests.deniedModal.denied")}
          value={formatDate(request.resolvedAt)}
        />
        <CommonRows request={request} />
      </Flex>
    </RequestModal>
  );
}
