/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { AdditionalServiceType } from "./AdditionalServiceType";
import {
  AdditionalServiceTypeFromJSON,
  AdditionalServiceTypeFromJSONTyped,
  AdditionalServiceTypeToJSON,
  AdditionalServiceTypeToJSONTyped,
} from "./AdditionalServiceType";

/**
 *
 * @export
 * @interface AdditionalService
 */
export interface AdditionalService {
  /**
   *
   * @type {string}
   * @memberof AdditionalService
   */
  bikeBenefitOrderId: string;
  /**
   *
   * @type {AdditionalServiceType}
   * @memberof AdditionalService
   */
  serviceType: AdditionalServiceType;
  /**
   *
   * @type {string}
   * @memberof AdditionalService
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof AdditionalService
   */
  priceGross: number;
  /**
   *
   * @type {number}
   * @memberof AdditionalService
   */
  vatRate: number;
  /**
   *
   * @type {string}
   * @memberof AdditionalService
   */
  id?: string;
  /**
   *
   * @type {Date}
   * @memberof AdditionalService
   */
  createdAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof AdditionalService
   */
  updatedAt?: Date;
}

/**
 * Check if a given object implements the AdditionalService interface.
 */
export function instanceOfAdditionalService(
  value: object,
): value is AdditionalService {
  if (
    !("bikeBenefitOrderId" in value) ||
    value["bikeBenefitOrderId"] === undefined
  )
    return false;
  if (!("serviceType" in value) || value["serviceType"] === undefined)
    return false;
  if (!("priceGross" in value) || value["priceGross"] === undefined)
    return false;
  if (!("vatRate" in value) || value["vatRate"] === undefined) return false;
  return true;
}

export function AdditionalServiceFromJSON(json: any): AdditionalService {
  return AdditionalServiceFromJSONTyped(json, false);
}

export function AdditionalServiceFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AdditionalService {
  if (json == null) {
    return json;
  }
  return {
    bikeBenefitOrderId: json["bike_benefit_order_id"],
    serviceType: AdditionalServiceTypeFromJSON(json["service_type"]),
    description: json["description"] == null ? undefined : json["description"],
    priceGross: json["price_gross"],
    vatRate: json["vat_rate"],
    id: json["id"] == null ? undefined : json["id"],
    createdAt:
      json["created_at"] == null ? undefined : new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] == null ? undefined : new Date(json["updated_at"]),
  };
}

export function AdditionalServiceToJSON(json: any): AdditionalService {
  return AdditionalServiceToJSONTyped(json, false);
}

export function AdditionalServiceToJSONTyped(
  value?: AdditionalService | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    bike_benefit_order_id: value["bikeBenefitOrderId"],
    service_type: AdditionalServiceTypeToJSON(value["serviceType"]),
    description: value["description"],
    price_gross: value["priceGross"],
    vat_rate: value["vatRate"],
    id: value["id"],
    created_at:
      value["createdAt"] == null ? undefined : value["createdAt"].toISOString(),
    updated_at:
      value["updatedAt"] == null ? undefined : value["updatedAt"].toISOString(),
  };
}
