/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { BenefitDefinitionAccessRequestStatus } from "./BenefitDefinitionAccessRequestStatus";
import {
  BenefitDefinitionAccessRequestStatusFromJSON,
  BenefitDefinitionAccessRequestStatusFromJSONTyped,
  BenefitDefinitionAccessRequestStatusToJSON,
  BenefitDefinitionAccessRequestStatusToJSONTyped,
} from "./BenefitDefinitionAccessRequestStatus";

/**
 *
 * @export
 * @interface UserBenefitDefinitionAccessRequestRead
 */
export interface UserBenefitDefinitionAccessRequestRead {
  /**
   *
   * @type {BenefitDefinitionAccessRequestStatus}
   * @memberof UserBenefitDefinitionAccessRequestRead
   */
  status?: BenefitDefinitionAccessRequestStatus;
  /**
   *
   * @type {string}
   * @memberof UserBenefitDefinitionAccessRequestRead
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof UserBenefitDefinitionAccessRequestRead
   */
  businessId?: string;
  /**
   *
   * @type {string}
   * @memberof UserBenefitDefinitionAccessRequestRead
   */
  benefitDefinitionId?: string;
  /**
   *
   * @type {string}
   * @memberof UserBenefitDefinitionAccessRequestRead
   */
  userId: string;
  /**
   *
   * @type {string}
   * @memberof UserBenefitDefinitionAccessRequestRead
   */
  userEmail: string;
  /**
   *
   * @type {string}
   * @memberof UserBenefitDefinitionAccessRequestRead
   */
  resolvedByUserId?: string;
  /**
   *
   * @type {Date}
   * @memberof UserBenefitDefinitionAccessRequestRead
   */
  resolvedAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof UserBenefitDefinitionAccessRequestRead
   */
  createdAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof UserBenefitDefinitionAccessRequestRead
   */
  updatedAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof UserBenefitDefinitionAccessRequestRead
   */
  deletedAt?: Date;
  /**
   *
   * @type {string}
   * @memberof UserBenefitDefinitionAccessRequestRead
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UserBenefitDefinitionAccessRequestRead
   */
  organisationId?: string;
}

/**
 * Check if a given object implements the UserBenefitDefinitionAccessRequestRead interface.
 */
export function instanceOfUserBenefitDefinitionAccessRequestRead(
  value: object,
): value is UserBenefitDefinitionAccessRequestRead {
  if (!("userId" in value) || value["userId"] === undefined) return false;
  if (!("userEmail" in value) || value["userEmail"] === undefined) return false;
  if (!("id" in value) || value["id"] === undefined) return false;
  return true;
}

export function UserBenefitDefinitionAccessRequestReadFromJSON(
  json: any,
): UserBenefitDefinitionAccessRequestRead {
  return UserBenefitDefinitionAccessRequestReadFromJSONTyped(json, false);
}

export function UserBenefitDefinitionAccessRequestReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserBenefitDefinitionAccessRequestRead {
  if (json == null) {
    return json;
  }
  return {
    status:
      json["status"] == null
        ? undefined
        : BenefitDefinitionAccessRequestStatusFromJSON(json["status"]),
    message: json["message"] == null ? undefined : json["message"],
    businessId: json["business_id"] == null ? undefined : json["business_id"],
    benefitDefinitionId:
      json["benefit_definition_id"] == null
        ? undefined
        : json["benefit_definition_id"],
    userId: json["user_id"],
    userEmail: json["user_email"],
    resolvedByUserId:
      json["resolved_by_user_id"] == null
        ? undefined
        : json["resolved_by_user_id"],
    resolvedAt:
      json["resolved_at"] == null ? undefined : new Date(json["resolved_at"]),
    createdAt:
      json["created_at"] == null ? undefined : new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] == null ? undefined : new Date(json["updated_at"]),
    deletedAt:
      json["deleted_at"] == null ? undefined : new Date(json["deleted_at"]),
    id: json["id"],
    organisationId:
      json["organisation_id"] == null ? undefined : json["organisation_id"],
  };
}

export function UserBenefitDefinitionAccessRequestReadToJSON(
  json: any,
): UserBenefitDefinitionAccessRequestRead {
  return UserBenefitDefinitionAccessRequestReadToJSONTyped(json, false);
}

export function UserBenefitDefinitionAccessRequestReadToJSONTyped(
  value?: UserBenefitDefinitionAccessRequestRead | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    status: BenefitDefinitionAccessRequestStatusToJSON(value["status"]),
    message: value["message"],
    business_id: value["businessId"],
    benefit_definition_id: value["benefitDefinitionId"],
    user_id: value["userId"],
    user_email: value["userEmail"],
    resolved_by_user_id: value["resolvedByUserId"],
    resolved_at:
      value["resolvedAt"] == null
        ? undefined
        : value["resolvedAt"].toISOString(),
    created_at:
      value["createdAt"] == null ? undefined : value["createdAt"].toISOString(),
    updated_at:
      value["updatedAt"] == null ? undefined : value["updatedAt"].toISOString(),
    deleted_at:
      value["deletedAt"] == null ? undefined : value["deletedAt"].toISOString(),
    id: value["id"],
    organisation_id: value["organisationId"],
  };
}
