/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";

/**
 *
 * @export
 * @interface UserSummaryExtendedRead
 */
export interface UserSummaryExtendedRead {
  /**
   *
   * @type {string}
   * @memberof UserSummaryExtendedRead
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UserSummaryExtendedRead
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UserSummaryExtendedRead
   */
  fullName?: string;
  /**
   *
   * @type {string}
   * @memberof UserSummaryExtendedRead
   */
  costCenter?: string;
  /**
   *
   * @type {string}
   * @memberof UserSummaryExtendedRead
   */
  employeeNumber?: string;
}

/**
 * Check if a given object implements the UserSummaryExtendedRead interface.
 */
export function instanceOfUserSummaryExtendedRead(
  value: object,
): value is UserSummaryExtendedRead {
  if (!("id" in value) || value["id"] === undefined) return false;
  if (!("email" in value) || value["email"] === undefined) return false;
  return true;
}

export function UserSummaryExtendedReadFromJSON(
  json: any,
): UserSummaryExtendedRead {
  return UserSummaryExtendedReadFromJSONTyped(json, false);
}

export function UserSummaryExtendedReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserSummaryExtendedRead {
  if (json == null) {
    return json;
  }
  return {
    id: json["id"],
    email: json["email"],
    fullName: json["full_name"] == null ? undefined : json["full_name"],
    costCenter: json["cost_center"] == null ? undefined : json["cost_center"],
    employeeNumber:
      json["employee_number"] == null ? undefined : json["employee_number"],
  };
}

export function UserSummaryExtendedReadToJSON(
  json: any,
): UserSummaryExtendedRead {
  return UserSummaryExtendedReadToJSONTyped(json, false);
}

export function UserSummaryExtendedReadToJSONTyped(
  value?: UserSummaryExtendedRead | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value["id"],
    email: value["email"],
    full_name: value["fullName"],
    cost_center: value["costCenter"],
    employee_number: value["employeeNumber"],
  };
}
