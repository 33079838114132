/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const ShopType = {
  OnlineStore: "online_store",
  LocalStore: "local_store",
  Maintenance: "maintenance",
} as const;
export type ShopType = (typeof ShopType)[keyof typeof ShopType];

export function instanceOfShopType(value: any): boolean {
  for (const key in ShopType) {
    if (Object.prototype.hasOwnProperty.call(ShopType, key)) {
      if (ShopType[key as keyof typeof ShopType] === value) {
        return true;
      }
    }
  }
  return false;
}

export function ShopTypeFromJSON(json: any): ShopType {
  return ShopTypeFromJSONTyped(json, false);
}

export function ShopTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ShopType {
  return json as ShopType;
}

export function ShopTypeToJSON(value?: ShopType | null): any {
  return value as any;
}

export function ShopTypeToJSONTyped(
  value: any,
  ignoreDiscriminator: boolean,
): ShopType {
  return value as ShopType;
}
