/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const Notification = {
  ProviderContractEnded: "provider_contract_ended",
  BeneficiaryContractStarted: "beneficiary_contract_started",
  BeneficiaryContractEndsInMonth: "beneficiary_contract_ends_in_month",
  BeneficiaryContractEndsIn14Days: "beneficiary_contract_ends_in_14_days",
  BeneficiaryContractEndsToday: "beneficiary_contract_ends_today",
  ExpiredAccessRequest: "expired_access_request",
} as const;
export type Notification = (typeof Notification)[keyof typeof Notification];

export function instanceOfNotification(value: any): boolean {
  for (const key in Notification) {
    if (Object.prototype.hasOwnProperty.call(Notification, key)) {
      if (Notification[key as keyof typeof Notification] === value) {
        return true;
      }
    }
  }
  return false;
}

export function NotificationFromJSON(json: any): Notification {
  return NotificationFromJSONTyped(json, false);
}

export function NotificationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Notification {
  return json as Notification;
}

export function NotificationToJSON(value?: Notification | null): any {
  return value as any;
}

export function NotificationToJSONTyped(
  value: any,
  ignoreDiscriminator: boolean,
): Notification {
  return value as Notification;
}
