/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const SupportedOrganisationCountries = {
  Fi: "FI",
  Se: "SE",
  Al: "AL",
  Ad: "AD",
  Am: "AM",
  At: "AT",
  Az: "AZ",
  Be: "BE",
  Ba: "BA",
  Bg: "BG",
  Hr: "HR",
  Cy: "CY",
  Cz: "CZ",
  Dk: "DK",
  Ee: "EE",
  Fr: "FR",
  Ge: "GE",
  De: "DE",
  Gr: "GR",
  Hu: "HU",
  Is: "IS",
  Ie: "IE",
  It: "IT",
  Lv: "LV",
  Li: "LI",
  Lt: "LT",
  Lu: "LU",
  Mt: "MT",
  Md: "MD",
  Mc: "MC",
  Me: "ME",
  Nl: "NL",
  Mk: "MK",
  No: "NO",
  Pl: "PL",
  Pt: "PT",
  Ro: "RO",
  Sm: "SM",
  Rs: "RS",
  Sk: "SK",
  Si: "SI",
  Es: "ES",
  Ch: "CH",
  Gb: "GB",
} as const;
export type SupportedOrganisationCountries =
  (typeof SupportedOrganisationCountries)[keyof typeof SupportedOrganisationCountries];

export function instanceOfSupportedOrganisationCountries(value: any): boolean {
  for (const key in SupportedOrganisationCountries) {
    if (
      Object.prototype.hasOwnProperty.call(SupportedOrganisationCountries, key)
    ) {
      if (
        SupportedOrganisationCountries[
          key as keyof typeof SupportedOrganisationCountries
        ] === value
      ) {
        return true;
      }
    }
  }
  return false;
}

export function SupportedOrganisationCountriesFromJSON(
  json: any,
): SupportedOrganisationCountries {
  return SupportedOrganisationCountriesFromJSONTyped(json, false);
}

export function SupportedOrganisationCountriesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SupportedOrganisationCountries {
  return json as SupportedOrganisationCountries;
}

export function SupportedOrganisationCountriesToJSON(
  value?: SupportedOrganisationCountries | null,
): any {
  return value as any;
}

export function SupportedOrganisationCountriesToJSONTyped(
  value: any,
  ignoreDiscriminator: boolean,
): SupportedOrganisationCountries {
  return value as SupportedOrganisationCountries;
}
