import { useDateFormat } from "@vapaus/i18n";

type DateCellProps = {
  date: Date;
};

export function DateCell({ date }: DateCellProps) {
  const formatDate = useDateFormat();
  return <article>{formatDate(date)}</article>;
}
