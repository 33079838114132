import { useTranslation } from "react-i18next";

import { useGetAdditionalServiceLabel } from "@vapaus/common-v2";
import { useCurrencyFormat } from "@vapaus/i18n";
import { useEnumEntries } from "@vapaus/shared-api";
import { CollapsibleLineItem, Flex, RowItem, Typography } from "@vapaus/ui-v2";

import { ContractProps } from "../../../type";

export function ContractBikePackage({ contract }: ContractProps) {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormat(contract.currency);

  const { getEnumLabel: getAccessoryTypeEnumLabel } =
    useEnumEntries("AccessoryType");

  const { getAdditionalServiceLabel } = useGetAdditionalServiceLabel();

  return (
    <CollapsibleLineItem title={t("admin:contractDetails.bikePackage.title")}>
      <Flex data-testid="bike-package" direction="column" gap="md">
        <Flex direction="column" gap="xxs">
          <Typography weight="bold">
            {t("admin:contractDetails.bikePackage.bikeTitle")}
          </Typography>
          <RowItem
            label={`${contract.bike.bikeBrand.name} ${contract.bike.model}`}
            value={formatCurrency(contract.bikePrice)}
            labelIsBold={false}
          />
        </Flex>
        {contract.accessories?.length ? (
          <Flex direction="column" gap="xxs">
            <Typography weight="bold">
              {t("admin:contractDetails.bikePackage.accessories")}
            </Typography>
            {contract.accessories.map((accessory) => (
              <RowItem
                key={accessory.id}
                label={`${getAccessoryTypeEnumLabel(
                  accessory.accessoryType,
                )}: ${accessory.manufacturer} ${accessory.model}`}
                value={formatCurrency(accessory.purchasePrice)}
                labelIsBold={false}
              />
            ))}
          </Flex>
        ) : null}

        {contract.additionalServices?.length ? (
          <Flex direction="column" gap="xxs">
            <Typography weight="bold">
              {t("admin:contractDetails.bikePackage.additionalServices")}
            </Typography>
            {contract.additionalServices.map((service) => (
              <RowItem
                key={service.id}
                label={getAdditionalServiceLabel(service)}
                value={formatCurrency(service.priceGross)}
                labelIsBold={false}
              />
            ))}
          </Flex>
        ) : null}

        <Flex direction="column" gap="xxs">
          <Typography weight="bold">
            {t("admin:contractDetails.bikePackage.deliveryType.title")}
          </Typography>
          <RowItem
            label={
              contract.deliveryTransport
                ? t("admin:contractDetails.bikePackage.deliveryType.delivery")
                : t("admin:contractDetails.bikePackage.deliveryType.pickUp")
            }
            value={formatCurrency(contract.deliveryTransport?.priceGross ?? 0)}
            labelIsBold={false}
          />
        </Flex>
        <RowItem
          label={t("admin:contractDetails.bikePackage.totalPackagePrice")}
          value={formatCurrency(
            contract.totalPackagePrice - contract.totalMaintenanceBudget,
          )}
          valueIsBold
        />
      </Flex>
    </CollapsibleLineItem>
  );
}
