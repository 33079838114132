/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  ErrorResponse,
  ErrorWithCodeResponse,
  HTTPValidationError,
  ProviderTerms,
} from "../models/index";
import {
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ErrorWithCodeResponseFromJSON,
  ErrorWithCodeResponseToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  ProviderTermsFromJSON,
  ProviderTermsToJSON,
} from "../models/index";
import * as runtime from "../runtime";

export interface ProviderTermsAcceptProviderTermsRequest {
  providerTermsId: string;
}

export interface ProviderTermsDownloadProviderTermsRequest {
  providerTermsId: string;
}

export interface ProviderTermsGetProviderTermsRequest {
  providerTermsId: string;
}

/**
 *
 */
export class ProviderTermsApi extends runtime.BaseAPI {
  /**
   * Accept Provider Terms
   */
  async providerTermsAcceptProviderTermsRaw(
    requestParameters: ProviderTermsAcceptProviderTermsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters["providerTermsId"] == null) {
      throw new runtime.RequiredError(
        "providerTermsId",
        'Required parameter "providerTermsId" was null or undefined when calling providerTermsAcceptProviderTerms().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/provider-api/provider-terms/{provider_terms_id}/accept`.replace(
          `{${"provider_terms_id"}}`,
          encodeURIComponent(String(requestParameters["providerTermsId"])),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Accept Provider Terms
   */
  async providerTermsAcceptProviderTerms(
    requestParameters: ProviderTermsAcceptProviderTermsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.providerTermsAcceptProviderTermsRaw(
      requestParameters,
      initOverrides,
    );
  }

  /**
   * Current Provider Terms
   */
  async providerTermsCurrentProviderTermsRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ProviderTerms>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/provider-api/provider-terms/current`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProviderTermsFromJSON(jsonValue),
    );
  }

  /**
   * Current Provider Terms
   */
  async providerTermsCurrentProviderTerms(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ProviderTerms> {
    const response =
      await this.providerTermsCurrentProviderTermsRaw(initOverrides);
    return await response.value();
  }

  /**
   * Download Provider Terms
   */
  async providerTermsDownloadProviderTermsRaw(
    requestParameters: ProviderTermsDownloadProviderTermsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters["providerTermsId"] == null) {
      throw new runtime.RequiredError(
        "providerTermsId",
        'Required parameter "providerTermsId" was null or undefined when calling providerTermsDownloadProviderTerms().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/provider-api/provider-terms/{provider_terms_id}/download`.replace(
          `{${"provider_terms_id"}}`,
          encodeURIComponent(String(requestParameters["providerTermsId"])),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Download Provider Terms
   */
  async providerTermsDownloadProviderTerms(
    requestParameters: ProviderTermsDownloadProviderTermsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.providerTermsDownloadProviderTermsRaw(
      requestParameters,
      initOverrides,
    );
  }

  /**
   * Get Provider Terms
   */
  async providerTermsGetProviderTermsRaw(
    requestParameters: ProviderTermsGetProviderTermsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ProviderTerms>> {
    if (requestParameters["providerTermsId"] == null) {
      throw new runtime.RequiredError(
        "providerTermsId",
        'Required parameter "providerTermsId" was null or undefined when calling providerTermsGetProviderTerms().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/provider-api/provider-terms/{provider_terms_id}`.replace(
          `{${"provider_terms_id"}}`,
          encodeURIComponent(String(requestParameters["providerTermsId"])),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProviderTermsFromJSON(jsonValue),
    );
  }

  /**
   * Get Provider Terms
   */
  async providerTermsGetProviderTerms(
    requestParameters: ProviderTermsGetProviderTermsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ProviderTerms> {
    const response = await this.providerTermsGetProviderTermsRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
