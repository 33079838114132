/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";

/**
 *
 * @export
 * @interface BenefitDefinitionReferenceRead
 */
export interface BenefitDefinitionReferenceRead {
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionReferenceRead
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionReferenceRead
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof BenefitDefinitionReferenceRead
   */
  active: boolean;
}

/**
 * Check if a given object implements the BenefitDefinitionReferenceRead interface.
 */
export function instanceOfBenefitDefinitionReferenceRead(
  value: object,
): value is BenefitDefinitionReferenceRead {
  if (!("id" in value) || value["id"] === undefined) return false;
  if (!("name" in value) || value["name"] === undefined) return false;
  if (!("active" in value) || value["active"] === undefined) return false;
  return true;
}

export function BenefitDefinitionReferenceReadFromJSON(
  json: any,
): BenefitDefinitionReferenceRead {
  return BenefitDefinitionReferenceReadFromJSONTyped(json, false);
}

export function BenefitDefinitionReferenceReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BenefitDefinitionReferenceRead {
  if (json == null) {
    return json;
  }
  return {
    id: json["id"],
    name: json["name"],
    active: json["active"],
  };
}

export function BenefitDefinitionReferenceReadToJSON(
  json: any,
): BenefitDefinitionReferenceRead {
  return BenefitDefinitionReferenceReadToJSONTyped(json, false);
}

export function BenefitDefinitionReferenceReadToJSONTyped(
  value?: BenefitDefinitionReferenceRead | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value["id"],
    name: value["name"],
    active: value["active"],
  };
}
