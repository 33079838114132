import { useTranslation } from "react-i18next";

import { Plan } from "@vapaus/api-codegen";
import { useCurrencyFormat } from "@vapaus/i18n";
import { CollapsibleLineItem, Flex, RowItem, Tooltip } from "@vapaus/ui-v2";

import { ContractParamsProps, ContractProps } from "../../../type";

interface ContractBreakdownProps extends ContractProps {
  contractParams: ContractParamsProps;
}

export function ContractBreakdown({
  contract,
  contractParams,
}: ContractBreakdownProps) {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormat(contract.currency);

  return (
    <CollapsibleLineItem
      title={t("admin:contractDetails.contractBreakdown.title")}
    >
      <Flex data-testid="contract-breakdown" direction="column" gap="md">
        <Flex direction="column" gap="xxs">
          <RowItem
            label={t("admin:contractDetails.contractBreakdown.bikeOrder")}
            value={formatCurrency(
              contract.totalPackagePrice - contract.totalMaintenanceBudget,
            )}
            labelIsBold={false}
          />
          <RowItem
            label={
              contract.isDownPaymentInsured
                ? t(
                    "admin:contractDetails.contractBreakdown.downPaymentInsured",
                  )
                : t("admin:contractDetails.contractBreakdown.downPayment")
            }
            value={formatCurrency(0 - contract.downPaymentAmount)}
            labelIsBold={false}
          />
          <RowItem
            label={t(
              "admin:contractDetails.contractBreakdown.maintenanceBudget",
            )}
            value={formatCurrency(contract.totalMaintenanceBudget)}
            labelIsBold={false}
          />
          <RowItem
            label={t(
              "admin:contractDetails.contractBreakdown.insuranceAndFinancing",
            )}
            value={formatCurrency(contractParams.leasingCost)}
            labelIsBold={false}
          />
        </Flex>
        <RowItem
          label={t("admin:contractDetails.contractBreakdown.total")}
          value={
            <Flex>
              {formatCurrency(contractParams.contractTotal)}
              {([Plan.Custom, Plan.Legacy] as Plan[]).includes(
                contract.benefitDefinition.plan,
              ) && (
                <Tooltip>
                  {t("admin:contractDetails.contractBreakdown.totalLegacyInfo")}
                </Tooltip>
              )}
            </Flex>
          }
          valueIsBold
        />
      </Flex>
    </CollapsibleLineItem>
  );
}
