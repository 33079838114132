/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { OrganisationSummaryRead } from "./OrganisationSummaryRead";
import {
  OrganisationSummaryReadFromJSON,
  OrganisationSummaryReadFromJSONTyped,
  OrganisationSummaryReadToJSON,
  OrganisationSummaryReadToJSONTyped,
} from "./OrganisationSummaryRead";
import type { UserSummaryRead } from "./UserSummaryRead";
import {
  UserSummaryReadFromJSON,
  UserSummaryReadFromJSONTyped,
  UserSummaryReadToJSON,
  UserSummaryReadToJSONTyped,
} from "./UserSummaryRead";

/**
 *
 * @export
 * @interface UserMepcoIntegrationSettingsRead
 */
export interface UserMepcoIntegrationSettingsRead {
  /**
   *
   * @type {string}
   * @memberof UserMepcoIntegrationSettingsRead
   */
  payGroupId: string;
  /**
   *
   * @type {string}
   * @memberof UserMepcoIntegrationSettingsRead
   */
  payComponent: string;
  /**
   *
   * @type {string}
   * @memberof UserMepcoIntegrationSettingsRead
   */
  id: string;
  /**
   *
   * @type {UserSummaryRead}
   * @memberof UserMepcoIntegrationSettingsRead
   */
  user: UserSummaryRead;
  /**
   *
   * @type {OrganisationSummaryRead}
   * @memberof UserMepcoIntegrationSettingsRead
   */
  organisation: OrganisationSummaryRead;
}

/**
 * Check if a given object implements the UserMepcoIntegrationSettingsRead interface.
 */
export function instanceOfUserMepcoIntegrationSettingsRead(
  value: object,
): value is UserMepcoIntegrationSettingsRead {
  if (!("payGroupId" in value) || value["payGroupId"] === undefined)
    return false;
  if (!("payComponent" in value) || value["payComponent"] === undefined)
    return false;
  if (!("id" in value) || value["id"] === undefined) return false;
  if (!("user" in value) || value["user"] === undefined) return false;
  if (!("organisation" in value) || value["organisation"] === undefined)
    return false;
  return true;
}

export function UserMepcoIntegrationSettingsReadFromJSON(
  json: any,
): UserMepcoIntegrationSettingsRead {
  return UserMepcoIntegrationSettingsReadFromJSONTyped(json, false);
}

export function UserMepcoIntegrationSettingsReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserMepcoIntegrationSettingsRead {
  if (json == null) {
    return json;
  }
  return {
    payGroupId: json["pay_group_id"],
    payComponent: json["pay_component"],
    id: json["id"],
    user: UserSummaryReadFromJSON(json["user"]),
    organisation: OrganisationSummaryReadFromJSON(json["organisation"]),
  };
}

export function UserMepcoIntegrationSettingsReadToJSON(
  json: any,
): UserMepcoIntegrationSettingsRead {
  return UserMepcoIntegrationSettingsReadToJSONTyped(json, false);
}

export function UserMepcoIntegrationSettingsReadToJSONTyped(
  value?: UserMepcoIntegrationSettingsRead | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    pay_group_id: value["payGroupId"],
    pay_component: value["payComponent"],
    id: value["id"],
    user: UserSummaryReadToJSON(value["user"]),
    organisation: OrganisationSummaryReadToJSON(value["organisation"]),
  };
}
