/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { AnnouncementVariant } from "./AnnouncementVariant";
import {
  AnnouncementVariantFromJSON,
  AnnouncementVariantFromJSONTyped,
  AnnouncementVariantToJSON,
  AnnouncementVariantToJSONTyped,
} from "./AnnouncementVariant";
import type { AvailableScopes } from "./AvailableScopes";
import {
  AvailableScopesFromJSON,
  AvailableScopesFromJSONTyped,
  AvailableScopesToJSON,
  AvailableScopesToJSONTyped,
} from "./AvailableScopes";
import type { SupportedCountriesEnum } from "./SupportedCountriesEnum";
import {
  SupportedCountriesEnumFromJSON,
  SupportedCountriesEnumFromJSONTyped,
  SupportedCountriesEnumToJSON,
  SupportedCountriesEnumToJSONTyped,
} from "./SupportedCountriesEnum";

/**
 *
 * @export
 * @interface AnnouncementRead
 */
export interface AnnouncementRead {
  /**
   *
   * @type {string}
   * @memberof AnnouncementRead
   */
  id: string;
  /**
   *
   * @type {AnnouncementVariant}
   * @memberof AnnouncementRead
   */
  variant: AnnouncementVariant;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof AnnouncementRead
   */
  content: { [key: string]: string };
  /**
   *
   * @type {boolean}
   * @memberof AnnouncementRead
   */
  isEnabled: boolean;
  /**
   *
   * @type {Date}
   * @memberof AnnouncementRead
   */
  enabledFrom?: Date;
  /**
   *
   * @type {Date}
   * @memberof AnnouncementRead
   */
  enabledUntil?: Date;
  /**
   *
   * @type {Array<AvailableScopes>}
   * @memberof AnnouncementRead
   */
  scopes: Array<AvailableScopes>;
  /**
   *
   * @type {Array<SupportedCountriesEnum>}
   * @memberof AnnouncementRead
   */
  countries: Array<SupportedCountriesEnum>;
  /**
   *
   * @type {boolean}
   * @memberof AnnouncementRead
   */
  isCloseable: boolean;
}

/**
 * Check if a given object implements the AnnouncementRead interface.
 */
export function instanceOfAnnouncementRead(
  value: object,
): value is AnnouncementRead {
  if (!("id" in value) || value["id"] === undefined) return false;
  if (!("variant" in value) || value["variant"] === undefined) return false;
  if (!("content" in value) || value["content"] === undefined) return false;
  if (!("isEnabled" in value) || value["isEnabled"] === undefined) return false;
  if (!("scopes" in value) || value["scopes"] === undefined) return false;
  if (!("countries" in value) || value["countries"] === undefined) return false;
  if (!("isCloseable" in value) || value["isCloseable"] === undefined)
    return false;
  return true;
}

export function AnnouncementReadFromJSON(json: any): AnnouncementRead {
  return AnnouncementReadFromJSONTyped(json, false);
}

export function AnnouncementReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AnnouncementRead {
  if (json == null) {
    return json;
  }
  return {
    id: json["id"],
    variant: AnnouncementVariantFromJSON(json["variant"]),
    content: json["content"],
    isEnabled: json["is_enabled"],
    enabledFrom:
      json["enabled_from"] == null ? undefined : new Date(json["enabled_from"]),
    enabledUntil:
      json["enabled_until"] == null
        ? undefined
        : new Date(json["enabled_until"]),
    scopes: (json["scopes"] as Array<any>).map(AvailableScopesFromJSON),
    countries: (json["countries"] as Array<any>).map(
      SupportedCountriesEnumFromJSON,
    ),
    isCloseable: json["is_closeable"],
  };
}

export function AnnouncementReadToJSON(json: any): AnnouncementRead {
  return AnnouncementReadToJSONTyped(json, false);
}

export function AnnouncementReadToJSONTyped(
  value?: AnnouncementRead | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value["id"],
    variant: AnnouncementVariantToJSON(value["variant"]),
    content: value["content"],
    is_enabled: value["isEnabled"],
    enabled_from:
      value["enabledFrom"] == null
        ? undefined
        : value["enabledFrom"].toISOString().substring(0, 10),
    enabled_until:
      value["enabledUntil"] == null
        ? undefined
        : value["enabledUntil"].toISOString().substring(0, 10),
    scopes: (value["scopes"] as Array<any>).map(AvailableScopesToJSON),
    countries: (value["countries"] as Array<any>).map(
      SupportedCountriesEnumToJSON,
    ),
    is_closeable: value["isCloseable"],
  };
}
