/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { BenefitDefinitionAccessRequestWithUserRead } from "./BenefitDefinitionAccessRequestWithUserRead";
import {
  BenefitDefinitionAccessRequestWithUserReadFromJSON,
  BenefitDefinitionAccessRequestWithUserReadFromJSONTyped,
  BenefitDefinitionAccessRequestWithUserReadToJSON,
  BenefitDefinitionAccessRequestWithUserReadToJSONTyped,
} from "./BenefitDefinitionAccessRequestWithUserRead";

/**
 *
 * @export
 * @interface PaginatedBenefitDefinitionAccessRequestWithUserOut
 */
export interface PaginatedBenefitDefinitionAccessRequestWithUserOut {
  /**
   *
   * @type {number}
   * @memberof PaginatedBenefitDefinitionAccessRequestWithUserOut
   */
  total: number;
  /**
   *
   * @type {Array<BenefitDefinitionAccessRequestWithUserRead>}
   * @memberof PaginatedBenefitDefinitionAccessRequestWithUserOut
   */
  items: Array<BenefitDefinitionAccessRequestWithUserRead>;
}

/**
 * Check if a given object implements the PaginatedBenefitDefinitionAccessRequestWithUserOut interface.
 */
export function instanceOfPaginatedBenefitDefinitionAccessRequestWithUserOut(
  value: object,
): value is PaginatedBenefitDefinitionAccessRequestWithUserOut {
  if (!("total" in value) || value["total"] === undefined) return false;
  if (!("items" in value) || value["items"] === undefined) return false;
  return true;
}

export function PaginatedBenefitDefinitionAccessRequestWithUserOutFromJSON(
  json: any,
): PaginatedBenefitDefinitionAccessRequestWithUserOut {
  return PaginatedBenefitDefinitionAccessRequestWithUserOutFromJSONTyped(
    json,
    false,
  );
}

export function PaginatedBenefitDefinitionAccessRequestWithUserOutFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaginatedBenefitDefinitionAccessRequestWithUserOut {
  if (json == null) {
    return json;
  }
  return {
    total: json["total"],
    items: (json["items"] as Array<any>).map(
      BenefitDefinitionAccessRequestWithUserReadFromJSON,
    ),
  };
}

export function PaginatedBenefitDefinitionAccessRequestWithUserOutToJSON(
  json: any,
): PaginatedBenefitDefinitionAccessRequestWithUserOut {
  return PaginatedBenefitDefinitionAccessRequestWithUserOutToJSONTyped(
    json,
    false,
  );
}

export function PaginatedBenefitDefinitionAccessRequestWithUserOutToJSONTyped(
  value?: PaginatedBenefitDefinitionAccessRequestWithUserOut | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    total: value["total"],
    items: (value["items"] as Array<any>).map(
      BenefitDefinitionAccessRequestWithUserReadToJSON,
    ),
  };
}
