/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { BenefitDefinitionReferenceRead } from "./BenefitDefinitionReferenceRead";
import {
  BenefitDefinitionReferenceReadFromJSON,
  BenefitDefinitionReferenceReadFromJSONTyped,
  BenefitDefinitionReferenceReadToJSON,
  BenefitDefinitionReferenceReadToJSONTyped,
} from "./BenefitDefinitionReferenceRead";
import type { EmployeeBenefitActivationStatus } from "./EmployeeBenefitActivationStatus";
import {
  EmployeeBenefitActivationStatusFromJSON,
  EmployeeBenefitActivationStatusFromJSONTyped,
  EmployeeBenefitActivationStatusToJSON,
  EmployeeBenefitActivationStatusToJSONTyped,
} from "./EmployeeBenefitActivationStatus";

/**
 *
 * @export
 * @interface EmployeeUser
 */
export interface EmployeeUser {
  /**
   *
   * @type {string}
   * @memberof EmployeeUser
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeUser
   */
  fullName?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeUser
   */
  benefitEmail: string;
  /**
   *
   * @type {Array<BenefitDefinitionReferenceRead>}
   * @memberof EmployeeUser
   */
  benefitDefinitions: Array<BenefitDefinitionReferenceRead>;
  /**
   *
   * @type {string}
   * @memberof EmployeeUser
   */
  costCenter?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeUser
   */
  employeeNumber?: string;
  /**
   *
   * @type {EmployeeBenefitActivationStatus}
   * @memberof EmployeeUser
   */
  benefitActivationStatus: EmployeeBenefitActivationStatus;
}

/**
 * Check if a given object implements the EmployeeUser interface.
 */
export function instanceOfEmployeeUser(value: object): value is EmployeeUser {
  if (!("id" in value) || value["id"] === undefined) return false;
  if (!("benefitEmail" in value) || value["benefitEmail"] === undefined)
    return false;
  if (
    !("benefitDefinitions" in value) ||
    value["benefitDefinitions"] === undefined
  )
    return false;
  if (
    !("benefitActivationStatus" in value) ||
    value["benefitActivationStatus"] === undefined
  )
    return false;
  return true;
}

export function EmployeeUserFromJSON(json: any): EmployeeUser {
  return EmployeeUserFromJSONTyped(json, false);
}

export function EmployeeUserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EmployeeUser {
  if (json == null) {
    return json;
  }
  return {
    id: json["id"],
    fullName: json["full_name"] == null ? undefined : json["full_name"],
    benefitEmail: json["benefit_email"],
    benefitDefinitions: (json["benefit_definitions"] as Array<any>).map(
      BenefitDefinitionReferenceReadFromJSON,
    ),
    costCenter: json["cost_center"] == null ? undefined : json["cost_center"],
    employeeNumber:
      json["employee_number"] == null ? undefined : json["employee_number"],
    benefitActivationStatus: EmployeeBenefitActivationStatusFromJSON(
      json["benefit_activation_status"],
    ),
  };
}

export function EmployeeUserToJSON(json: any): EmployeeUser {
  return EmployeeUserToJSONTyped(json, false);
}

export function EmployeeUserToJSONTyped(
  value?: EmployeeUser | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value["id"],
    full_name: value["fullName"],
    benefit_email: value["benefitEmail"],
    benefit_definitions: (value["benefitDefinitions"] as Array<any>).map(
      BenefitDefinitionReferenceReadToJSON,
    ),
    cost_center: value["costCenter"],
    employee_number: value["employeeNumber"],
    benefit_activation_status: EmployeeBenefitActivationStatusToJSON(
      value["benefitActivationStatus"],
    ),
  };
}
