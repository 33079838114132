/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { BenefitDefinitionAccessRequestStatus } from "./BenefitDefinitionAccessRequestStatus";
import {
  BenefitDefinitionAccessRequestStatusFromJSON,
  BenefitDefinitionAccessRequestStatusFromJSONTyped,
  BenefitDefinitionAccessRequestStatusToJSON,
  BenefitDefinitionAccessRequestStatusToJSONTyped,
} from "./BenefitDefinitionAccessRequestStatus";
import type { BenefitDefinitionSummaryRead } from "./BenefitDefinitionSummaryRead";
import {
  BenefitDefinitionSummaryReadFromJSON,
  BenefitDefinitionSummaryReadFromJSONTyped,
  BenefitDefinitionSummaryReadToJSON,
  BenefitDefinitionSummaryReadToJSONTyped,
} from "./BenefitDefinitionSummaryRead";
import type { UserSummaryExtendedRead } from "./UserSummaryExtendedRead";
import {
  UserSummaryExtendedReadFromJSON,
  UserSummaryExtendedReadFromJSONTyped,
  UserSummaryExtendedReadToJSON,
  UserSummaryExtendedReadToJSONTyped,
} from "./UserSummaryExtendedRead";

/**
 *
 * @export
 * @interface BenefitDefinitionAccessRequestWithUserRead
 */
export interface BenefitDefinitionAccessRequestWithUserRead {
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionAccessRequestWithUserRead
   */
  id: string;
  /**
   *
   * @type {BenefitDefinitionAccessRequestStatus}
   * @memberof BenefitDefinitionAccessRequestWithUserRead
   */
  status: BenefitDefinitionAccessRequestStatus;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionAccessRequestWithUserRead
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionAccessRequestWithUserRead
   */
  businessId?: string;
  /**
   *
   * @type {BenefitDefinitionSummaryRead}
   * @memberof BenefitDefinitionAccessRequestWithUserRead
   */
  benefitDefinition?: BenefitDefinitionSummaryRead;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionAccessRequestWithUserRead
   */
  userId: string;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionAccessRequestWithUserRead
   */
  userEmail: string;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionAccessRequestWithUserRead
   */
  resolvedByUserId?: string;
  /**
   *
   * @type {Date}
   * @memberof BenefitDefinitionAccessRequestWithUserRead
   */
  resolvedAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof BenefitDefinitionAccessRequestWithUserRead
   */
  createdAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof BenefitDefinitionAccessRequestWithUserRead
   */
  updatedAt?: Date;
  /**
   *
   * @type {UserSummaryExtendedRead}
   * @memberof BenefitDefinitionAccessRequestWithUserRead
   */
  user: UserSummaryExtendedRead;
}

/**
 * Check if a given object implements the BenefitDefinitionAccessRequestWithUserRead interface.
 */
export function instanceOfBenefitDefinitionAccessRequestWithUserRead(
  value: object,
): value is BenefitDefinitionAccessRequestWithUserRead {
  if (!("id" in value) || value["id"] === undefined) return false;
  if (!("status" in value) || value["status"] === undefined) return false;
  if (!("userId" in value) || value["userId"] === undefined) return false;
  if (!("userEmail" in value) || value["userEmail"] === undefined) return false;
  if (!("user" in value) || value["user"] === undefined) return false;
  return true;
}

export function BenefitDefinitionAccessRequestWithUserReadFromJSON(
  json: any,
): BenefitDefinitionAccessRequestWithUserRead {
  return BenefitDefinitionAccessRequestWithUserReadFromJSONTyped(json, false);
}

export function BenefitDefinitionAccessRequestWithUserReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BenefitDefinitionAccessRequestWithUserRead {
  if (json == null) {
    return json;
  }
  return {
    id: json["id"],
    status: BenefitDefinitionAccessRequestStatusFromJSON(json["status"]),
    message: json["message"] == null ? undefined : json["message"],
    businessId: json["business_id"] == null ? undefined : json["business_id"],
    benefitDefinition:
      json["benefit_definition"] == null
        ? undefined
        : BenefitDefinitionSummaryReadFromJSON(json["benefit_definition"]),
    userId: json["user_id"],
    userEmail: json["user_email"],
    resolvedByUserId:
      json["resolved_by_user_id"] == null
        ? undefined
        : json["resolved_by_user_id"],
    resolvedAt:
      json["resolved_at"] == null ? undefined : new Date(json["resolved_at"]),
    createdAt:
      json["created_at"] == null ? undefined : new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] == null ? undefined : new Date(json["updated_at"]),
    user: UserSummaryExtendedReadFromJSON(json["user"]),
  };
}

export function BenefitDefinitionAccessRequestWithUserReadToJSON(
  json: any,
): BenefitDefinitionAccessRequestWithUserRead {
  return BenefitDefinitionAccessRequestWithUserReadToJSONTyped(json, false);
}

export function BenefitDefinitionAccessRequestWithUserReadToJSONTyped(
  value?: BenefitDefinitionAccessRequestWithUserRead | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value["id"],
    status: BenefitDefinitionAccessRequestStatusToJSON(value["status"]),
    message: value["message"],
    business_id: value["businessId"],
    benefit_definition: BenefitDefinitionSummaryReadToJSON(
      value["benefitDefinition"],
    ),
    user_id: value["userId"],
    user_email: value["userEmail"],
    resolved_by_user_id: value["resolvedByUserId"],
    resolved_at:
      value["resolvedAt"] == null
        ? undefined
        : value["resolvedAt"].toISOString(),
    created_at:
      value["createdAt"] == null ? undefined : value["createdAt"].toISOString(),
    updated_at:
      value["updatedAt"] == null ? undefined : value["updatedAt"].toISOString(),
    user: UserSummaryExtendedReadToJSON(value["user"]),
  };
}
